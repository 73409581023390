.container {
  height: 100vh;
  overflow-y: scroll;
}
.container::-webkit-scrollbar {
  display: none;
}
.LearningHead {
  padding: 1.25rem;
  display: flex;
  background: #a2a2be;
  line-height: 0;
  height: 30px;
  align-items: center;
}
.LearningHead h3 {
  font-size: 1.75rem;
}
.AllCard {
  display: flex;
  padding: 2rem;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-around;
}
.card {
  display: flex;
  flex-direction: column;
  width: 30%;
  align-items: flex-start;
  border: 1px solid;
  justify-content: flex-start;
  padding: 1rem;
  border-radius: 10px;
}
.cardHead {
  font-size: 1.5rem;
  line-height: 0;
  border-bottom: 1px solid;
  width: 100%;
}
.saveList {
  line-height: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.saveList h5 {
  font-size: 1.75rem;

  margin: 30px 0;
}
.saveList p {
  font-size: 1.5rem;
}
.saveList span {
  color: blue;
}
.topicList {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  line-height: 2;
}
.topicList li {
  font-size: 1.5rem;
  color: blue;
}
.Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1rem;
  border-bottom: 1px solid #b1b1b1;
}
.HeaderText {
  width: 100%;
}
.HeaderText p {
  font-size: 1.25rem;
  margin: 0;
  color: #1a1e5d;
  font-weight: 500;
}
.HeaderIcons {
  width: 100%;
  text-align: right;
  color: #1a1e5d;
  font-size: 1.5rem;
}
.LessonDiv {
  border: 1px solid #dbdbdb;
  margin: 1rem 2rem;
  padding: 0rem 3rem;
  background: linear-gradient(#fff, #dbdbdb);
  height: auto;
  min-height: 70vh;
}
.Card {
  /* width: 100%; */
  height: fit-content;
  border: 1px solid #bdbcbc;
  height: auto;
  padding: 1rem;
  background-color: #fff;
}
.CardContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.First {
  width: 35%;
}
.Seccond {
  width: 35%;
}
.CardHead p {
  color: #1a1e5d;
  font-weight: 600;
  margin: 0;
  padding: 6px 0;
  font-size: 1.25rem;
  text-transform: uppercase;
}
.CardContent p {
  margin: 0;
  padding: 4px 0;
  font-size: 1.15rem;
  font-weight: 500;
}
.List {
  border: 2px solid #bdbcbc;
  margin-top: 2rem;
  height: auto;
  background-color: #fff;
}

.LessonList {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #bdbcbc;
  padding: 10px;
}
.ListtText {
  width: 95%;
  cursor: pointer;
  color: #1a1e5d;
  font-weight: 500;
}
.ListIcons {
  width: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1rem;
  font-size: 1.25rem;
}
.Status {
  color: green;
  font-weight: 600;
}
.Popup {
  width: 35rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  z-index: 9999;
  height: 25rem;
  background-color: #fff;
  padding: 1rem;
  border: 1px solid;
  border-radius: 5px;
  box-shadow: 1px 2px #a9a7a7;
}

.SubPopup {
  width: 25rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  z-index: 9999;
  height: 10rem;
  background-color: #fff;
  padding: 1rem;
  border: 1px solid;
  border-radius: 5px;
  box-shadow: 1px 2px #a9a7a7;
}

.subPopTxt {
  padding: 1rem;
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-weight: 700;
}

.subBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.subBtn button {
  padding: 15px;
  width: 100%;
  background-color: #1010794d;
  border: 1px solid blue;
  font-weight: 800;
}

.PopupOverlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.Heading {
  background: #dde1ef;
  color: #56197e;
  height: fit-content;
  margin: 10px 0;
}
.Heading p {
  margin: 0;
  padding: 6px 1rem;

  font-size: 1.25rem;
  font-weight: 500;
}
.Table {
  margin-top: 1rem;
  background-color: #fff;
}
.Table table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #bdbcbc;
}
td {
  text-align: center;
  padding: 7px;
}

.Icons {
  display: flex;
  flex-direction: row;
  font-size: 1.25rem;
  align-items: center;
  justify-content: space-evenly;
}
.Table th,
.Table td {
  border: 1px solid #bdbcbc; /* Add this line to set borders for th and td */
  text-align: center;
  padding: 7px;
  font-size: 1.1rem;
}
.Table th {
  background: #dde1ef;
  color: #56197e;
  border: none;
}
table span {
  cursor: pointer;
}
.Bold {
  font-weight: bold;
}
.SeccondContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.SeccondContentFirst,
.SeccondContentSecond {
  font-size: 1.1rem;
  font-weight: 700;
  width: 10rem;
  padding: 6px 2px;
}
.SeccondContentSecond {
  font-weight: 500;
}
.Topiclink {
  color: rgb(13, 127, 241);
}
.ExamICon {
  font-size: 1.5rem;
  margin: 0 6px;
  color: #101079;
}
.roundGraph {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 10rem;
}
.roundGraph p {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  padding: 8px 0;
}
.flashingButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flashing {
  padding: 0.5rem;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  background: linear-gradient(45deg, #3498db, #2ecc71);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background 0.3s, color 0.3s, transform 0.3s;
  animation: heartbeat 1.5s ease-in-out infinite;
}

.flashing:hover {
  background: linear-gradient(45deg, #2ecc71, #27ae60);
  color: #ffffff;
  transform: scale(1.05);
}
@keyframes heartbeat {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
.leaderBoard {
  align-items: center;
  background: #101079;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  left: 55%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40rem;
  border: 5px solid #62a7c5;
  z-index: 99999;
  padding: 0 0.75rem 0.75rem 0.75rem;
  height: 50vh;
}
.boardHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  border: 5px solid #3047b6;
  border-radius: 2rem;
  margin-top: -30px;
  background: #101079;
  color: #fff;
  padding: 0.75rem;
}
.boardHeader h3 {
  margin: 0;
  font-size: 1.5rem;
  background: linear-gradient(to right, #737edd, #04c1ff);
  -webkit-background-clip: text;
  color: transparent;
}
.boardSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 0.75rem 0;
  height: 100%;
  overflow-y: scroll;
}
.boardSection::-webkit-scrollbar {
  display: none;
}
.boardValues {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  margin: 0.5rem 0;
}
.valueBadge {
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.valueBadge p {
  font-size: 1.5rem;
  color: #fff;
  margin: 0;
  font-weight: 800;
}
.valueBadge img {
  width: 100%;
  height: 100%;
}
.valueAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.valueAvatar svg {
  font-size: 3rem;
  color: #ffff;
}
.playerNameStar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 50%;
  background: linear-gradient(to right, #3d7dd4, #3720c9);
  border-radius: 1rem;
  padding: 0.75rem 0.5rem;
}
.playerNameStar p {
  font-size: 1.25rem;
  color: #fff;
  font-weight: 700;
  margin: 0;
}
.Overlay {
  background-color: #3e3e3e1a;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 99;
}
@media screen and (max-width: 820px) {
  .LessonDiv {
    padding: 0rem 0.5rem;
  }
  .SeccondContentFirst,
  .SeccondContentSecond {
    font-size: 1rem;
    font-weight: 700;
    width: 10rem;
    padding: 6px 2px;
  }
}
@media screen and (max-width: 720px) {
  .CardContent {
    flex-direction: column;
    justify-content: space-between;
  }
  .First {
    width: 100%;
  }
  .Seccond {
    width: 100%;
  }
  .Popup {
    width: 70%;
    height: fit-content;
    padding: 0.5rem;
  }
}
@media screen and (max-width: 420px) {
  .container {
    width: 100%;
    height: 100vh;
    padding: 0 0.5rem;
    overflow-y: scroll;
  }
  .AssessmentData p {
    font-size: 0.9rem;
  }
  .SeccondContentFirst,
  .SeccondContentSecond {
    font-size: 0.8rem;
    width: 10rem;
    padding: 6px 2px;
  }
  .Table th,
  .Table td {
    padding: 2px;
    font-size: 1rem;
  }
}
.WhiteBoradContainer {
  width: 60rem;
  height: 36rem;
  border-radius: 16px;
  background-color: #fff;
  position: fixed;
  top: 19%;
  right: 1%;
  border: 1px solid #dbdbdb;
  /* transform: translate(-50%, -50%); */
  animation: slideIn 0.5s forwards;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 9;
}
.closing {
  animation: slideOut 0.5s; /* Adjust the duration as needed */
}
@keyframes slideIn {
  from {
    right: -60rem; /* Off-screen initially */
  }
  to {
    right: 1rem; /* Final position */
  }
}
@keyframes slideOut {
  from {
    right: 1rem; /* Initial position when closing */
  }
  to {
    right: -60rem; /* Move off-screen to the right */
  }
}
.WhiteBoradHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
  padding: 0rem 1rem;
  margin: 0;
  border: none;
  border-radius: 15px 15px 0 0;
}
.WhiteBoradIcon {
  cursor: pointer;
  font-size: 1.25rem;
  padding: 8px;
}
.WhiteBoradContent {
  height: 30rem;
  padding: 0.5rem 1rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.chatboticon {
  position: fixed;
  top: 50%;
  right: 0%;
  /* color: #fff;  */
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* animation: slideIn 0.5s forwards; */
}

.Iconhead {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: #000;
  font-weight: bold;
  font-size: 1.25rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
}
.icontext {
  padding: 0.5rem;
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  /* background-color: #e4c395; */
  border-radius: 6px;
  /* border: 1px solid; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #1a1e5d;
  background-color: #fff;
}
.icontext p {
  margin: 0;
  padding: 0;
}
.WhiteboardFloatingIcon {
  font-size: 1.5rem;
  margin: 2px 0;
  color: #1a1e5d;
}
.ChatImage {
  width: 11rem;
  display: flex;
  align-items: center;
}
.ChatImage img {
  width: 100%;
}

.learningCardContainer {
  padding: 20px;
}

.learningHead {
  padding-bottom: 20px;
}

.cardBox {
  margin-bottom: 20px;
  padding-left: 20px;
}

.cardText {
  text-align: left;
  align-items: center;
  font-size: 15px;
  font-weight: 800;
  text-decoration: none;
}

.cardText span {
  align-items: center;
  display: flex;
  cursor: pointer;
}

.cardMain {
  margin-top: 40px;
  padding: 20px;
  background-color: #3046b636;
  border-radius: 10px;
  width: 100vh;
}

.TopicIcons {
  font-size: 1rem;
}

.iconStat {
  font-size: 2rem;
  color: #1a1e5d;
}

.statBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.statBox strong {
  cursor: pointer;
}

.disabled {
  opacity: 0.5; 
  pointer-events: none; 
}
