.container {
    height: calc(100vh - 17vh);
    overflow-y: auto;
    padding: 0 2rem;
}

.container::-webkit-scrollbar {
    width: 10px;
}

.container::-webkit-scrollbar-track {
    background: #D9D9D9;
    border-radius: 10px;
}

.container::-webkit-scrollbar-thumb {
    background: #8C8C8C;
    border-radius: 10px;
}

.container::-webkit-scrollbar-thumb:hover {
    background: #8C8C8C;

}


.performance {
    display: flex;
    flex-direction: column;
    width: 93%;
    gap: 1rem;
}

.welcomeSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(91.43deg, #FFBE00 7.29%, rgba(255, 190, 0, 0.39) 142.96%, #FFC828 142.97%, #D29C00 142.99%);

    border-radius: 45px;
}

.welcomeSectionLeft {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 55%;
    padding: 1rem;
}

.welcomeSectionLeft p {
    font-family: Urbane Demi ;
    font-size: 24px;
    font-weight: 600;
    line-height: 39.82px;
    text-align: left;
    margin: 0;
    padding: .75rem 1rem;
    color: #fff;
}

.welcomeSectionLeft span {
    color: #fff;
    font-family: Urbane Extra;
    font-size: px;
    font-weight: 300;
    line-height: 27.65px;
    text-align: left;
    margin: 0;
    padding: 0 1rem;
}

.welcomeSectionRight {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 40%;
}

.welcomeImageContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding-right: 20%;
}

.welcomeImage {
width: 10rem; 
height: 9.5rem; 
}

.welcomeImage img {
    width: 100%;
    height: 100%;
}
.userPointCard{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 14rem;
}
.userPointCard img{
    width: 100%;
    height: 100%;
}

.dailyDrillCard{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1;
    gap: 2rem;
    padding: 2rem;
    border-radius: 33px;
    background-color: #FFE79D;
}
.weeklyWorkOutCard{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1;
    gap: 2rem;
    padding: 2rem;
    border-radius: 33px;
    background-color: #B7FFF7;
}
.assessmentCardLeft{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
}
.assessmentCardImage{
    width: 5rem;
    height: 5rem;
}
.assessmentCardImage img{
    width: 100%;
    height: 100%;
}
.assessmentCardRight{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: .5rem;
}
.assessmentCardRight p{
    font-family: Urbane;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #000;
    margin: 0;
    word-wrap: break-word;
}
.assessmentCardRight h4{
    font-family: Urbane;
    font-size: 40px;
    font-weight: 600;
    line-height: 56.02px;
    text-align: left;
    margin: 0;
}
.dailyDrillCard .assessmentCardRight h4{
    color: #FF8800;
}
.weeklyWorkOutCard .assessmentCardRight h4{
    color: #038E7F;
}
.assessmentCardRight span{
    font-family: Urbane;
    font-size: 18px;
    font-weight: 500;
    line-height: 32.41px;
    text-align: left;
    color: #5F5F5F;
}
.moduleSection{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem 2rem;
    border: 2px solid #8988901F;
    border-radius: 12px;
    box-shadow: 0px -4px 22.8px 2px #D9D9D9;

}
.moduleHeder{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.moduleHeder p{
    font-family: Urbane;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    margin: 0;
    color: #595BDB;
}
.numberBarSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
}

.numberBar {
    width: 100%;
    height: 7px;
    background-color: #F0F0F0;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    border-radius: 1rem;
}

.number, .numberContainer {
    margin-left: 3rem; 
}




.selectedNumber {
    background: #595BDB;
    border-radius: 50%;
    font-family: Urbane;
    font-size: 14px;
    font-weight: 500;
    line-height: 13.33px;
    text-align: left;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 1.75rem;
    height: 1.75rem;
}

.numberContainer{
    border-radius: 50%;
    background-color: #fff;
    padding: .15rem;
    border: 2px solid #595BDB;

}

.number {
    background: #CDCEFF;
    border-radius: 50%;
    font-family: Urbane;
    font-size: 11px;
    font-weight: 500;
    line-height: 13.33px;
    text-align: left;
    color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;

}
.moduleSubHeder{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 0;
}
.moduleSubHeder p{
    font-family: Urbane;
    font-size: 16px;
    font-weight: 500;
    line-height: 21.78px;
    text-align: center;
    color: #5F5F5F;
    margin: 0;
}
.moduleSubHeder span{
    font-family: Urbane;
    font-size: 16px;
    font-weight: 500;
    line-height: 21.78px;
    text-align: center;
    color: #000;
}

.progressBarSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.progressBar {
    width: 100%;
    height: 10px;
    background-color: #F0F0F0;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
}
.progressBarCount p{
    font-family: Urbane;
    font-size: 16px;
    font-weight: 600;
    line-height: 30.px;
    text-align: center;
    color: #595BDB;
    margin: 0;
}
.roundGraphSection{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
    padding: 1rem 0;
    flex-wrap: wrap;
}
.roundGraphCard{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: .5rem;
}
.roundGraphCardHeder{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.roundGraphCardHeder p{
    font-family: Urbane;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: center;
    margin: 0;
    color: #000;
}
.roundGraphCardBottom{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.roundGraphCardBottom p{
    font-family: Urbane;
    font-size: 14px;
    font-weight: 600;
    line-height: 18.15px;
    text-align: center;
    margin: 0;
    color: #3E3D42;
}
.roundGraph{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 5.9rem;
    position: relative;
}
.roundGraphData{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.roundGraphData p{
    font-family: Urbane;
    font-size: 22px;
    font-weight: 500;
    line-height: 35px;
    text-align: left;
    margin: 0;
    color: #595BDB;
}
.learningSection{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 1rem 1.75rem;
    border: 2px solid #8988901F;
    border-radius: 12px;
    box-shadow: 0px -4px 22.8px 2px #D9D9D9;
}
.learningSectionLeft{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 1rem;
    width: 48%;
}
.learningHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.learningHeader p{
    font-family: Urbane;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.6px;
    text-align: left;
    margin: 0;
    color: #5F5F5F;
}
.learningSectionRight{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-left: 1px solid #595BDB;
    padding-left: 1rem;
    width: 48%;
}
.learningSubHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;
    padding: 1rem 0;
    /* height: 6vh; */
    gap: .5rem;
  flex-wrap: wrap;
}
.drillHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;
    margin-bottom: .75rem;
}
.learningSubHeaderLeft{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
    border-radius: 4px;
    background-color: #D9D9D9;
}
.subHeaderCard{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: .35rem;
    width: 3rem;
    border-right: 0.4px solid #89889061;
}
.subHeaderCard:last-child{
    border: none;
}
.subHeaderCard p{
    font-family: Urbane;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    text-align: left;
    margin: 0;   
    color: #000; 
}
.selectedSubHeaderCard{
    background-color: #000;
    border:none;
    border-radius: 4px;
}
.selectedSubHeaderCard p{
    color: #ffffff; 
}
.learningSubHeaderRight{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1rem;

}
.learningSubHeaderRight select{
    border: 1px solid #878787;
    border-radius: 23px;
    padding:.2rem .75rem;
    max-width: 180px;
}
.barGraph{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
   
}
.levelHeader{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
}
.levelHeaderCard{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: .5rem;
    
}
.levelHeaderCard h4{
    font-family: Urbane;
    font-size: 11px;
    font-weight: 500;
    line-height: 13.2px;
    text-align: left;
    color: #000;   
    white-space: nowrap; 
}
.levelHeaderCard p{
    font-family: Urbane;
    font-size: 8px;
    font-weight: 300;
    line-height: 9.6px;
    text-align: left;
    margin: 0;
    color: #3E3D42;
}
.levelHeaderCard span{
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #FFBE00;
}
.levelHeaderCard:last-child span{
    background-color: #595BDB;
}
.assessmentSection{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;
}
.assessmentSectionLeft{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem ;
    border: 2px solid #8988901F;
    border-radius: 12px;
    box-shadow: 0px -4px 22.8px 2px #D9D9D9;
    width: 46%;
}
.assessmentSectionRight{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem ;
    border: 2px solid #8988901F;
    border-radius: 12px;
    box-shadow: 0px -4px 22.8px 2px #D9D9D9;
    width: 46%;
}
.assessmentScore{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFBE00;
    border-radius: 50%;
    width: 7vw;
    aspect-ratio: 1; 
}
.assessmentScore p{
    margin: 0;
    font-family: Urbane;
    font-size: 9px;
    font-weight: 500;
    line-height: 10.8px;
    text-align: center;
    color: #000;
}
.assessmentScore h4{
    font-family: Urbane;
    font-size: 35px;
    font-weight: 600;
    line-height: 42.01px;
    text-align: left;
    color: #fff;
}
.lineGraph{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.achievementHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 0;
}

.achievementHeader p {
    margin: 0;
    font-family: Urbane;
    font-size: 24px;
    font-weight: 600;
    line-height: 36.01px;
    text-align: left;
    color: #595BDB;
}

.achievementSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 2px solid #8988901F;
    box-shadow: 0px -4px 22.8px 0px #D9D9D9;
    border-radius: 20px;
    padding: 1rem;
    background-color: #FCFCFC;
    margin-bottom: 1rem;
}
.achievementGrid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1rem;
    gap: 2rem;
}

.achievementCard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 1rem 0;
    gap: 1.5rem;
}

.achievementCardImage {
    width: 7.5rem;
    cursor: pointer;
}

.achievementCardImage img {
    width: 100%;
    height: 100%;
}
/* .selectedImage {
    box-shadow: 2px 3px 10.4px 0px #00000085;
  } */

.achievementCardText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: .3rem;
}

.achievementCardText p {
    font-family: Urbane;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: center;
    margin: 0;
    color: #595BDB;
}
.achievementCardText span{
    font-family: Urbane;
    font-size: 14px;
    font-weight: 300;
    line-height: 19.2px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000;
    width: 70%;
}
.viewText{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.viewText p{
    font-family: Urbane;
font-size: 14px;
font-weight: 500;
line-height: 24px;
text-align: center;
color: #595BDB;
text-decoration: underline;
padding-right: 1rem;
cursor: pointer;
}
.recommendedText{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: .5rem;
}
.recommendedText span{
    color: red;
}
.recommendedText p{
    font-family: Urbane Extra;
font-size: 14px;
font-weight: 300;
line-height: 22.75px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}
.graphSectionRight {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #e9eaff7a;
    border-radius: 35px;
    padding: 1rem;
  }
  
  .leaderBoardHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .leaderBoardHeader p {
    margin: 0;
    padding: 6px 1rem;
    color: #595bdb;
    font-family: Urbane;
    font-size: 18px;
    font-weight: 500;
    line-height: 27.65px;
    text-align: left;
  }
  
  .leaderBoardBody {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: scroll;
    padding: 1rem;
    height: 38vh;
  }
  
  .leaderBoardBody::-webkit-scrollbar {
    width: 10px;
  }
  
  .leaderBoardBody::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
  }
  
  .leaderBoardBody::-webkit-scrollbar-thumb {
    background: #595bdb;
    border-radius: 10px;
  }
  
  .leaderBoardBody::-webkit-scrollbar-thumb:hover {
    background: #595bdb;
  }
  
  .leaderBoardCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.75rem 2rem;
    background-color: #b1b2ee;
    border-radius: 2rem;
    margin: 0.5rem 0;
  }
  
  .leaderCardLeft {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
  }
  
  .leaderCardRight {
    width: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .leaderCardRight img {
    width: 100%;
  }
  
  .leaderCardLeftTop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .leaderName {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 70%;
  }
  
  .leaderName p {
    margin: 0;
    font-family: Urbane;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: left;
    color: #3f3f3f;
  }
  
  .leaderPoint {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 30%;
  }
  
  .leaderPoint p {
    margin: 0;
    font-family: Urbane;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: left;
    text-align: left;
    color: #3f3f3f;
  }
  
  .leaderCardLeftBottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .leaderProgressBAr {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 70%;
  }
  
  .leaderAssessment {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 30%;
  }
  
  .leaderAssessment p {
    font-family: Urbane;
    font-size: 10px;
    font-weight: 500;
    line-height: 13.33px;
    text-align: left;
    color: #3f3f3f;
  }
  .tooltip {
    cursor: pointer;
    position: relative; /* Keeps tooltip text positioned properly */
    display: inline-block;
  }
  
  /* Tooltip styling */
  .tooltip::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 110%; /* Adjust as needed */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    white-space: nowrap;
    font-size: 10px; 
    font-family: Urbane Extra;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    z-index: 9999; /* Very high z-index to prevent overlap */
    pointer-events: none; /* Prevents interference with hover effects */
  }
  
  /* Show tooltip on hover */
  .tooltip:hover::after {
    opacity: 1;
    visibility: visible;
  }
  
  
  @media screen and (max-width:800px) {
    .performance {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 1rem;
    }
    .learningSection{
        flex-direction: column;
    }
    .learningSectionLeft{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 0rem;
        width: 100%;
    }
    .learningSectionRight{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border: none;
        padding-left: 0;
        width: 100%;
    }
    .assessmentSection{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 1rem;
    }
    .assessmentSectionLeft{
        width: 100%;
    }
    .assessmentSectionRight{
        width: 100%;
    }
    .assessmentScore{
        width: 14vw;
        aspect-ratio: 1; 
    }
    .container {
        height: 100%;
        overflow-y: auto;
        padding: 0 1rem;
    }
    
  }
  @media screen and (max-width:600px) {
    .welcomeSection {
        flex-wrap: wrap;
    }
    
    .welcomeSectionLeft {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        padding: 1rem;
    }
    
    .welcomeSectionLeft p {
        font-family: Urbane Demi ;
        font-size: 20px;
        font-weight: 600;
        line-height: 39.82px;
        text-align: left;
        margin: 0;
        padding: .75rem 1rem;
        color: #fff;
    }
    
    .welcomeSectionLeft span {
        color: #fff;
        font-family: Urbane Extra;
        font-size: 16px;
        font-weight: 300;
        line-height: 27.65px;
        text-align: left;
        margin: 0;
        padding: 0 1rem;
    }
    
    .welcomeSectionRight {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        padding: .5rem 1rem;
    }
    
    .welcomeImageContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-right: 0%;
    }
    
    .welcomeImage {
    width: 10rem; 
    height: 9.5rem; 
    }
    
    .welcomeImage img {
        width: 100%;
        height: 100%;
    }
    .userPointCard{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 14rem;
    }
    .userPointCard img{
        width: 100%;
        height: 100%;
    }
    
    .dailyDrillCard{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex: 1;
        gap: 2rem;
        padding: 2rem;
        border-radius: 33px;
        background-color: #FFE79D;
    }
    .weeklyWorkOutCard{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex: 1;
        gap: 2rem;
        padding: 2rem;
        border-radius: 33px;
        background-color: #B7FFF7;
    }
    
  }