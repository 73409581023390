.container {
  height: calc(100vh - 17vh);
  overflow-y: auto;
  padding: 0 2rem;
}

.container::-webkit-scrollbar {
  width: 10px;
}

.container::-webkit-scrollbar-track {
  background: #d9d9d9;
  border-radius: 10px;
}

.container::-webkit-scrollbar-thumb {
  background: #8c8c8c;
  border-radius: 10px;
}

.container::-webkit-scrollbar-thumb:hover {
  background: #8c8c8c;
}

.dashboardSection {
  display: flex;
  flex-direction: column;
  width: 93%;
}

.welcomeSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(
    91.43deg,
    #00cbf4 7.29%,
    rgba(0, 142, 225, 0.35) 142.96%,
    #0151cd 142.97%,
    #032f82 142.99%
  );
  padding: 0 1rem;
  border-radius: 45px;
  margin-bottom: 1rem;
}

.welcomeSectionLeft {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 45%;
  padding: 1rem;
}

.welcomeSectionLeft p {
  font-family: Urbane Demi;
  font-size: 22px;
  font-weight: 600;
  line-height: 39.82px;
  text-align: left;
  margin: 0;
  padding: 0.75rem 1rem;
  color: #fff;
}

.welcomeSectionLeft span {
  color: #fff;
  font-family: Urbane Extra;
  font-size: 16px;
  font-weight: 300;
  line-height: 27.65px;
  text-align: left;
  margin: 0;
  padding: 0 1rem;
}

.welcomeSectionRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 45%;
}

.welcomeImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.welcomeImage {
  width: 15rem;
}

.welcomeImage img {
  width: 100%;
  height: 100%;
}

.videoSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ffeebb;
  padding: 1rem;
  border-radius: 45px;
  margin: 1rem 0;
}

.videoSectionLeft {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 45%;
  padding: 1rem;
}

.videoSectionLeft p {
  margin: 0;
  padding: 0 1rem;
  color: #5f5f5f;
  font-family: Urbane Extra;
  font-size: 20px;
  font-weight: 300;
  line-height: 33.19px;
  text-align: left;
}

.videoSectionRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 45%;
  padding: 1rem;
}

.videoButtonSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border-radius: 2rem;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
}

.videoButtonSection svg {
  color: #ffbe00;
  font-size: 2rem;
  padding-right: 1rem;
}

.videoButtonSection p {
  font-family: Urbane;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.04px;
  text-align: left;
  margin: 0;
  color: #5f5f5f;
}

.activitySection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 0;
  gap: 1rem;
}

.activitySectionLeft {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem 3rem;
  background: #595bdb4d;
  width: 53%;
  border-radius: 2rem;
}

.activitySectionLeftTop {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.activitySectionLeftTop h4 {
  font-family: Urbane;
  font-size: 26px;
  font-weight: 500;
  line-height: 43.21px;
  text-align: left;
  color: #595bdb;
  margin: 0;
  padding-bottom: 0.75rem;
}

.activitySectionLeftTop p {
  font-family: Urbane Extra;
  font-size: 16px;
  font-weight: 300;
  line-height: 35px;
  text-align: left;
  color: #5f5f5f;
}

.activitySectionLeftBottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  gap: 1rem;
}

.moduleText {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.moduleText p {
  font-family: Urbane;
  font-size: 32px;
  font-weight: 500;
  line-height: 57.61px;
  text-align: left;
  color: #595bdba6;
  margin: 0;
  padding-right: 1rem;
}

.moduleText span {
  font-family: Urbane;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.04px;
  text-align: left;
  color: #5f5f5f;
}

.exploreButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 2rem;
  padding: 0.7rem 0;
  width: 14rem;
  cursor: pointer;
}

.exploreButton p {
  margin: 0;
  font-family: Urbane;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.04px;
  text-align: left;
  color: #5f5f5f;
  padding-right: 0.75rem;
}

.exploreButton svg {
  font-size: 1.35rem;
}

.activitySectionRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 34%;
  border-radius: 2rem;
}

.activityRightHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.activityRightHeader p {
  font-family: Urbane;
  font-size: 20px;
  font-weight: 500;
  line-height: 27.65px;
  text-align: left;
  color: #5f5f5f;
  margin: 0;
}
.activityRightHeader span {
  font-family: Urbane Extra;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.04px;
  text-align: left;
  color: #898890;
}

.activityCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
}

.activityCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 45px;
  padding: 2rem 1rem;
  margin: 1rem 0;
}
.disabledCard {
  background: #d9d9d9;
  pointer-events: none;
}
.dailyCard {
  background-color: #ffbe00;
}
.weeklyCard {
  background-color: #00dfc7;
}
.orangeBackground {
  background-color: #ff7a00;
}

.redBackground {
  background-color: #ff2443;
}

.activityCardImage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  padding: 0 1rem;
}

.activityCardImage img {
  width: 100%;
  height: 100%;
}

.activityCardText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.activityCardText p {
  font-family: Urbane;
  font-size: 20px;
  font-weight: 500;
  line-height: 33.61px;
  text-align: left;
  color: #fff;
  margin: 0;
  padding: 0 1rem;
}

.statusSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding:1.5rem 2rem;
  background: #595bdb1a;
  border-radius: 2rem;
  margin-bottom: 1rem;
}

.statusSectionLeft {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 55%;
}

.statusHeading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.statusHeading p {
  font-family: Urbane;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #595bdb;
  margin: 0;
  padding: 4px 0;
}

.moduleHeading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.moduleHeading p {
  font-family: Urbane;
  font-size: 18px;
  font-weight: 500;
  line-height: 27.65px;
  text-align: left;
  color: #595bdb;
  margin: 0;
  padding: 4px 0;
}

.progressBar {
  margin: 6px 0;
}

.topicContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 8px 0;
}

.topicCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 1rem;
  padding: 10px 1rem;
  margin: 8px 0;
}

.topicCardLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.topicCardLeft p {
  font-family: Urbane;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #5f5f5f;
  margin: 0;
}

.topicCardRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 30%;
}

.topicCardRight p {
  font-family: Urbane;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  margin: 0;
}

.statusSectionRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 2rem;
  border-left: 3px solid #81808533;
  width: 35%;
}

.statusSectionRightContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 65%;
}

.statusSectionRightGraph {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 35%;
  position: relative;
}
.percentageLabel {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px; 
  font-weight: bold;
  color: #5f5f5f;
  text-align: center;
  pointer-events: none;
}

.statusRightHeading {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: .5rem;
}

.statusRightHeading p {
  font-family: Urbane;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #595bdb;
  margin: 0;
  padding: 6px 0;
}

.statusRightHeading span {
  font-family: Urbane Extra;
  font-size: 13px;
  font-weight: 300;
  line-height: 16px;
  text-align: left;
  color: #595bdb;
}

.statusRightBody {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem 0;
}

.statusRightBody h4 {
  font-family: Urbane;
  font-size: 16px;
  font-weight: 600;
  line-height: 23.04px;
  text-align: left;
  color: #595bdb;
  margin: 0;
  padding: 1rem 0;
}

.statusRightBody span {
  font-family: Urbane;
  font-size: 15px;
  font-weight: 500;
  line-height: 23.04px;
  text-align: left;
  color: #595bdb;
}

.statusRightButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #b1b2ee;
  border-radius: 2rem;
  padding: 1rem 0;
  width: 8rem;
  margin-top: 1.2rem;
}

.statusRightButton p {
  font-family: Urbane;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #5f5f5f;
  margin: 0;
  cursor: pointer;
}

.popupOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  width: 100%;
  height: 100vh;
  background: #d9d9d980;
}

.Popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 31px;
  padding: 3rem 8rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 99;
  box-shadow: 1px 1px 2.3px 0px #cfcfcf;
}

.popupHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.popupHeaderIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.popupHeaderIcon img {
  width: 50px;
  height: 100%;
}

.popupMainHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0;
}

.popupMainHeader p {
  margin: 0;
  font-family: Urbane;
  font-size: 30px;
  font-weight: 500;
  line-height: 48.01px;
  text-align: left;
  color: #595bdb;
}

.popupSubHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}

.popupSubHeader p {
  margin: 0;
  font-family: Urbane;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  color: #5f5f5f;
}

.popupBody {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.popupBodyTop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6rem;
  padding: 1rem 0;
}

.popupBodyCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.popupBodyCard p {
  margin: 0;
  font-family: Urbane;
  font-size: 18px;
  font-weight: 500;
  line-height: 28.8px;
  text-align: left;
  color: #5f5f5f;
}

.popupBodyCard span {
  margin: 0;
  font-family: Urbane;
  font-size: 18px;
  font-weight: 500;
  line-height: 28.8px;
  text-align: left;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #595bdb;
  width: 45px;
  height: 45px;
}

.popupBodyCard img {
  width: 54px;
  height: 100%;
}

.popupBodyBottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}

.popupBodyBottom p {
  margin: 0;
  font-family: Urbane;
  font-size: 18px;
  font-weight: 500;
  line-height: 28.8px;
  text-align: left;
  color: #5f5f5f;
}

.PopupButton {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  padding: 1rem 0;
}

.snoozeButton {
  width: 14rem;
  height: 3.35rem;
  border-radius: 2.5rem;
  font-family: Urbane;
  font-size: 20px;
  font-weight: 600;
  line-height: 27.65px;
  text-align: center;
  color: #fff;
  background: #00cbf4;
  border: none;
  cursor: pointer;
}

.yesButton {
  width: 14rem;
  height: 3.35rem;
  border-radius: 2.5rem;
  background: #ffbe00;
  font-family: Urbane;
  font-size: 20px;
  font-weight: 600;
  line-height: 27.65px;
  text-align: center;
  color: #fff;
  border: none;
  cursor: pointer;
}

.snoozeHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}

.snoozeHeader p {
  margin: 0;
  font-family: Urbane;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #5f5f5f;
}

.snoozeContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1.5rem 0;
  gap: 2rem;
}

.snoozeCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #00cbf4;
  padding: 1rem;
  border-radius: 44px;
  cursor: pointer;
}

.snoozeCard p {
  margin: 0;
  font-family: Urbane;
  font-size: 18px;
  font-weight: 600;
  line-height: 27.65px;
  text-align: center;
  color: #fff;
}
.videoPopup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #232323;
  border-radius: 31px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 99;
  box-shadow: 1px 1px 2.3px 0px #3f3f3f;
  border-radius: 10px;
}
.videoContainer {
  width: 50vw;
  height: 60vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: .3rem;
}

.videoContainer video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 25px;
  position: relative;
}
.videoClose {
  position: absolute;
  color: #fff;
  font-size: 1.75rem;
  font-weight: 800;
  z-index: 9999999;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .dashboardSection {
    width: 100%;
  }
  .container {
    padding: 0;
    width: 100vw;
    overflow-x: hidden;
    height: 100%;
  }
  .welcomeSection,
  .videoSection {
    margin: 0.3rem 0.5rem;
  }
  .activitySectionLeft {
    padding: 1rem 2rem;
    width: 55%;
  }
  .activitySectionRight {
    width: 45%;
    justify-content: center;
  }
  .moduleText p {
    font-size: 2rem;
    padding-right: 0.5rem;
  }
  .moduleText span,
  .exploreButton p {
    font-size: 1rem;
  }
  .exploreButton svg {
    font-size: 1rem;
  }
  .activityCard {
    padding: 1rem 0.5rem;
  }
  .activityCardText p {
    font-size: 18px;
    line-height: 1.5;
  }
  .activitySectionLeftTop h4 {
    font-size: 28px;
    line-height: 1.5;
  }
  .activitySectionLeftTop p {
    font-size: 0.9375rem;
    line-height: 1.5;
  }
  .statusSection {
    margin: 0 0.5rem;
  }
  .activitySection {
    margin: 1rem 0.5rem;
  }
}
@media screen and (max-width: 768px) {
  .welcomeSection {
    border-radius: 30px;
    padding: 1rem 0;
  }
  .welcomeSectionLeft p {
    font-size: 1.5rem;
    padding: 0.75rem 0;
  }
  .welcomeSectionLeft span {
    font-size: 1.125rem;
    padding: 0;
  }
  .videoSection {
    border-radius: 30px;
    padding: 1rem 0;
  }
  .videoSectionLeft p {
    font-size: 1.375rem;
    padding: 0;
  }
  .videoButtonSection {
    border-radius: 1.75rem;
    padding: 0.5rem 1rem;
  }
  .videoButtonSection p {
    font-size: 0.875rem;
  }
  .activitySection {
    flex-direction: column;
    align-items: center;
    margin: 0.3rem 0.5rem;
    gap: 0.3rem;
  }
  .activitySectionLeft {
    width: 100%;
    padding: 0;
    border-radius: 30px;
  }
  .activitySectionRight {
    width: 100%;
  }
  .activityCard {
    border-radius: 18px;
    margin: 0.3rem 0;
  }

  .activitySectionLeftTop {
    padding: 1rem;
  }
  .activitySectionLeftTop h4 {
    font-size: 1.75rem;
  }
  .activitySectionLeftTop p {
    font-size: 0.875rem;
  }
  .activitySectionLeftBottom {
    flex-direction: row;
    padding: 1rem;
  }
  .moduleText p {
    font-size: 1.8rem;
  }
  .moduleText span,
  .exploreButton p {
    font-size: 0.9375rem;
  }

  .statusSection {
    border-radius: 30px;
    flex-direction: column-reverse;
    border-radius: 25px;
  }

  .statusSectionRight {
    width: 100%;
    border-bottom: 3px solid #81808533;
    border-left: 0;
    flex-direction: row;
    padding-left: 0;
  }
  .statusSectionRightContent {
    width: 65%;
    height: 50%;
  }
  .statusSectionRightGraph canvas {
    position: absolute;
    padding-bottom: 10px;
  }
  .statusRightHeading {
    width: 100%;
  }
  .statusRightHeading p {
    font-size: 14px;
  }
  .statusRightHeading span {
    font-size: 11.33px;
  }
  .statusRightBody {
    width: 100%;
  }
  .statusRightButton {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    border-radius: 1.5rem;
    padding: 0.75rem 0;
  }
  .statusRightButton p {
    font-size: 12px;
  }
  .statusRightBody h4 {
    font-size: 16px;
    font-weight: 500;
    padding: 0.25rem 0;
  }
  .statusRightBody span {
    font-size: 15px;
  }
  .statusSectionLeft {
    width: 100%;
    padding-top: 1rem;
  }
  .statusHeading p {
    font-size: 13px;
  }
  .moduleHeading p {
    font-size: 18px;
  }
  .topicCardRight p {
    font-size: 14px;
  }
  .topicCardLeft p {
    font-size: 14px;
  }
  .statusSectionRightGraph {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 20vw;
    position: relative;
  }
  .percentageLabel {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px; 
  font-weight: bold;
  color: #5f5f5f;
  text-align: center;
  pointer-events: none;
}
}
@media screen and (max-width: 576px) {
  .welcomeSection {
    height: 13rem;
    margin: 0rem 0.5rem 0.5rem 0.5rem;
    flex-direction: column;
    border-radius: 25px;
  }
  .welcomeSectionLeft {
    width: 100%;
    height: 35%;
  }
  .welcomeSectionRight {
    width: 100%;
    height: 55%;
  }
  .welcomeSectionLeft p {
    font-size: 1.375rem;
    font-weight: 500;
    line-height: 1.5;
    padding: 0.75rem 1.5rem;
  }

  .welcomeSectionLeft span {
    font-size: 1rem;
    line-height: 1.5;
    padding: 0 1.5rem;
  }
  .welcomeImage {
    width: 10rem;
  }

  .videoSection {
    height: 7rem;
    border-radius: 25px;
    margin: 0 0.5rem;
    flex-direction: column;
    padding: 0 1.5rem;
  }

  .videoSectionRight,
  .videoSectionLeft {
    width: 100%;
    height: 50%;
    align-items: center;
    padding: 0.5rem;
    justify-content: center;
  }
  .videoSectionLeft p {
    font-size: 1rem;
    line-height: 1.5;
  }

  .videoButtonSection p {
    font-size: 0.8rem;
  }
  .videoButtonSection svg {
    font-size: 1.5rem;
    padding-right: 0.5rem;
  }
  .activitySection {
    flex-direction: column;
    align-items: center;
    margin: 0.5rem 0.5rem 0.3rem 0.5rem;
    gap: 0.2rem;
  }
  .activitySectionLeft {
    width: 100%;
    padding: 0;
    border-radius: 25px;
  }
  .activitySectionLeftTop {
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  }
  .activitySectionLeftBottom {
    padding: 0 1rem 2rem;
    flex-direction: row;
  }
  .activitySectionLeftTop h4 {
    font-size: 1.625rem;
    line-height: 1.5;
  }
  .activitySectionLeftTop p {
    font-size: 0.8125rem;
    line-height: 1.5;
  }
  .moduleText {
    flex-direction: column;
  }
  .moduleText p {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.5;
  }
  .exploreButton {
    width: 13rem;
  }
  .moduleText span,
  .exploreButton p {
    font-size: 0.8rem;
  }

  .activitySectionRight {
    width: 100%;
  }
  .activityCardContainer {
    flex-direction: row;
    justify-content: space-between;
  }
  .activityCard {
    border-radius: 18px;
    padding: 0.75rem 0rem;
    margin: 0.2rem 0;
    width: 49%;
    justify-content: center;
  }
  .activityCardText P {
    font-size: 16px;
    line-height: 1.5;
    padding: 0 0.25rem;
  }
  .activityCardImage {
    width: 2rem;
    height: 2rem;
    padding: 0 0.25rem;
  }
  .statusSectionRightGraph canvas {
    padding-bottom: 0;
  }
}
