.container {
    height: 82vh;
    overflow-y: auto;
    padding: 0 1rem;
}

.container::-webkit-scrollbar {
    width: 10px;
}

.container::-webkit-scrollbar-track {
    background: #D9D9D9;
    border-radius: 10px;
}

.container::-webkit-scrollbar-thumb {
    background: #8C8C8C;
    border-radius: 10px;
}

.container::-webkit-scrollbar-thumb:hover {
    background: #8C8C8C;

}
.learningBody{
    display: flex;
    flex-direction: column;
    width: 94%;
    height: 100%;
}

.header {
    padding: 1rem;
    background: #595BDB;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.headerIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 1.75rem;
    cursor: pointer;
}

.headerIcon img {
    width: 100%;
    height: 100%;
}

.headerText {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 2rem;
}

.headerText span {
    font-family: Urbane Extra;
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    text-align: left;
    margin: 0;
    color: #fff;
}

.headerText h4 {
    font-family: Urbane;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    margin: 0;
    color: #fff;
    padding: .5rem 0;
}
.learningContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 1px solid #00000040;
    border-radius: 25px 25px 0 0;
    margin-top: .5rem;
    padding: 10px;
    height:77%;
}

.learningSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    overflow-y: scroll;
}
.learningSection::-webkit-scrollbar {
    width: 6px;
}

.learningSection::-webkit-scrollbar-track {
    background: #D9D9D9;
    border-radius: 10px;
}

.learningSection::-webkit-scrollbar-thumb {
    background: #8C8C8C;
    border-radius: 10px;
}

.learningSection::-webkit-scrollbar-thumb:hover {
    background: #8C8C8C;

}

.learningContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    flex: 1;
}
.learningContent p{
    font-family: Urbane Extra;
    font-size: 16px;
    font-weight: 300;
    line-height: 40px;
    text-align: left;
    color: #2F2E32;
    
}
.learningContent ul{
    list-style-position: inside;
}
.learningContent ol{
    list-style-position: inside;
}

.assessmentFloatingSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #F5F5FF;
    border-radius: 15px 15px 0 0;
    padding: 1rem;
    position: sticky;
    bottom: 0%;
}

.floatingSectionLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 24%;

}

.floatingSectionLeft span {
    font-family: Urbane Extra;
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    text-align: left;
    color: #5F5F5F;

}

.floatingSectionLeft p {
    margin: 0;
    font-family: Urbane;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    color: #5F5F5F;
}

.floatingSectionRight {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.floatingSectionRight button {
    outline: none;
    border: none;
    border-radius: 35px;
    font-family: Urbane Extra;
    font-size: 15px;
    font-weight: 500;
    line-height: 27.65px;
    text-align: center;
    color: #fff;
    background: #00DFC7;
    height: 2rem;
    width: 10rem;
    cursor: pointer;
}
.info{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.info p{
    color: #5F5F5F;
    font-family: Urbane;
    font-size: 1.1rem;
    margin-top: 20%;
}
@media (max-width: 1024px){
    .learningContainer{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border: 1px solid #00000040;
        border-radius: 25px 25px 0 0;
        margin-top: .5rem;
        padding: 10px;
        height:100%;
    }
    
    .learningSection {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;
        overflow-y: unset;
       
    }
    .container {
        height: 100%;
        overflow-y: auto;
        padding: 0 1rem;
    }
    .learningBody{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }
    .learningContent img {
       width: 100%;
       height: auto;
    }
}
@media screen and (max-width:768px) {
    .headerText span {
        font-size: 14px;
    }
    
    .headerText h4 {
        font-family: Urbane;
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
        margin: 0;
        color: #fff;
        padding: .5rem 0;
    }
    .learningContent p{
        font-family: Urbane Extra;
        font-size: 14px;
        font-weight: 300;
        line-height: 40px;
        text-align: left;
        color: #2F2E32;
        
    }
    .assessmentFloatingSection {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: #F5F5FF;
        border-radius: 15px 15px 0 0;
        padding: 1rem;
        position: sticky;
        bottom: 0%;
        flex-wrap: wrap;
    }
    .floatingSectionLeft {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: fit-content;
        gap: 4px;
    
    }
    
    .floatingSectionLeft span {
        font-family: Urbane Extra;
        font-size: 16px;
        font-weight: 300;
        line-height: 30px;
        text-align: left;
        color: #5F5F5F;
    
    }
    
    .floatingSectionLeft p {
        margin: 0;
        font-family: Urbane;
        font-size: 16px;
        font-weight: 600;
        line-height: 30px;
        text-align: left;
        color: #5F5F5F;
    }
    
    
    .floatingSectionRight button {
        outline: none;
        border: none;
        border-radius: 35px;
        font-family: Urbane Extra;
        font-size: 14px;
    
    }
    .floatingSectionRight button {
        font-size: 15px;
        height: 2rem;
        width: fit-content;
        padding: 0 1rem;
    }
}