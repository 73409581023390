.Container{
    height: 100vh;
  overflow-y: scroll;
  padding: 0 1rem;
}
.Container::-webkit-scrollbar{
    display: none;
  }
  .Content{
    /* width: 100%; */
    display: flex;
    flex-direction: column;
  }
  .profile{
    /* width: 100%; */
    margin-top: .5rem;
    /* align-items: right; */
    border: 1px solid #dbdbdb;
    border-radius: 1rem;
    /* padding: 1rem; */
   
  }
  .profileimage img{
    width: 100%;
    /* height: 100%; */
    /* border-radius: 50%; */
  }
  .ProfileImageDiv{
    width: 12rem;
    padding:2px;
  }
  .profileimage{
    width: 12rem;
    display: flex;
    justify-content:center;
    align-items: center;
  }
  .Image{
    width: 8rem;
    
    height: 9rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Image span{
  border-radius: 50%;
  border: 1px solid;
  padding: 3rem 3rem;
  }
  .Analytics{
    border: 1px solid #dbdbdb;
    padding:5px 1rem;
    border-radius: 1rem;
    margin: .5rem 0;
  }
  .AnalyticsHead p{
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
    padding: 0 2px;
  }
  .analyticContent{
    margin-top: 5px;
    display: grid;
      /* width: 70rem; */
      height: auto;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 1rem;
      column-gap: 1px;
  }
  .AnalyticsCard{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
  }
  .AnalyticsCardcontent{
    width: fit-content;
  }
  .AnalyticsCardcontent p{
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0 10px;
    margin: 0;
  }
  .MainText{
    display: flex;
    flex-direction: row;
  }
  .FirstText{
    font-size: 1rem;
    font-weight: 700;
    width: 9rem;
    padding: 6px 0;
  }
  .SecondText{
    font-size: 1rem;
    font-weight: 700;
    padding: 6px 0;
  }
  .boldnumber {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .color{
    background-color: rgb(39, 79, 208);
  }
  .ProfileDetails{
  
    text-align: left;
    color: #1a1e5d;
    /* margin-left: 1rem; */
    font-weight: 500;
    font-size: 1rem;
    padding: 0 1rem;
  }
  .CardIcons{
    width: 1.75rem;
  }
  .CardIcons img{
    width: 100%;
  }
  .WhiteBoradContainer{
    width: 60rem;
height: 36rem;
border-radius: 16px;
background-color: #fff;
position: fixed;
top: 19%;
right: 1%;
border: 1px solid #dbdbdb;
/* transform: translate(-50%, -50%); */
animation: slideIn 0.5s forwards;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.closing {
    animation: slideOut 0.5s; /* Adjust the duration as needed */
  }
  @keyframes slideIn {
    from {
      right: -60rem; /* Off-screen initially */
    }
    to {
      right: 1rem; /* Final position */
    }
  }
  @keyframes slideOut {
    from {
      right: 1rem; /* Initial position when closing */
    }
    to {
      right: -60rem; /* Move off-screen to the right */
    }
  }
  .WhiteBoradHead{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid;
    padding: 0rem 1rem;
    margin: 0;
    border: none;
    border-radius:15px 15px 0 0 ;
  }
  .WhiteBoradIcon{
    cursor: pointer;
    font-size: 1.25rem;
    padding: 8px;
  }
  .WhiteBoradContent{
    height: 30rem;
    padding:.5rem 1rem;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  

  .chatboticon {
    position: fixed;
    top: 50%;
   right: 0%;
    /* color: #fff;  */
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* animation: slideIn 0.5s forwards; */
  }
 
  .Iconhead {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: #000;
    font-weight: bold;
    font-size: 1.25rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
  }
  .icontext {
    padding: .5rem;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    /* background-color: #e4c395; */
    border-radius: 6px;
    /* border: 1px solid; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:#1a1e5d;
  }
  .icontext p{
    margin: 0;
    padding: 0;
  }
  .WhiteboardFloatingIcon{
  font-size: 1.5rem;
  margin: 2px 0;
  color:#1a1e5d;
  }
  .ChatImage {
    width: 11rem;
    /* border-radius: 50%; */
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    /* background-color: #0f8bea; */
  }
  .ChatImage img {
    width: 100%;
  }
  @media screen and (max-width:720px) {
    .analyticContent{
      margin-top: 5px;
      display: grid;
        /* width: 70rem; */
        height: auto;
        grid-template-columns: repeat(2, 1fr);
        row-gap: .5rem;
        column-gap: 1px;
    }
    .AnalyticsCard{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px;
    }
    .AnalyticsCardcontent{
      width: fit-content;
    }
    .AnalyticsCardcontent p{
      font-size: 1rem;
      font-weight: 500;
      padding: 0 5px;
      margin: 0;
    }
  }
  @media screen and (max-width:600px) {
    .analyticContent{
      margin-top: 5px;
      display: grid;
        /* width: 70rem; */
        height: auto;
        grid-template-columns: repeat(1, 1fr);
        row-gap: .5rem;
        column-gap: 1px;
    }
  }
  @media screen and (max-width:520px){
    .FirstText {
      /* width: 100%; */
      padding-bottom: 6px; /* Add some space between the labels and values in mobile view */
    }
  
    .SecondText {
      width: 100%;
    }
    .FirstText{
      font-size: .9rem;
      padding: 6px 0;
      font-weight: 500;
    }
    .SecondText{
      font-size: .9rem;
      padding: 6px 0;
      font-weight: 500;
    }
    .Container{
    padding: 0 2px;
  }
  .ProfileDetails{
    font-size: 1rem;
    padding: 0 ;
    padding-left: 4px;
  }
  

    
  }