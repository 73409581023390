.Container {
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  transform: translateX(-100%);
  padding-bottom: 1rem;
  animation: slide-in 1s forwards;
}

@keyframes slide-in {
  to {
      transform: translateX(0);
  }
}


.ReadyCard {
 width: 70%;
  border-radius: 67px;
  box-shadow: 1px 2px 21.1px -8px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 2rem;
}

.Cardheading {
  text-align: center;
  align-items: center;
}
.cardBody {
  padding: 3rem;
}

.Cardheading p {
  font-size: 20px;
  font-weight: 500;
  line-height: 27.65px;
  text-align: center;
  margin: 0;
  padding-bottom: 1rem;
}

.Cardheading h3 {
  font-size: 28px;
  font-weight: 500;
  line-height: 39.61px;
  text-align: center;
  margin: 0;
  color: rgba(63, 63, 63, 1);
  padding-bottom: 1rem;
}

.Cardheading h3 span {
  font-size: 30px;
  font-weight: 700;
  line-height: 39.61px;
  text-align: center;
  margin: 0;
  color: rgba(89, 91, 219, 1);
}

.Readymsg {
  text-align: center;
  padding-bottom: 1rem;
  margin-top: -2rem;
}

.Readymsg p {
  font-size: 17px;
  font-weight: 400;
  line-height: 27.65px;
  color: rgba(63, 63, 63, 1);
  margin: 0;
  padding-bottom: 0.5rem;
}

.ReadyImg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.start {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.start button {
  padding: 1rem;
  margin-top: 0rem;
  width: 400px;
  border: none;
  border-radius: 25px;
  background: rgba(89, 91, 219, 1);
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
    font-family: Urbane;
    font-size: 23.04px;
    font-weight: 500;
    line-height: 27.65px;
    text-align: center;

}
