.Container {
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  transform: translateX(-100%);
  padding: 1rem;
  animation: slide-in 1s forwards;
  display: flex;
}

@keyframes slide-in {
  to {
      transform: translateX(0);
  }
}

.successCard {
  /* width: 1400px; */
  /* height: 662px; */
  border-radius: 67px;
  box-shadow: 1px 2px 21.1px -8px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.msg {
  text-align: center;
  align-items: center;
}

.msg p {
  font-size: 20px;
  font-weight: 400;
  line-height: 27.65px;
  color: rgba(63, 63, 63, 1);
  margin: 0;
  padding-bottom: 1rem;

}

.Cardheading {
  text-align: center;
  align-items: center;
}
.cardBody{
    padding: 3rem;
}

.Cardheading p {
  font-size: 20px;
  font-weight: 500;
  line-height: 27.65px;
  text-align: center;
  margin: 0;
  padding-bottom: 1rem;
}

.Cardheading h3 {
  font-size: 28px;
  font-weight: 800;
  line-height: 39.61px;
  text-align: center;
  margin: 0;
  color: rgba(63, 63, 63, 1);
  padding-bottom: 1rem;
}

.SuccessImg {
    padding-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcome {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.welcome p {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 27.65px;
  color: rgba(89, 91, 219, 1);
  padding: 1rem 0 1rem 0;
}

.welcome button {
  padding: 0.8rem;
  margin-top: 0rem;
  width: 400px;
  border: none;
  border-radius: 25px;
  background: rgba(89, 91, 219, 1);
  font-size: 26px;
  font-weight: 600;
  line-height: 31.21px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
@media (max-width:600px){
  .SuccessImg img{
    width: 35%;
  }
  .successCard{
    width: 90%;
  }
  .welcome button {
    padding: 0.8rem;
    margin-top: 0rem;
    width: 70%;
    border: none;
    border-radius: 25px;
    background: rgba(89, 91, 219, 1);
    font-size: 18px;
    font-weight: 600;
    line-height: 31.21px;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
  }
}