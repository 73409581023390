.Container {
  width: 100%;
  /* height: 100vh; */
  justify-content: center;
  align-items: center;
  position: relative;
}

.loginBody {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 1rem;
  gap: 6rem;

}
.loginBodyLeft{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
  padding: 2rem 0;

}
.loginBodyRight{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 50%;
}
.formBox{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 70%;
}

.welcomeHead {
  font-family: Urbane;
  font-size: 34px;
  font-weight: 500;
  line-height: 47px;
  text-align: left;
  color: rgba(95, 95, 95, 1);
  margin: 0;
  padding: 6px 0;
}

.welcomeHead span {
  color: rgba(89, 91, 219, 1);
}

.welcomeSubHead {
  font-family: Urbane;
  font-size: 20px;
  font-weight: 500;
  line-height: 27.65px;
  text-align: left; 
  color: rgba(95, 95, 95, 1);
  padding-top: 0.5rem;
  margin: 0;
}

.formContainer {
  display: flex;
  flex-direction: column;
}

.formFeilds {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.formFeilds input {
  background: transparent;
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: 300;
  line-height: 23px;
  text-align: left;
}

.EmailBox {
  background: rgba(89, 91, 219, 0.27);
  display: flex;
  justify-content: space-between;
  padding: 1.2rem 2rem;
  border-radius: 15px;
  margin-top: 1.35rem;
}

.passwordBox {
  background: rgba(89, 91, 219, 0.27);
  display: flex;
  justify-content: space-between;
  padding: 1.2rem 2rem;
  border-radius: 15px;
  margin-top: 1.35rem;
}

.eyeIco {
  font-size: 1.2rem;
  color: rgb(60, 63, 218);
  cursor: pointer;
}

.password {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  cursor: pointer;
}

.password {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  cursor: pointer;
}

.customCheckbox {
  display: flex;
  align-items: center;
  position: relative;
  font-family: Urbane Extra;
  font-size: 16px;
  font-weight: 300;
  line-height: 22.83px;
  color: rgba(89, 91, 219, 1);
}

.customCheckbox input[type="checkbox"] {
  opacity: 0; /* Hide the default checkbox */
  position: absolute; /* Remove it from layout */
  width: 0;
  height: 0;
}

.customCheckbox .checkmark {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(89, 91, 219, 1); /* Border color */
  border-radius: 4px;
  background-color: #fff; /* Background color */
  margin-right: 4px;
}

.customCheckbox input[type="checkbox"]:checked + .checkmark::after {
  content: "✓";
  position: absolute;
  left: 4px;
  top: 0;
  color: rgba(89, 91, 219, 1);
  font-size: 16px;
  font-weight: 700;
}

.password label {
  font-family: Urbane Extra;
  font-size: 16px;
  font-weight: 300;
  line-height: 22.83px;
  text-align: left;  
  color: rgba(89, 91, 219, 1);
  align-items: center;
  display: flex;
  justify-content: center;
}

.password p {
  font-size: 16px;
  font-weight: 300;
  line-height: 22.83px;
  text-align: left;
  color: rgba(89, 91, 219, 1);
  margin: 0;
  font-family: Urbane Extra;
}

.signUp {
  display: flex;
  justify-content: center;
  align-items: center;
  gap:.3rem;
  padding-top: 1rem;
}

.formContainer button {
  padding: 1rem;
  margin-top: 2rem;
  border: none;
  border-radius: 16px;
  background: rgba(89, 91, 219, 1);
  width: 100%;
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

.signUp p {
  color: rgba(95, 95, 95, 1);
  font-family: Urbane Extra;
  font-size: 16px;
  font-weight: 300;
  line-height: 22.5px;
  text-align: left;
  cursor: pointer;
}

.signUp p span {
  font-family: Urbane Demi;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.83px;
  text-align: left;  
  color:#595BDB;
  cursor: pointer;
}

.groupImg {
 width: 38rem;
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
 align-items: normal;

}
.groupImg img{
width: 100%;
height: 100%;
}

.popup {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  width: 116%;
  max-width: 400px;
  border-radius: 10px;
  text-align: center;
}

.popup input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.popupButton {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.popupButton button {
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
}

.popupSend {
  background-color: rgba(89, 91, 219, 1);
  color: white;
}

.popupCancel {
  background-color: #fc1100;
  color: white;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.errorMessage{
  color: #ff3b2d;
  font-family: Urbane Extra;
  font-size: 16px;
  font-weight: 300;
  line-height: 22.83px;
  text-align: left;  
  padding: 1rem 0;
}

/* media querry */

@media (max-width: 768px) {
  .topBar {
    flex-direction: column;
    align-items: center;
  }

  .topBarMenu {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    margin-right: 0;
  }

  .loginBody {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin: 2rem 0;
  }
  .loginBodyLeft{
    display: flex;
    flex-direction: row;
    justify-content:center;
    width: 80%;
  }
  .loginBodyRight{
    display: flex;
    flex-direction: row;
    justify-content:center;
    width: 80%;
  }

  .formBox {
    width: 90%;
  }

  .formContainer button {
    width: 100%;
  }
  .welcomeHead {
    font-size: 28px;
    padding: 6px 0;
  }
  .password label {
    font-family: Urbane Extra;
    font-size: 14px;
  }
  
  .password p {
    font-size: 14px;
  }
}
@media (max-width: 580px) {
  .loginBodyLeft{
    width: 95%;
  }
  .welcomeHead {
    font-size: 18px;
    padding: 6px 0;
  }
  .welcomeSubHead {
    font-size: 16px;
    padding-top: 0.5rem;
  }
  .password {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .5rem;
  }
  .signUp p {
    font-size: 14px;
  }
  
  .signUp p span {
    font-size: 14px;
  }
  .customCheckbox .checkmark {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
  .formContainer button {
    padding:.5rem 1rem;
    margin-top: 2rem;
    border: none;
    border-radius: 16px;
    background: rgba(89, 91, 219, 1);
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: 31px;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
  }
  

}