.Container{
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
}
.Syllubus{
    width: 99.85%;
    /* height: 100vh;
    margin-left: auto;
    margin-right: auto; */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid;
}
.Container::-webkit-scrollbar {
    display: none;
  }
.Title{
   width: 100%;
  font-family: drumshake_italic;
  font-size: 1.5rem;
  margin: 0;
  margin-top: 2rem;
  background-color: transparent;
  text-decoration: underline;
}
.Title p{
    margin-left: 2rem;
}
.List {
width: 100%;
/* margin-left: 2rem; */
}
.Cards{
    width:25rem;
    height: 10rem;
    border-radius: 2rem;
    margin-top: .5rem;

    border: 1px solid;
}
.cardContent{
    width: 100%;
    align-items: center;
    margin-left: 2rem;
}
.cardContent p{
    font-size: 1.25rem;
    font-weight: 500;
}
li{
    color: rgb(15, 86, 240);
    cursor: pointer;

}