.Container{
    width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url("../../assests/bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
    
  }
  .Content{
    width: 30rem;
    display: flex;
    flex-direction: column;
  align-items: center;
  margin: 0 auto;
}
.Logo img{
    width: 100%;
}
.Logo{
    width: 100px;
}
.ValidationContent{
    display: flex;
    flex-direction: column;
  justify-content: center;
  border: 1px solid;
  border-radius: 10px ;
  color: #fff;
}
.InvitationHead{
    background-color: #eca139;
    border-radius: 10px 10px 0 0;
}
.InvitationHead p{
    margin: 0;
    padding: 6px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
    color: #fff;
}
.ValidationButton{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 3px 0;
}
.ValidationButton button{
    font-size: 1rem;
  padding: 10px 2rem;
  text-align: center;
  border-radius: 10px;
  margin: 1rem 0;
  border: none;
  background-color: #1a1e5d;
  color: #fff;
  cursor: pointer;
}

.ValidationText p{
 margin: 0;
 padding: 1rem;
 font-size: 1.2rem;
 text-align: center;
}
.SearchText p{
    font-weight: 700;
    font-size: 3rem;
    margin: 0;
    padding:1rem 0;
    color: #fff;
    text-align: center;
    
  }
  @media screen and (max-width:500px) 
  {
    .SearchText p{
      font-weight: 700;
      font-size: 2rem;
      margin: 0;
      padding:1rem 0;
      color: #fff;
      text-align: center;
      
    }
    .Content{
      width: 90%;
      display: flex;
      flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }
  }