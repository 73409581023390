.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.FailCard {
width: 80%;
  border-radius: 67px;
  box-shadow: 1px 2px 21.1px -8px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Cardheading {
  text-align: center;
  align-items: center;
}
.cardBody {
  padding: 2rem;
}

.Cardheading p {
  font-size: 24px;
  font-weight: 500;
  line-height: 27.65px;
  text-align: center;
  margin: 0;
  padding-bottom: 1rem;
}

.Cardheading h3 {
  font-size: 34px;
  font-weight: 800;
  line-height: 39.61px;
  text-align: center;
  margin: 0;
  color: rgba(63, 63, 63, 1);
  padding-bottom: 1rem;
}

.Cardheading h3 span {
  font-size: 34px;
  font-weight: 800;
  line-height: 39.61px;
  text-align: center;
  margin: 0;
  color: rgba(89, 91, 219, 1);
}

.Failmsg {
  text-align: center;
  padding-bottom: 3rem;
}

.Failmsg p {
  font-size: 20px;
  font-weight: 400;
  line-height: 27.65px;
  color: rgba(63, 63, 63, 1);
  margin: 0;
  padding-bottom: 1rem;
}

.FailImg {
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.try {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.try button {
  padding: 0.8rem;
  margin-top: 0rem;
  width: 400px;
  border: none;
  border-radius: 25px;
  background: rgba(89, 91, 219, 1);
  font-size: 22px;
  font-weight: 600;
  line-height: 31.21px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
@media (max-width:600px){
  .try button {
    width: 60%;
    font-size: 22px;
  }
  .FailImg img{
    width: 35%;
  }
}
@media (max-width:450px){
  .Cardheading p {
    font-size: 18px;
    font-weight: 500;
    line-height: 27.65px;
    text-align: center;
    margin: 0;
    padding-bottom: 1rem;
  }
  
  .Cardheading h3 {
    font-size: 24px;
    font-weight: 800;
    line-height: 39.61px;
    text-align: center;
    margin: 0;
    color: rgba(63, 63, 63, 1);
    padding-bottom: 1rem;
  }
  .Failmsg p {
    font-size: 16px;
    font-weight: 400;
    line-height: 27.65px;
    color: rgba(63, 63, 63, 1);
    margin: 0;
    padding-bottom: 1rem;
  }
}