.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
}

.contentLeft {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 52%;
    padding: 2rem 1rem;
    align-items: center;
    padding-left: 3rem;
}

.resetCard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2rem 3rem;
    border-radius: 55px;
    box-shadow: 0px 0px 7.2px 1px #D9D9D999;
    background: #FFFFFF;
    width: 80%;
    /* height: 63vh; */
}
.cardContent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 7% 0;
}

.cardHeader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.cardHeader h4 {
    font-family: Urbane;
    font-size: 34px;
    font-weight: 500;
    line-height: 45px;
    text-align: left;
    color: #000000;
    margin: 0;
    padding: .75rem 0;
}

.cardHeader span {
    font-family: Urbane;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    text-align: left;
    color: #5F5F5F;
    margin: 0;
}

.cardBody {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem 0;
}

.emailInput {
    background: rgba(89, 91, 219, 0.27);
    padding: 0 2rem;
    border-radius: 25px;
    display: flex;
    margin: .75rem 0;
    height: 4.625rem;
}

.emailInput input {
    background: transparent;
    outline: none;
    border: none;
    font-family: Urbane;
    font-size: 19.2px;
    font-weight: 300;
    line-height: 23.04px;
    text-align: left;
    width: 100%;
}

.cardButton {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: flex-start;
}

.cardButton button {
    margin-top: .75rem;
    border: none;
    border-radius: 25px;
    background: rgba(89, 91, 219, 1);
    font-size: 26px;
    font-weight: 600;
    line-height: 30px;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
    height: 4.625rem;
    width: 100%;
}

.cardBottomText {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: .75rem 0;
}

.cardBottomText p {
    font-family: Urbane Extra;
    font-size: 16px;
    font-weight: 300;
    line-height: 23.04px;
    text-align: left;
    margin: 0;
    color: #3F3F3F;

}

.cardBottomText span {
    font-family: Urbane Demi;
    font-size: 16px;
    font-weight: 600;
    line-height: 23.04px;
    text-align: left;
    color: #595BDB;

}

.otpSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
}

.otpInput {
    background: rgba(89, 91, 219, 0.27);
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 5.25rem;
    height: 4.625rem;
}

.otpInput input {
    background: transparent;
    outline: none;
    border: none;
    font-family: Urbane;
    font-size: 19.2px;
    font-weight: 300;
    line-height: 23.04px;
    text-align: center;
    width: 100%;
}

.passwordCondition {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.specialCharacter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-family: Urbane;
    font-size: 24px;
    font-weight: 300;
    line-height: 18.8px;
    text-align: left;
    color: #595BDB;
    padding: 0 4px;
}

.passwordConditionText {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.passwordConditionText p {
    font-family: Urbane;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    margin: 0;
    color: #5F5F5F;

}

.passwordConditionText span {
    font-family: Urbane;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #00DFC7;

}

.successContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding:2rem 1rem;
}

.successImage {
    width: 14.5rem;
    height: 14.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: center;
}

.successImage img {
    width: 100%;
    height: 100%;
}

.successText {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4rem 0;
}

.successText p {
    font-family: Urbane;
    font-size: 23.04px;
    font-weight: 600;
    line-height: 27.65px;
    text-align: left;
    color: #3F3F3F;
    margin: 0;
}

.contentRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 44%;
    align-items: center;
}

.rightImage {
    width: 38rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.rightImage img {
    width: 100%;
    height: 100%;
}
.errorText{
    font-size: 1rem;
    color: red;
    text-align: center;
    font-family: Urbane;
    font-weight: 500;
}
.resendText{
    font-size: .9rem;
    color: rgb(23, 129, 222);
    text-align: center;
    font-family: Urbane;
    font-weight: 500;
}
@media (max-width:1025px) {
    .container{
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        
    }
    .contentLeft {
        width: 70%;
        padding: 2rem 1rem;
        align-items: center;
    }
    
    .resetCard {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 2rem 3rem;
        border-radius: 55px;
        box-shadow: 0px 0px 7.2px 1px #D9D9D999;
        background: #FFFFFF;
        width: 80%;
        /* height: 63vh; */
    }
    .contentRight {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    align-items: center;
    }
    .otpSection {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0;
        gap: 1rem;
    }

    
}
@media (max-width:600px){
    .contentLeft {
        width: 90%;
        padding: 2rem 1rem;
        align-items: center;
    }
    .resetCard {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 2rem ;
        border-radius: 55px;
        box-shadow: 0px 0px 7.2px 1px #D9D9D999;
        background: #FFFFFF;
        width: 80%;
        /* height: 63vh; */
    }
    .successContent {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem;
    }
    
    .successImage {
        width: 12rem;
        height: 12rem;
        display: flex;
        align-items: center;
        justify-content: center;
        justify-content: center;
    }
    
    .successImage img {
        width: 100%;
        height: 100%;
    }
    
    .successText {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 2rem 0;
    }
    
    .successText p {
        font-family: Urbane;
        font-size: 20px;
        font-weight: 600;
        line-height: 27.65px;
        text-align: center;
        color: #3F3F3F;
        margin: 0;
    }
}
@media (max-width:430px){
    .contentLeft {
        width: 90%;
        padding:1rem;
        align-items: center;
    }
    .otpInput {
        background: rgba(89, 91, 219, 0.27);
        border-radius: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 3rem;
    }
    .cardHeader h4 {
        font-family: Urbane;
        font-size: 24px;
        font-weight: 500;
        line-height: 45px;
        text-align: left;
        color: #000000;
        margin: 0;
        padding: .75rem 0;
    }
    
    .cardHeader span {
        font-family: Urbane;
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
        text-align: left;
        color: #5F5F5F;
        margin: 0;
    }
}