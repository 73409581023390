.Container{
  width: 100%;
  height: 100vh;
  padding: 0 20px;
  
}
.InvitationContainer{
    width: 30rem;
    display: flex;
    flex-direction: column;
  align-items: center;
  margin: 0 auto;
}
.InvitationLogo img{
    width: 100%;
}
.InvitationLogo{
    width: 100px;
    margin: 1rem 0;
}
.InvitationContent{
    display: flex;
    flex-direction: column;
  justify-content: center;
  border: 1px solid;
  border-radius: 10px 10px 0 0;
}
.InvitationHead{
    background-color: #eca139;
    border-radius: 10px 10px 0 0;
}
.InvitationHead p{
    margin: 0;
    padding: 6px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
    color: #fff;
}
.InvitationButton{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 3px 0;
}
.InvitationButton button{
    font-size: 1rem;
  padding: 10px 2rem;
  text-align: center;
  border-radius: 10px;
  margin: 1rem 0;
  border: none;
  background-color: #1a1e5d;
  color: #fff;
  cursor: pointer;
}

.InvitationText p{
 margin: 0;
 padding: 1rem;
 font-size: 1.2rem;
}
@media screen and (max-width:550px) {
  .InvitationLogo{
    width: 90px;
}
.InvitationText p{
  margin: 0;
  padding:.5rem .7rem;
  font-size: 1rem;
 }
 .InvitationContainer{
  width: 90%;
  display: flex;
  flex-direction: column;
align-items: center;
margin: 0 auto;
}
.InvitationHead p{
  margin: 0;
  padding: 6px;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 700;
  color: #fff;
}
.InvitationButton button{
  font-size: 1rem;
padding: 10px 1rem;
text-align: center;
border-radius: 10px;
margin: 1rem 0;
border: none;
background-color: #1a1e5d;
color: #fff;
cursor: pointer;
}
  
}