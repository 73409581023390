@font-face {
    font-family: 'Urbane';
    src: url('./assests/Urbane-Medium.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Urbane Extra';
    src: url('./assests/Urbane-Light.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Urbane Demi';
    src: url('./assests/Urbane-DemiBold.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Urbane Thin';
    src: url('./assests/Urbane-Thin.ttf') format('truetype');
  }
  
  .App{
   
    width: 100%;
    margin: 0;
    font-family: "Urbane";
  }

  .layer{
    display: flex;
    flex-direction: column;
    width: 100%;


  }