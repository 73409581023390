.Container {
  /* height: 100vh; */
  justify-content: center;
  align-items: center;
  position: relative;
  transform: translateX(-100%);
  animation: slide-in 1s forwards;
  /* padding-bottom: 2rem; */
  /* overflow: hidden; */
}


@keyframes slide-in {
  to {
      transform: translateX(0);
  }
}

@media (min-width: 1920px) {
  .Container {
        overflow: hidden;
  }
}


.RegisterBody {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 1rem;
}
.registerBodyLeft{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
  padding: 2rem 0;
  padding-right: 3rem;
}
.registerBodyRight{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 50%;
  padding-left: 4rem;
}
.formBox{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 73%;
}


.welcomeHead {
  font-family: Urbane;
  font-size: 30px;
  font-weight: 500;
  line-height: 39.82px;
  text-align: left;
  color: #3F3F3F;
  margin: 0;
}

.welcomeHead span {
  color: rgba(89, 91, 219, 1);
}

.welcomeSubHead {
  font-family: Urbane;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.04px;
  text-align: left;  
  color: #3F3F3F;
  padding-top: 0.5rem;
  margin: 0;
}

.formContainer {
  display: flex;
  flex-direction: column;
}

.formFeilds {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.formGroup{
  display: flex;
  gap: 2rem;
  width:100%;
  margin-top: 1.5rem;
}

.names input {
  background: transparent;
  outline: none;
  border: none;
  font-family: Urbane Extra;
  font-size: 16px;
  font-weight: 300;
  line-height: 19.2px;
  text-align: left;  
  width: 100%;
}
.error {
  border: 1px solid red;
}

.Username , .selectContainer{
  background: rgba(89, 91, 219, 0.27);
  padding: 0.98rem 2rem;
  border-radius: 25px;
  display: flex;
  width: 100%;
}

.selectContainer select{
  font-weight: 500;
  background: rgba(89, 91, 219, 0.27);
}

.emailBox{
  background: rgba(89, 91, 219, 0.27);
  padding: 0.98rem 2rem;
  border-radius: 25px;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
}


.emailBox input {
  background: transparent;
  outline: none;
  border: none;
  font-family: Urbane Extra;
  font-size: 16px;
  font-weight: 300;
  line-height: 19.2px;
  text-align: left;  
  width: 90%;
}
.checkMark{
  font-size: 1rem;
  color: #898890;
  position: absolute;
  right: -1.5rem;
}
.passwordForm input {
  background: transparent;
  outline: none;
  border: none;
  font-family: Urbane Extra;
  font-size: 16px;
  font-weight: 300;
  line-height: 19.2px;
  text-align: left;  
  width: 100%;
}


.names{
  background: rgba(89, 91, 219, 0.27);
  padding: 0.98rem 2rem;
  border-radius: 25px;
  display: flex;
  width: 100%;
}

.Username input {
  background: transparent;
  outline: none;
  border: none;
  font-family: Urbane Extra;
  font-size: 16px;
  font-weight: 300;
  line-height: 19.2px;
  text-align: left;  
  width: 100%;
}

.selectContainer select {
  background: transparent;
  outline: none;
  border: none;
  font-size: 19.2px;
  font-weight: 300;
  line-height: 23.04px;
  text-align: left;
  width: 100%;
  font-family: Urbane Extra;
  font-size: 16px;
  font-weight: 300;
  line-height: 19.2px;
  text-align: left;
  color: rgb(116, 116, 116);
}

.passwordForm{
  background: rgba(89, 91, 219, 0.27);
  padding: 0.98rem 2rem;
  border-radius: 25px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;
}


.eyeIco{
  font-size: 1.35rem;
  color: rgb(60, 63, 218);
  cursor: pointer;
}

.declare {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
  cursor: pointer;
  width: 100%;
}

.customCheckbox {
  display: flex;
  position: relative;
  font-family: Urbane Extra;
  font-size: 19.2px;
  font-weight: 300;
  line-height: 23.04px;
  color: rgba(95, 95, 95, 1);
}

.customCheckbox input[type="checkbox"] {
  opacity: 0; /* Hide the default checkbox */
  position: absolute; /* Remove it from layout */
  width: 0;
  height: 0;
}

.customCheckbox .checkmark {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(89, 91, 219, 1); /* Border color */
  border-radius: 4px;
  background-color: #fff; /* Background color */
  margin-right: 8px;
}

.customCheckbox input[type="checkbox"]:checked + .checkmark::after {
  content: "✓"; 
  position: absolute;
  left: 4px;
  top: 0px;
  color: rgba(89, 91, 219, 1); 
  font-size: 14px;
  font-weight: 700;
}

.declare p {
  font-family: Urbane Extra;
  font-size: 17px;
  font-weight: 300;
  line-height: 23.04px;
  text-align: left;
  color: rgba(95, 95, 95, 1);
  margin: 0;
  width: 100%;
}

.declare span {
  font-family: Urbane;
  font-size: 17px;
  font-weight: 300;
  line-height: 23.04px;
  text-align: left;
  color: rgba(89, 91, 219, 1);
  margin: 0;
}
.declare a{
  font-family: Urbane;
  font-size: 17px;
  font-weight: 300;
  line-height: 23.04px;
  text-align: left;
  color: rgba(89, 91, 219, 1);
  margin: 0;
  text-decoration: none;
}

.signUp {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;
}

.formContainer button {
  padding: 1rem;
  margin-top: 1rem;
  border: none;
  border-radius: 25px;
  background: rgba(89, 91, 219, 1);
  font-size: 24px;
  font-weight: 600;
  line-height: 31.21px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  width: 100%;
}

.signUp p {
  color: rgba(95, 95, 95, 1);
  font-family: Urbane Extra;
  font-size: 17px;
  font-weight: 300;
  line-height: 23.04px;
  text-align: left;    
  cursor: pointer;
}

.signUp p span {
  font-family: Urbane Demi;
  font-size: 17px;
  font-weight: 300;
  line-height: 23.04px;
  text-align: left;  
  color: rgba(89, 91, 219, 1);
  cursor: pointer;
}

.groupImg {
  width: 38rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: normal;
 height: fit-content;
 }
 .groupImg img{
 width: 100%;
 height: 100%;
 }

.popup {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    width: 116%;
    max-width: 400px;
    border-radius: 10px;
    text-align: center;
}

.popup input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.popupButton {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.popupButton button {
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
}



.popupSend  {
  background-color: rgba(89, 91, 219, 1);
  color: white;
}

.popupCancel  {
  background-color: #fc1100;
  color: white;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.passwordCondition {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 0;
}

.specialCharacter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: Urbane;
  font-size: 24px;
  font-weight: 300;
  line-height: 18.8px;
  text-align: left;
  color: #595BDB;
  padding: 0 4px;
}

.passwordConditionText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.passwordConditionText p {
  font-family: Urbane;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  margin: 0;
  color: #5F5F5F;

}

.passwordConditionText span {
  font-family: Urbane;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #00DFC7;

}




/* --------- media querry --------- */

@media (max-width: 1074px) {
  .topBar {
    flex-direction: column;
    align-items: center;
  }

  .topBarMenu {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    margin-right: 0;
  }

  .RegisterBody {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin: 2rem 0;
  }

  .formBox {
    width: 90%;
  }

  .formContainer button {
    width: 100%;
  }
  .registerBodyLeft{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80%;
    padding: 2rem 0;
  }
  .registerBodyRight{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 80%;
    padding-left: 4rem;
  }
  
}

@media (max-width: 768px) {
  .topBar {
    flex-direction: column;
    align-items: center;
  }

  .topBarMenu {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    margin-right: 0;
  }

  .RegisterBody {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin: 2rem 0;
  }

  .formBox {
    width: 90%;
  }

  .formContainer button {
    width: 100%;
  }
  .registerBodyLeft{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80%;
    padding: 2rem 0;
  }
  .registerBodyRight{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 80%;
    padding-left: 4rem;
  }
  
  
  .welcomeHead {
    font-family: Urbane;
    font-size: 26px;
    font-weight: 500;
    line-height: 39.82px;
    text-align: left;
    color: #3F3F3F;
    margin: 0;
  }
  
  .welcomeSubHead {
    font-family: Urbane;
    font-size: 16px;
    font-weight: 500;
    line-height: 23.04px;
    text-align: left;  
    color: #3F3F3F;
    padding-top: 0.5rem;
    margin: 0;
  }
  
}
@media (max-width: 540px) {

  .registerBodyLeft{
    width: 95%;
    padding: 2rem 0;
  }
  
  .welcomeHead {
    font-family: Urbane;
    font-size: 22px;
    font-weight: 500;
    line-height: 39.82px;
    text-align: left;
    color: #3F3F3F;
    margin: 0;
  }
  
  .welcomeSubHead {
    font-family: Urbane;
    font-size: 14px;
    margin: 0;
  }
  .formFeilds {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    align-items: center;
  }
  .formGroup{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 1.5rem;
    align-items: center;
  }
  .Username ,.emailBox,.names,.passwordForm{
    width:80%;
  }
  .selectContainer  {
    width: 80%;
  }

  .formContainer button {
    padding:.5rem 1rem;
    font-size: 20px;
    width: 100%;
  }
  
}





