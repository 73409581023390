.Container {

  height: 100vh;
  padding: 0 1rem;
  /* background: linear-gradient(#fff,#dbdbdb); */
  overflow-y: scroll;
}
.Container::-webkit-scrollbar{
  display: none;
}
.Head {
  width: 100%;
  margin-top: 1rem;
}
.label {
  color: #1a1e5d;
  font-weight: 500;
}
.Input {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  border: 2px solid #dbdbdb;
  padding: 4px 6px;
}
.Input input {
  width: 100%;
  font-size: 1.2rem;
  height: 1.75rem;
  color: #1a1e5d;
  outline: none;
  border: none;
}
.Icons {
  font-size: 1.5rem;
}
.Content {
  width: 100%;
  height: auto;
  margin-top: 2rem;
}
.Grid {
  /* width: 100%; */
  height: auto;
  border: 2px solid #cac9c9;
  border-radius: 5px;
  display: flex;
    padding: 1rem 0;
    flex-wrap: wrap;
    background-color: #fff;
}
.Heading {
  width: 100%;
}
.Heading p {
  color: #1a1e5d;
  font-weight: 500;
  padding: 0;
  margin: 5px 0;
}
.card {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #1a1e5d;
  border-radius: 1rem;
  padding: 8px 5px ;
  margin: 10px ;
  cursor: pointer;
}
.card p{
    font-size: 1rem;
    margin: 0;
    padding: 0 8px;
    color: #1a1e5d;
    font-weight: 500;
}
.POPUP{
    width: 50rem;
  /* height: 27rem; */
  padding: 1rem 2rem;
  border-radius: 15px;
  border: 1px solid #949393;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Overlay{
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color:rgba(4, 4, 4, 0.793);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.Close{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 92%;
    font-size: 1.25rem;
    /* margin: 0.75rem 0; */
    position: absolute;
    top: 10px;
    color: #666565;
    font-size: 1.5rem;
    cursor: pointer;
}
.goalHead {
    font-size: 1rem;
    width: 100%;

    font-weight: 500;
  }
  .goalInput {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0.25rem 0;
    align-items: center;
    
  }
  .CommentInput{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0.25rem 0;
    /* align-items: center; */
  }
  .goalInput p {
    font-size: 1rem;
  }
  .goalInput input {
    width: 80%;
  }
  .CommentInput textarea {
    width: 78.9%;
    resize: none;
    border: 2px solid #dbdbdb;
    border-radius: 6px;
    outline: none;
    padding: 5px;
  }
  .goalInput select {
    width: 80%;
    height: 25px;
    width: 80%;
    height: 32px;
    font-size: 1rem;
    outline: none;
    border: 2px solid #dbdbdb;
    background-color: transparent;
    border-radius: 6px;
    cursor: pointer;
  }
  .goalButton {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    margin: 1rem 0;
    margin-top: 2rem;
  }
  .goalButton button {
    width: 100px;
    height: 40px;
    background: #1a1a5b;
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 1rem;
    padding: 0.5rem;
    cursor: pointer;
  }
  .topic {
    flex-direction: column;
    display: flex;
    width: 100%;
   
    align-items: flex-start;
  }
  .topicHead {
    width: 100%;
    line-height: 0;
    color: #615f5f;
  }
  .topicHead h4 {
    font-size: 1rem;
  }
  .Topicdiv{
    width: 94%;
  }
  .topicDetails {
    width: 100%;
    height: 20rem;
    overflow-y: scroll;
  }
  .topicDetails::-webkit-scrollbar{
    display: none;
  }
  .topicDetails p {
    font-size: 1.25rem;
    border: 2px solid #dbdbdb;
    background-color: #ebe9e9;
    padding: 3px 1rem;
    margin: 9px 0;
    border-radius: 4px;
  }
  .learningButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 1rem;
  }
  .learningButton button {
    background: #262673;
    outline: none;
    border: none;
    color: #fff;
    width: 150px;
    height: 35px;
    font-size: 1rem;
    border-radius: 5px;
    padding: 0.5rem;
    cursor: pointer;
  }
  .Header{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    border-bottom: 2px solid #b1b1b1;
  }
  .HeaderText{
    width: 100%;
  }
  .HeaderText p{
    font-size: 1.25rem;
    margin: 0;
    color:#1a1e5d ;
    font-weight: 500;
  }
  .HeaderIcons{
    width: 100%;
    text-align: right;
    color:#1a1e5d ;
    font-size: 1.5rem;
  }
  .CategoryDiv{
    /* width: 100%; */
    height: auto;
    min-height: 81.9vh;
    border: 2px solid #dbdbdb;
    margin-top: 2rem;
    padding: 1rem 3rem;
    background: linear-gradient(#fff,#d7d7d7);
    
  }
  .AddressSection {
    width: 25rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;

    z-index: 9999;
  
  }
  .AddressSection form {
    background-color: #efefef;
    padding: 2rem 2rem ;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 99;
  }
  .AddressSection form input
  {
    width: 100%;
    font-size: 1rem;
    background-color: #fff;
    padding: 5px 1px;
    text-align: center;
    border-radius:5px ;
    margin: 10px 0;
    border: 3px solid #696969c1;
    
  }
  .AddressSection form input:focus {
    border-color: transparent;
  }
  .AddressSection form {
    align-items: center;
  }
  .AddressSection form button {
    width: 70%;
    border-radius: 50px;
    background-color: #3b82f6;
    border: none;
    outline: none;
    height: 2rem;
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
    margin-top: 1rem;
  }
  .AddressSection form input::-webkit-input-placeholder {
    text-align: center;
    text-transform: uppercase;
  }
  .Formtext{
    width: 100%;
  }
  .Formtext p{
    margin: 4px 1rem;
    font-size: 1.2rem;
  }
  .ProfileOverlay{
    width: 100vw;
  height: 100vh;
  position: absolute;
  background-color:rgba(4, 4, 4, 0.793);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  }
  .Required{
    color:red;
  }
  .GoalDiv{
    width: 95%;
  }
  .checkbox{
    display: flex;
    flex-direction: row;
    width: 100%;
    /* justify-content: space-between; */
    margin: 5px 0;
    
  }
  .mainLabel{
    width: 25rem;
  }
  .EmptyMessage p{
    margin: 0;
    padding: 6px;
    color: rgb(245, 30, 30);
  }
  @media screen and (max-width:900px){
    .POPUP{
      width: 80%;
    padding: 1rem 1rem;
    
  }
  }
  @media screen and (max-width:650px){
    .POPUP{
      width: 90%;
    padding:2px 5px;
    
  }
  }
  @media screen and (max-width:600px){
    .Container {
      padding: 0 .5rem;
    }
    .Head {
      margin-top: .5rem;
    }
    .CategoryDiv{
      margin-top: 1rem;
      padding: 1rem .5rem;
      
    }
    .goalButton {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin: 1rem 0;
      margin-top: 0rem;
    }
    .mainLabel{
      width: 17rem;
    }
  }
  @media screen and (max-width:500px){
    .AddressSection {
      width: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      justify-content: center;

      z-index: 9999;
    
    }
    .AddressSection form {
      background-color: #efefef;
      padding:  1rem ;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 99;
    }
    .AddressSection form input
  {
    width: 100%;
    font-size: .9rem;
    background-color: #fff;
    padding: 5px 1px;
    text-align: center;
    border-radius:5px ;
    margin: 10px 0;
    border: 1px solid #696969c1;
    
  }
  .Formtext p{
    margin: 4px .5rem;
    font-size: 1rem;
  }
  .AddressSection form button {
    width: 60%;
    border-radius: 50px;
    background-color: #3b82f6;
    border: none;
    outline: none;
    height: 2rem;
    font-size: .9rem;
    font-weight: 600;
    color: #fff;
    margin-top: 1rem;
  }
  } 
  