.container {
    height: calc(100vh - 17vh);
    overflow-y: auto;
    padding: 0 2rem;
}

.container::-webkit-scrollbar {
    width: 10px;
}

.container::-webkit-scrollbar-track {
    background: #D9D9D9;
    border-radius: 10px;
}

.container::-webkit-scrollbar-thumb {
    background: #8C8C8C;
    border-radius: 10px;
}

.container::-webkit-scrollbar-thumb:hover {
    background: #8C8C8C;

}


.subscription {
    display: flex;
    flex-direction: column;
    width: 93%;
    gap: 1rem;
}

.welcomeSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(91.43deg, #00CBF4 7.29%, rgba(0, 142, 225, 0.35) 142.96%, #0151CD 142.97%, #032F82 142.99%);
    border-radius: 45px;
}

.welcomeSectionLeft {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 45%;
    padding: 1rem;
}

.welcomeSectionLeft p {
    font-family: Urbane Demi ;
    font-size: 22px;
    font-weight: 600;
    line-height: 39.82px;
    text-align: left;
    margin: 0;
    padding: .75rem 1rem;
    color: #fff;
}

.welcomeSectionLeft span {
    color: #fff;
    font-family: Urbane Extra;
    font-size: 16px;
    font-weight: 300;
    line-height: 27.65px;
    text-align: left;
    margin: 0;
    padding: 0 1rem;
}

.welcomeSectionRight {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 45%;
}

.welcomeImageContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding-right: 20%;
}

.welcomeImage {
width: 10rem; 
height: 10rem; 
}

.welcomeImage img {
    width: 100%;
    height: 100%;
}
.planSection{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.planHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 0;
}
.planHeader p{
    font-family: Urbane;
    font-size: 18px;
    font-weight: 500;
    line-height: 26.4px;
    text-align: left;
    margin: 0;
    color: #818085;
}
.subscriptionPlan{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 55px;
    background: #FFFFFF;
    box-shadow: 0px 0px 7.2px 1px #D9D9D999;
    padding: 2rem;
    gap: 1rem;
    margin-bottom: 1rem;
}
.subscriptionPlanTop{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.subscriptionPlanTop p{
    font-family: Urbane;
    font-size: 16px;
    font-weight: 600;
    line-height: 26.4px;
    text-align: left;
    color: #595BDB;    
    margin: 0;
    cursor: pointer;
}
.subscriptionPlanTop button{
  border: none;
  border-radius: 16px;
  background: rgba(89, 91, 219, 1);
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  padding: 1rem 1.5rem ;
}
.subscriptionPlanBottom{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
}
.subscriptionPlanBottom h4{
    font-family: Urbane;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    color: #3F3F3F;
    margin: 0;
}
.subscriptionPlanBottom p{
    font-family: Urbane;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #3F3F3F;
    margin: 0;
}
.subscriptionDetail{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.detailTable {
    width: 100%;
    border-collapse: collapse; 
}

.detailTable thead {
    border-bottom: 1px solid #DCDCDC; 
}

.detailTable th {
    text-align: left;
    font-family: Urbane;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    color: #383838;
    padding: .35rem 0;
    border-bottom: 1px solid #DCDCDC; 
}

.detailTable td {
    text-align: left;
    padding: .35rem 0;
    font-family: Urbane;
    font-size: 16px;
    font-weight: 500;
    line-height: 26.4px;
    color: #818085;
}
.downloadSection{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
}
.downloadCard{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: .6rem;
}
.downloadCard p{
    font-family: Urbane Demi;
    font-size: 14px;
    font-weight: 600;
    line-height: 26.4px;
    text-align: left;
    margin: 0;
    color: #818085;
}
.downloadCard img{
   width: 3vh;
   height: auto;
   cursor: pointer;
}
@media screen and (max-width:1280px){
  .detailTable th{
    
    padding-right: 0.5rem;
  }
}
@media screen and (max-width: 1024px) {
  .container {
    width: 100vw;
    height: 100%;
    padding: 0;
    overflow-x: hidden;
  }
  .subscription {
    width: 100%;
  }
  .welcomeSection {
    margin: 0 0.5rem;
  }
  .planHeader {
    padding: 1rem;
  }
  .subscriptionPlan {
    margin: 0 0.5rem;
    border-radius: 35px;
  }
  .subscriptionDetail{
    overflow-x: auto;
  }
  .detailTable td{
    padding-right: 1rem;
  }

}

@media screen and (max-width: 768px) {
  .container{
    padding-bottom: 0.5rem;
  }
  .welcomeSection {
    flex-direction: column;
    border-radius: 35px;
  }
  .welcomeSectionLeft {
    width: 100%;
  }
  .welcomeSectionRight {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .welcomeImageContainer {
    padding-right: 0;
  }
  .welcomeSectionLeft p {
    font-size: 1.5rem;
    line-height: 1.5;
  }
  .welcomeSectionLeft span {
    font-size: 1.125rem;
    line-height: 1.5;
  }
  .planHeader p {
    font-size: 1.1875rem;
  }
  
  .subscriptionPlanTop p {
    font-size: 1rem;
  }
  .subscriptionPlanBottom h4{
    font-size: 1.1875rem;
  }
  .subscriptionPlanBottom p{
    font-size: 0.9375rem;
  }
  .detailTable th{
    font-size: 1.125rem;
   
  }
  .detailTable td{
    font-size: 1.125rem;
  }
  .downloadCard p{
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 576px) {
  .welcomeSection {
    border-radius: 25px;
  }
  .welcomeSectionLeft p {
    font-size: 1.375rem;
  }
  .welcomeSectionLeft span {
    font-size: 1rem;
  }
  .planHeader p {
    font-size: 1rem; 
  }
  .subscriptionPlanTop{
    justify-content: flex-start;
  }
  .subscriptionPlanTop p {
    font-size: 0.875rem;
  }
  .subscriptionPlanBottom h4{
    font-size: 1rem;
  }
  .subscriptionPlanBottom p{
    font-size: 0.875rem;
  }
  
  .detailTable th{
    font-size: 1rem;
  }
  .detailTable td{
    font-size: 1rem;
  }
  .downloadCard p{
    font-size: 1rem;
  }
}