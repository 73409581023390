.Container {
  justify-content: center;
  align-items: center;
  display: flex;
}

.topBar {
  display: flex;
  justify-content: space-between;
  padding: 1.35rem 2rem 1.35rem 2rem;
  width: 91%;
  position: relative;
  z-index: 1;
}

.topBarMenu {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  align-items: center;
}
.topBarMenu:last-child {
  padding-right: 3rem;
}
.topBarMenu p {
  cursor: pointer;
}

.topImg {
  align-items: center;
  width: 13rem;
  height: 5rem;
}
.topImg img {
  width: 90%;
  height: 100%;
}
.userIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 50px;
}
.userIcon span {
  background-color: rgba(89, 91, 219, 1);
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  font-size: 1.2rem;
}
.userIcon img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.menuIcon {
  display: none;
}
.menuIcon svg {
  color: #595bdb;
}
.notificationIconImage {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.iconImage {
  width: 2.1rem;
  height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.iconImage img,
.notificationIconImage img {
  width: 100%;
  height: 100%;
}
.notificationPopUp {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  top: 100px;
  right: 120px;
  width: 25vw;
  background-color: white;
  box-shadow: 0px 4px 16px 0px #d4d4d4;
  border-radius: 10px;
  z-index: 100;
  padding: 1rem 0;
  padding-bottom: 2rem;
}
.notificationPopUp::before {
  content: "";
  position: absolute;
  top: -15px;
  right: 105px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 18px solid rgb(255, 255, 255); /* Color of the popup */
}
.notificationHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #89889069;
  padding-bottom: 0.5rem;
  margin: 0 2rem;
}
.notificationHeader p {
  font-family: Urbane;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  color: #3e3d42;
}
.notificationContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 52vh;
  overflow-y: auto;
}
.notificationContent::-webkit-scrollbar {
  display: none;
}
.notificationItemContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 2rem;
}


.notificationItemContainer:last-child .notificationItem {
  border-bottom: none;
}

.notificationItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.5rem;
  border-bottom: 1px solid #89889069;
}

.notificationItemLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.notificationLeftImage {
  width: 2rem;
}
.notificationLeftImage img {
  width: 100%;
  height: 100%;
}
.notificationItemRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.notificationRightHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.notificationRightHeader p {
  font-family: Urbane;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  text-align: left;
  color: #5f5f5f;
  margin: 0;
  padding: 0.5rem 0;
}
.notificationRightContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.notificationRightContent p {
  font-family: Urbane;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  color: #3e3d42;
  margin: 0;
}
.notificationRightContent span {
  font-family: Urbane;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #3e3d42;
}
.notificationRightBottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.notificationRightBottom p {
  font-family: Urbane;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  text-align: left;
  color: #a2a2a2;
  margin: 0;
  padding: 0.5rem 0;
}
.userPoint {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  z-index: 999;
}
.userPoint img {
  width: 20%;
  height: 100%;
}
.userPoint span {
  font-family: Urbane;
  font-size: 18px;
  font-weight: 600;
  line-height: 30.26px;
  text-align: right;
  color: #595bdb;
}

.sidebar {
  width: 16rem;
  display: none;
  flex-direction: column;
  border-radius: 0 0;
  height: calc(100vh- 17vh);
  background: #ededfb;
  border-radius: 30px 0 0 30px;
  justify-content: space-between;
  margin-left: 4rem;
  position: fixed;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.MenuContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 0;
  align-items: flex-end;
}

.menuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  margin: 6px 0;
  width: 12rem;
  border-radius: 2rem 0 0 2rem;
  cursor: pointer;
}

.selectedMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  margin: 6px 0;
  width: 12rem;
  border-radius: 2rem 0 0 2rem;
  background-color: #fff;
  cursor: pointer;
}
.menuItemImage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 1.2rem;
  padding-left: 1rem;
}

.menuItemImage img {
  width: 100%;
  height: 100%;
}
.menuItemText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.menuItemText p {
  margin: 0;
  font-family: Urbane;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #595bdb;
  padding-left: 1rem;
}
.bottomContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
}
.separateLine {
  border-top: 2px solid #dbdbdb;
  width: 100%;
  margin-bottom: 8px;
}
.bottomText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
  padding-right: 1.5rem;
}
.bottomText p {
  font-family: Urbane;
  font-size: 13px;
  font-weight: 500;
  line-height: 23.04px;
  text-align: left;
  color: #595bdb;
  margin: 0;
  cursor: pointer;
}
.selectedBottomText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0.75rem 0;
  padding: 10px;
  margin: 6px 0;
  width: 12rem;
  border-radius: 2rem 0 0 2rem;
  background-color: #fff;
  cursor: pointer;
}
.selectedBottomText p {
  font-family: Urbane;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.04px;
  text-align: left;
  color: #595bdb;
  margin: 0;
  cursor: pointer;
}
.supportText {
  padding-left: 1.7rem;
}
.logoutText {
  padding-left: 1.4rem;
}
.popupOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  width: 100%;
  height: 100vh;
  background: #d9d9d980;
}
.Popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 31px;
  padding: 4rem 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99;
  box-shadow: 1px 1px 2.3px 0px #cfcfcf;
}
.popupText {
  padding: 2rem 0;
}
.popupText p {
  font-family: Urbane;
  font-size: 23.04px;
  font-weight: 600;
  line-height: 27.65px;
  text-align: left;
  color: #505050;
  margin: 0;
}
.PopupButton {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  padding: 2rem 0;
}
.noButton {
  width: 16.5rem;
  height: 3.9375rem;
  border-radius: 2.5rem;
  font-family: Urbane;
  font-size: 23.04px;
  font-weight: 600;
  line-height: 27.65px;
  text-align: center;
  color: #fff;
  background: #595bdb;
  border: none;
  cursor: pointer;
}
.yesButton {
  width: 16.5rem;
  height: 3.9375rem;
  border-radius: 2.5rem;
  background: #d9d9d9;
  font-family: Urbane;
  font-size: 23.04px;
  font-weight: 600;
  line-height: 27.65px;
  text-align: center;
  color: #898890;
  border: none;
  cursor: pointer;
}
.pointPopup{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  top: 100px;
  right: 20%;
  /* width: 25vw; */
  background-color: white;
  box-shadow: 0px 4px 16px 0px #d4d4d4;
  border-radius: 10px;
  z-index: 100;
  padding: 2rem 3rem;
  padding-bottom: 2rem;
  gap: 1rem;
}
.pointPopupClose{
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.pointPopupClose svg{
  font-size: 2rem;
  color: #B4B4B4;
  cursor: pointer;
}
.pointPopupImageContainer{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.pointPopupImage{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 12vw;
}
.pointPopupImage img{
  width: 100%;
  height: 100%;
}
.pointPopupContent{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
}
.pointPopupTopContent{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5rem;
}
.pointPopupTopContent img{
  width: 20%;
  height: 100%;
}
.pointPopupTopContent p{
  font-family: Urbane;
  font-size: 24px;
  font-weight: 600;
  line-height: 30.26px;
  text-align: right;
  color: #595bdb;
}
.pointPopupBottomContent{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;
}
.pointPopupBottomContent span{
  font-family: Urbane;
  font-size: 14px;
  font-weight: 300;
  line-height: 16.9px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #3e3d42;
}
.pointPopupBottomContent p{
  font-family: Urbane;
  font-size: 18px;
  font-weight: 600;
  line-height: 30.26px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #595bdb;  
}
.footerText{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.footerText p{
  font-family: Urbane;
  font-size: 12px;
  font-weight: 300;
  line-height: 14.4px;
  text-align: center;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: auto;
  color: #898890;
}
@media screen and (max-width: 1024px) {
  .topBar {
    width: 100%;
    padding: 1.35rem 2rem;
  }
  .topBarMenu {
    gap: 2rem;
  }
  .topBarMenu:last-child {
    padding-right: 1.5rem;
  }
  .menuIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 2rem;
  }
  .menuIcon svg {
    height: 100%;
    width: 100%;
  }
  .sidebar {
    display: flex;
    width: 20rem;
    height: 28rem;
    border-radius: 15px;
    position: absolute;
    top: 107px;
    right: 37px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    z-index: 9999;
  }
  .menuItem {
    width: 100%;
  }
  .selectedMenu {
    border-radius: 0;
    width: 100%;
  }
  .MenuContainer {
    align-items: flex-start;
    overflow: hidden;
  }
  .bottomContent {
    padding: 1rem 0;
  }
  .selectedBottomText {
    padding: 10px 0;
    border-radius: 0;
    width: 100%;
  }
  .selectedBottomText p {
    padding-right: 1.5rem;
    font-size: 13px;
  }
  .popupOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
  }

  .Popup {
    padding: 1.5rem;
    position: fixed;
    width: 50vw;
  }
  .PopupButton {
    gap: 2rem;
  }
  .popupText p {
    font-size: 1.3rem;
  }
  .noButton,
  .yesButton {
    font-size: 1.3rem;
    width: 10rem;
    height: 3rem;
  }
  .notificationPopUp {
    width: 300px;
    height: 350px;
    position: fixed;
    right: 100px;
  }
  .notificationPopUp::before {
    right: calc(50% - 41px);
  }
}
@media screen and (max-width: 768px) {
  .topBarMenu:last-child {
    padding-right: 1rem;
  }
  .topBar {
    padding: 1.35rem 2rem;
  }
  .topImg {
    display: flex;
    align-items: center;
    height: auto;
  }
  .topImg img {
    width: 100%;
    height: 100%;
  }
  .iconImage {
    width: 1.65rem;
    height: 1.5rem;
  }
  .userIcon {
    width: 43px;
  }
  .menuIcon {
    height: 30px;
  }

  .userPoint span {
    font-size: 15px;
    line-height: 18px;
  }

  .sidebar {
    width: 20rem;
    top: 107px;
    right: 26px;
  }
  .notificationPopUp::before {
    right: calc(50% - 60px);
  }

  .notificationHeader p {
    font-size: 14px;
    line-height: 1.5;
  }
  .notificationRightHeader p,
  .notificationRightBottom {
    font-size: 10px;
    line-height: 1.5;
  }

  .notificationRightContent p,
  .notificationRightContent span {
    font-size: 12px;
    line-height: 1.5;
  }
  .Popup {
    padding: 1rem;
    width: 70vw;
  }
  .PopupButton {
    gap: 1rem;
    padding: 1rem 0;
    width: 70%;
  }
  .noButton,
  .yesButton {
    font-size: 1rem;
    width: 50%;
    height: 2.5rem;
  }
  .popupText p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .sidebar {
    width: 13rem;
    height: 20rem;
    top: 107px;
    right: 20px;
  }
  .bottomContent {
    padding: 0.5rem 0;
  }

  .MenuContainer {
    padding: 10px 0 5px 0;
    align-items: center;
  }
  .selectedMenu {
    padding: 2px 0;
    margin: 6px 0;
    width: 100%;
    border-radius: 0;
  }
  .menuItemImage {
    width: 1.5rem;
    padding-left: 1rem;
  }
  .menuItem {
    padding: 2px 0;
    margin: 3px 0;
    width: 100%;
  }
  .menuItemText p {
    font-size: 11px;
  }
  .topBar {
    padding: 1.35rem 1rem;
  }
  .topImg {
    width: 30%;
    height: auto;
  }
  .topBarMenu {
    width: 60%;
    gap: 0.3rem;
  }
  .topBarMenu:last-child {
    padding-right: 0;
  }
  .userPoint {
    justify-content: space-evenly;
    gap: 0;
  }
  .userPoint span {
    font-size: 0.675rem;
  }
  .iconImage {
    width: 1.5rem;
    height: 1.5rem;
    position: relative;
  }
  .userIcon {
    width: 35px;
  }
  .menuIcon {
    height: 24px;
    width: 24px;
  }
  .notificationPopUp {
    width: 250px;
    height: 300px;
    right: 45px;
  }
  .notificationPopUp::before {
    right: calc(50% - 40px);
  }
  .notificationHeader p {
    font-size: 14px;
    line-height: 1.5;
  }
  .notificationRightHeader p,
  .notificationRightBottom {
    font-size: 10px;
    line-height: 1.5;
  }

  .notificationRightContent p,
  .notificationRightContent span {
    font-size: 12px;
    line-height: 1.5;
  }
  .moduleCardName p {
    font-size: 0.875rem;
  }
  .Popup {
    width: 80vw;
  }
  .noButton,
  .yesButton {
    font-size: 0.875rem;
  }
  .PopupButton {
    padding: 0.5rem 0;
  }
  .popupText p {
    font-size: 0.8rem;
  }
}
