.container {
  height: calc(100vh - 17vh);
  overflow-y: auto;
  padding: 0 2rem;
}

.container::-webkit-scrollbar {
  width: 10px;
}

.container::-webkit-scrollbar-track {
  background: #d9d9d9;
  border-radius: 10px;
}

.container::-webkit-scrollbar-thumb {
  background: #8c8c8c;
  border-radius: 10px;
}

.container::-webkit-scrollbar-thumb:hover {
  background: #8c8c8c;
}

.profileSection {
  display: flex;
  flex-direction: column;
  width: 93%;
}

.header {
  padding: 2rem;
  background: #595bdb;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header p {
  font-family: Urbane;
  font-size: 24px;
  font-weight: 600;
  line-height: 39.82px;
  text-align: left;
  margin: 0;
  color: #fff;
}
.header span {
  font-family: Urbane;
  font-size: 20px;
  font-weight: 600;
  line-height: 29.05px;
  text-align: right;
  color: #ffffff;
  margin: 0;
}
.body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem;
  margin-top: 1rem;
  border-radius: 42px;
  border: 1px solid #3e3d421f;
  box-shadow: 7px 7px 8.2px -8px #595bdb66;
  gap: 1rem;
  margin-bottom: 1rem;
}

.bodyLeft {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bodyTopLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bodyTopRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 25%;
  align-items: flex-end;
}

.avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 10rem;
}

.avatar img {
  width: 100%;
  height: 100%;
}

.avatarText {
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.avatarText p {
  margin: 0;
  font-family: Urbane Extra;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.6px;
  text-align: center;
  color: #5f5f5f;
  text-decoration: underline;
  cursor: pointer;
}

.bodyRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 72%;
  padding: 1rem;
}

.rightTop {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
}

.dataContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.dataCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 48%;
}

.dataCard span {
  font-family: Urbane Extra;
  font-size: 12px;
  font-weight: 300;
  line-height: 16.8px;
  text-align: left;
  color: #5f5f5f;
}

.dataCard p {
  padding: 4px 0;
  margin: 0;
  font-family: Urbane;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  color: #3f3f3f;
}

.dataCard input {
  padding: 4px 0;
  font-family: Urbane;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.6px;
  color: #3f3f3f;
  border: none;
  border-bottom: 1px solid #1e1e1e84;
  outline: none;
  width: 100%;
  background: none;
}

.editSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-bottom: 1px solid #5f5f5f;
}

.editText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.editText svg {
  font-size: 1rem;
  color: #5f5f5fcc;
}

.editText p {
  font-family: Urbane Extra;
  font-size: 14px;
  font-weight: 300;
  line-height: 16.8px;
  text-align: left;
  color: #5f5f5f;
  margin: 0;
  text-decoration: underline;
}

.editText button {
  border: none;
  background: #595bdb;
  border-radius: 9px;
  padding: 0.5rem 2rem;
  font-family: Urbane;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.rightBottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 0;
}

.rightBottom p {
  margin: 0;
  font-family: Urbane Extra;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  text-decoration: underline;
  color: #5f5f5f;
  cursor: pointer;
}

.popupOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  width: 100%;
  height: 100vh;
  background: #d9d9d980;
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 31px;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 99;
  box-shadow: 1px 1px 2.3px 0px #cfcfcf;
  width: 32%;
}

.cardContent {
  display: flex;
  flex-direction: column;
}

.cardHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.cardHeader h4 {
  font-family: Urbane;
  font-size: 32px;
  font-weight: 500;
  line-height: 45px;
  text-align: left;
  color: #000000;
  margin: 0;
  padding: 0.75rem 0;
}

.cardHeader span {
  font-family: Urbane;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  color: #5f5f5f;
  margin: 0;
}

.cardBody {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem 0;
}

.emailInput {
  background: rgba(89, 91, 219, 0.27);
  padding: 0 2rem;
  border-radius: 25px;
  display: flex;
  margin: 0.75rem 0;
  height: 4.25rem;
  align-items: center;
}

.emailInput input {
  background: transparent;
  outline: none;
  border: none;
  font-family: Urbane;
  font-size: 18px;
  font-weight: 300;
  line-height: 23.04px;
  text-align: left;
  width: 100%;
}

.cardButton {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: flex-start;
}

.cardButton button {
  margin-top: 0.75rem;
  border: none;
  border-radius: 25px;
  background: rgba(89, 91, 219, 1);
  font-size: 23px;
  font-weight: 600;
  line-height: 30px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  height: 4.25rem;
  width: 100%;
  font-family: Urbane Demi;
}

.cardBottomText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0;
}

.cardBottomText p {
  font-family: Urbane Extra;
  font-size: 16px;
  font-weight: 300;
  line-height: 23.04px;
  text-align: left;
  margin: 0;
  color: #3f3f3f;
}

.cardBottomText span {
  font-family: Urbane Demi;
  font-size: 16px;
  font-weight: 600;
  line-height: 23.04px;
  text-align: left;
  color: #595bdb;
}

.otpSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
}

.otpInput {
  background: rgba(89, 91, 219, 0.27);
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 5.25rem;
  height: 4.625rem;
}

.otpInput input {
  background: transparent;
  outline: none;
  border: none;
  font-family: Urbane;
  font-size: 19.2px;
  font-weight: 300;
  line-height: 23.04px;
  text-align: center;
  width: 100%;
}

.passwordCondition {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.specialCharacter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: Urbane;
  font-size: 24px;
  font-weight: 300;
  line-height: 18.8px;
  text-align: left;
  color: #595bdb;
  padding: 0 4px;
}

.passwordConditionText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.passwordConditionText p {
  font-family: Urbane;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  margin: 0;
  color: #5f5f5f;
}

.passwordConditionText span {
  font-family: Urbane;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #00dfc7;
}

.successContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
}

.successImage {
  width: 14rem;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: center;
}

.successImage img {
  width: 100%;
  height: 100%;
}

.successText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
}

.successText p {
  font-family: Urbane;
  font-size: 23.04px;
  font-weight: 600;
  line-height: 27.65px;
  text-align: left;
  color: #3f3f3f;
  margin: 0;
}

.successButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}

.successButton button {
  color: #fff;
  background-color: #595bdb;
  border-radius: 14px;
  width: 13rem;
  height: 3rem;
  border: none;
  font-family: Urbane;
  font-size: 23.04px;
  font-weight: 600;
  line-height: 27.65px;
  text-align: center;
  cursor: pointer;
}
.errorInput {
  border: 1px solid red;
}
.avatarPopup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 31px;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 99;
  box-shadow: 1px 1px 2.3px 0px #cfcfcf;
}
.avatarPopupBody {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.avatarGrid {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(5, 1fr);
  padding: 1rem 0;
}
.avatarImage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 104px;
  height: 104px;
  border-radius: 14px;
}
.avatarImage img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}
.avatarButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
}
.avatarButton button {
  width: 163px;
  height: 45px;
  background: #595bdb;
  border: none;
  border-radius: 9px;
  color: #fff;
  font-family: Urbane Demi;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}
.selectedImage {
  border: 3px solid red;
  opacity: 0.75;
}
.clearButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.clearButton svg {
  font-size: 1.5rem;
  color: #5f5f5f;
  cursor: pointer;
}
.error {
  color: red;
}
.achievementHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
}

.achievementHeader p {
  margin: 0;
  font-family: Urbane;
  font-size:26px;
  font-weight: 600;
  line-height: 36.01px;
  text-align: left;
  color: #595bdb;
}

.achievementSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 1rem;
  border: 2px solid #8988901f;
  box-shadow: 0px -4px 22.8px 0px #d9d9d9;
  border-radius: 20px;
  padding: 1rem;
  background-color: #fcfcfc;
}

.achievementSectionHeder {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  padding-top: 1rem;
  padding-left: 1rem;
}

.achievementSectionHeder p {
  font-family: Urbane;
  font-size: 26px;
  font-weight: 600;
  line-height: 36.01px;
  text-align: left;
  margin: 0;
  color: #595bdb;
}

.achievementSectionHeder span {
  font-family: Urbane Extra;
  font-size: 14px;
  font-weight: 300;
  line-height: 19.2px;
  text-align: left;
  color: #5f5f5f;
}

.achievementGrid {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem;
  gap: 0.75rem;
}

.achievementCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: .5rem 0;
  gap: 1.25rem;
  padding: 1rem;
}

.achievementCardImage {
  width: 7.5rem;
}

.achievementCardImage img {
  width: 100%;
  height: 100%;
}

.achievementCardText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.achievementCardText p {
  font-family: Urbane;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  margin: 0;
  color: #898890;
}

.activeText p {
  font-family: Urbane;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  margin: 0;
  color: #595bdb;
}

.dailyDrillSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 1rem;
  border: 2px solid #8988901f;
  box-shadow: 0px -4px 22.8px 0px #d9d9d9;
  border-radius: 20px;
  padding: 1rem;
  background-color: #fff8e5;
}

.dailyDrillSectionHeder {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  padding-top: 1rem;
  padding-left: 1rem;
}

.dailyDrillSectionHeder p {
  font-family: Urbane;
  font-size: 24px;
  font-weight: 600;
  line-height: 36.01px;
  text-align: left;
  margin: 0;
  color: #ffbe00;
}

.dailyDrillSectionHeder span {
  font-family: Urbane Extra;
  font-size: 14px;
  font-weight: 300;
  line-height: 19.2px;
  text-align: left;
  color: #5f5f5f;
}

.activeDailyDrillText p {
  font-family: Urbane;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  margin: 0;
  color: #ffbe00;
}

.weeklyWorkoutSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 1rem;
  border: 2px solid #8988901f;
  box-shadow: 0px -4px 22.8px 0px #d9d9d9;
  border-radius: 20px;
  padding: 1rem;
  background-color: #e3fffc;
}

.weeklyWorkoutSectionHeder {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  padding-top: 1rem;
  padding-left: 1rem;
}

.weeklyWorkoutSectionHeder p {
  font-family: Urbane;
  font-size: 24px;
  font-weight: 600;
  line-height: 36.01px;
  text-align: left;
  margin: 0;
  color: #00dfc7;
}

.weeklyWorkoutSectionHeder span {
  font-family: Urbane Extra;
  font-size: 14px;
  font-weight: 300;
  line-height: 19.2px;
  text-align: left;
  color: #5f5f5f;
}

.activeWeeklyWorkoutText p {
  font-family: Urbane;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  margin: 0;
  color: #00dfc7;
}

.achievementPopup {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #f6f7fc;
  border-radius: 20px;
  box-shadow: 0px 1px 21.5px -2px #d4d4ff;
  padding: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20vw;
  z-index: 999;
}

.achievementPopupHeder {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.achievementPopupHeder p {
  font-family: Urbane;
  font-size: 24px;
  font-weight: 600;
  line-height: 36.01px;
  text-align: center;
  margin: 0;
  color: #595bdb;
}

.achievementPopupHeder span {
  font-family: Urbane Extra;
  font-size: 14px;
  font-weight: 300;
  line-height: 19.2px;
  text-align: center;
  color: #5f5f5f;
}

.achievementPopupImageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 2rem 0;
}

.achievementPopupImage {
  width: 10.5rem;
  height: 9rem;
  position: relative;
}

.achievementPopupImage img {
  width: 100%;
  height: 100%;
}

.achievementPoint {
  position: absolute;
  bottom: 10px;
  right: 1.5rem;
}

.achievementPoint p {
  font-family: Urbane;
  font-size: 21px;
  font-weight: 600;
  line-height: 30.18px;
  text-align: left;
  color: #595bdb;
}

.achievementPopupText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}

.achievementPopupText p {
  font-family: Urbane;
  font-size: 16px;
  font-weight: 600;
  line-height: 24.14px;
  text-align: center;
  margin: 0;
  color: #5f5f5f;
}

.achievementPopupButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}

.achievementPopupButton button {
  background-color: #595bdb;
  font-family: Urbane;
  font-size: 18px;
  font-weight: 600;
  line-height: 24.14px;
  text-align: center;
  color: #fff;
  outline: none;
  border: none;
  border-radius: 15px;
  height: 3rem;
  padding: 0 1rem;
  width: 70%;
}
@media screen and (max-width: 1024px) {
  .container {
    padding: 0;
    width: 100vw;
    height: 100%;
  }
  .body,
  .header {
    margin: 1rem;
  }

  .profileSection {
    width: 100%;
  }
  .bodyRight {
    width: 100%;
    padding: 0;
  }
  .achievementHeader {
    padding: 1rem 1rem 1rem 0;
  }
  .achievementSection,
  .dailyDrillSection,
  .weeklyWorkoutSection {
    margin: 0;
  }
  .popupOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
  }

  .popup {
    padding: 1.5rem;
    position: fixed;
    width: 50vw;
  }
}
@media screen and (max-width: 768px) {
  .header p,
  .header span,
  .achievementHeader p,
  .achievementSectionHeder p,
  .dailyDrillSectionHeder p,
  .weeklyWorkoutSectionHeder p {
    font-size: 1.5rem;
  }
  .dataCard span,
  .editText p {
    font-size: 0.813;
  }
  .editText button {
    font-size: 0.8rem;
  }
  .dataCard p,
  .dataCard input {
    font-size: 1rem;
  }

  .rightBottom p {
    font-size: 0.8125rem;
  }
  .achievementSectionHeder span,
  .dailyDrillSectionHeder span,
  .weeklyWorkoutSectionHeder span,
  .achievementCardText p {
    font-size: 0.875rem;
  }
  .achievementGrid {
    justify-content: flex-start;
  }
  .cardHeader h4 {
    font-size: 1.75rem;
  }
  .cardHeader span {
    font-size: 0.875rem;
  }
  .emailInput {
    height: 3.25rem;
    margin: 0.5rem 0;
  }
  .popup {
    width: 60vw;
  }
  .emailInput input,
  .cardBottomText p,
  .cardBottomText span {
    font-size: 0.875rem;
  }
  .cardButton button {
    height: 3.25rem;
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 480px) {
  .header p,
  .header span,
  .achievementHeader p,
  .achievementSectionHeder p,
  .dailyDrillSectionHeder p,
  .weeklyWorkoutSectionHeder p {
    font-size: 1.125rem;
  }
  .dataCard span,
  .editText p {
    font-size: 0.675rem;
  }
  .editText button {
    font-size: 0.75rem;
  }
  .clearButton svg {
    font-size: 1rem;
  }
  .dataCard p,
  .dataCard input {
    font-size: 0.875;
  }

  .bodyLeft {
    align-items: center;
  }
  .rightBottom p {
    font-size: 0.6875rem;
  }
  .achievementSectionHeder span,
  .dailyDrillSectionHeder span,
  .weeklyWorkoutSectionHeder span,
  .achievementCardText p {
    font-size: 0.75rem;
  }

  .bodyTopLeft {
    width: 50%;
    height: 100%;
    align-items: center;
  }
  .bodyTopRight {
    width: 50%;
    height: 100%;
    align-items: center;
  }
  .avatar:last-of-type {
    margin-bottom: 2rem;
  }
  .avatar:first-of-type img {
    width: 70%;
  }

  .avatar:last-of-type img {
    width: 70%;
  }
  .cardHeader h4 {
    font-size: 1.625rem;
  }
  .cardHeader span {
    font-size: 0.75rem;
  }
  .popup {
    width: 70vw;
  }
  .emailInput input {
    font-size: 0.75rem;
  }
  .cardBottomText p,
  .cardBottomText span {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 360px) {
  .bodyLeft {
    flex-direction: column-reverse;
  }
}
