.Container {
    width: 100%;
    height: 100vh;
    padding: 0 1rem;
    overflow-y: scroll;
    /* position: relative; */
  }
  .Container::-webkit-scrollbar {
    display: none;
  }
  .Header {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    align-items: center;
    color: #131010;
    height: 4rem;
    border-radius: 10px;
    margin: 1rem;
    box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
      0.3em 0.3em 1em #6d6d6d4d;
    transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .HeaderText {
    width: 80%;
    display: flex;
    align-items: center;
    padding: 1rem;
    justify-content: flex-start;
  }
  .HeaderText p {
    font-weight: 500;
    margin: 0;
    font-size: 1.35rem;
    text-transform: uppercase;
  }
  .HeadButton {
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;
  }
  .HeadButton button {
    background: #efe4dd;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: #7e193e;
  width: 115px;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #be5656;
  }
  .assesment {
    display: flex;
    flex-direction: row;
    padding:0 .5rem;
    height: 90vh;
    justify-content: space-between;
    overflow-y: scroll;
    z-index: 9;
  }
  /* .assesment::-webkit-scrollbar{
    display: none;
  } */
  .assesmentQuestion{
      margin: 1rem 0;
      display: flex;
    flex-direction: row;
    align-items: normal;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    pointer-events: none; /* Disable mouse events */
  }
  .assesmentQuestion p{
    margin: 0;
    padding: 0;
  }
  .assesmentQuestion input {
    user-select: text;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    pointer-events: auto; /* Enable mouse events */
  }
  .assesmentAnswer{
    display: flex;
    /* width: 100%; */
    flex-direction: column;
    /* align-items: center; */
    
    border-radius: 6px;
    /* background-color: #fff; */
    padding: 3px 5px;
  }
  .assesmentAnswer textarea{
      /* width: 100%; */
      height: 75px;
  border-radius: 5px;
  outline: none;
  padding: 5px;
  resize: none;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .AssessmentHead{
    width: 70%;
    display: flex;
    flex-direction: column;
    border:none ;
    padding: 8px 6px;
    height: fit-content;
    margin: 2px 0;
    background-color: #fff;
      box-shadow: 0 3px 6px #0000003d;
    border-radius: 6px;
  }
  .Count{
    width: fit-content;
    padding: 8px 30px;
    background: #dde1ef;
    font-size: 1rem;
    outline: none;
    border-radius: 7px;
    color: #56197e;
    font-weight: 600;
    border: 2px solid #6356be;
    margin: 0 0.25rem;
  }
  .value{
    font-size: 1rem;
  }
  .FillQuestion{
    width: 100%;
   }
   .FillQuestion input{
     width: 30%;
     height: 1.25rem;
     border: none;
     border-bottom: 1px dotted;
     outline: none;
   }
   .AddButton{
    width: 100%;
    text-align: right;
    padding: 4px 0;
  }
  .AddButton button:nth-child(3){
    margin-right: 0;
   
  }
  
  .ValidateButton{
    background-color: #38b020;
    font-size: 1rem;
    outline: none;
    border-radius: 7px;
    padding: 0.75rem;
    color: #ffffff;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    border:none;
    margin: 0 0.25rem;
  }
  .SkipButton{
    background: #dde1ef;
    font-size: 1rem;
    outline: none;
    border-radius: 7px;
    padding: 0.75rem;
    color: #56197e;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    border: 2px solid #6356be;
    margin: 0 0.25rem;
  }
  .FinishButton{
    background-color: #0f6bd4;
    font-size: 1rem;
    outline: none;
    border-radius: 7px;
    padding: 0.75rem;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    margin: 0 0.25rem;
    border: none;
  }
  .SaveButton{
    background-color: #d4820f;
    font-size: 1rem;
    outline: none;
    border-radius: 7px;
    padding: 0.75rem;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    margin: 0 0.25rem;
    border: none;
  }
  .AssesssmentSide{
    width: 22%;
    padding:  1rem;
    display: flex;
    flex-direction: column;
    }
    .Sidecard{
      /* width: 100%; */
      padding: 1rem;
      color: #1a1e5d;
      background-color: #fff;
      box-shadow: 0 3px 8px #0000003d;
    }
    .Grid{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      padding:5px 1rem;
      row-gap: 1rem;
      column-gap:1rem;
      margin-top: 2rem;
    }
    .span{
      border: 1px solid;
      padding:5px 2px ;
      text-align: center;
      border-radius: 5px;
      cursor: pointer;
      background-color: #fff;
    }
    .CardHead{
      font-size: 1.25rem;
    }
    .DropContainer {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
    .DropQuestions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 65%;
      padding-top: 1.5rem;
    }
    .DropAnswers {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 35%;
    }
    .DropAnswers h5 {
      margin: 0;
      text-decoration: underline;
      font-size: 1rem;
    }
    .Card {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 14rem;
      margin: 8px 0;
      border-radius: 6px;
      border: 1px solid #dbdbdb;
      background-color: #e1dfdf;
      padding: 4px 0;
    }
    .Card p {
      margin: 0;
      padding: 4px;
    }
    
    .DraggablePlaceholder {
      border: 2px dashed #ccc;
      padding: 4px;
      text-align: center;
      color: #999;
      cursor: pointer;
      margin: 4px 0;
    }
    .DraggableItem {
      border: 2px solid #ccc;
      padding: 4px;
      cursor: pointer;
      margin: 4px 0;
    }
    
    .DropQuestions table {
      width: 100%;
      border-spacing: 0;
      /* border: 2px solid #a8b1b9; */
      border-collapse: separate;
      border-spacing: 0 8px;
    }
    .DropQuestions tbody tr {
      text-align: center;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .DropQuestions td {
      padding: 0.1rem 0.2rem;
    
      font-size: 1rem;
      text-align: center;
    }
    
    .DropQuestions td:nth-child(1) {
      text-align: left;
    }
    .DropQuestions td:nth-child(2) {
      width: 13rem;
    }    
    .validateLoaderDiv {
      display: flex;
      flex-direction: column;
      position: absolute;
      z-index: 99999;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 2rem;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #d0cece;
      border-radius: 5px;
    }
    .validateLoaderContent {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .validateLoaderText p {
      font-weight: 500;
      font-size: 1.1rem;
      margin: 0;
      padding: 6px;
    }
    .validateLoaderContent p {
      font-weight: 500;
      font-size: 1.1rem;
      margin: 0;
      padding: 6px 1rem;
    }
    .Requiredmessage{
      color: #0f6bd4;
    }
    .Validtext{
      color: #eb4034;
    }
    .DictationTabel {
      width: 80%;
    }
    .DictationTabel input{
      width: 90%;
      height: 2rem;
      padding: 4px;
      outline: none;
    border-radius: 6px;
    border: none;
    background-color: transparent;
    font-size: 1.15rem;
    }
    .DictationTabel td{
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .DictationTabel tbody tr:hover {
      background-color: #ececec;
    }
    .speakerIcon{
      font-size: 1.5rem;
    }
    .crosswordcontainer{
      display: flex;
      flex-direction: row;
      width: 100%;
      height: fit-content;
    }
    .crosswordpuzzle {
      display: grid;
      grid-template-columns: repeat(10, 40px);
      /* grid-template-rows: repeat(10, 50px); */
      gap: 0;
     
      
    }
    
    .PuzzleQuestion{
      padding: 0 1rem;
      width: 100%;
    }
    
    .cell {
      width: 80%;
      height: 100%;
      text-transform: uppercase;
      text-align: center;
      font-size: 20px;
      border: 1px solid #000;
      padding:6px 4px; /* Remove padding */
      margin: 0; /* Remove margin */
      outline: none; /* Remove outline */
    }
    .clueNumber {
      position: absolute;
      top: 0;
      right: 0;
      font-size: smaller;
    }
    .QuestionAcross{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .QuestionAcrossInputContainer{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .QuestionAcrossInputContainer input{
      font-family: "Poppins";
      width: 100%;
      font-size: 1.15rem;
      background-color: #fff;
      padding: 0.5rem 0.25rem;
      border-radius: 5px;
      margin: 0.5rem 0;
      border: 1px solid #bfaaaac1;
      outline: none;
      text-align: start;
    }
    
    .QuestionDown{
      display: flex;
      flex-direction: column;
    }
    .QuestionDownInputContainer{
      display: flex;
      flex-direction: column;
    }
    .QuestionDownInputContainer input{
      font-family: "Poppins";
      width: 100%;
      font-size: 1.15rem;
      background-color: #fff;
      padding: 0.5rem 0.25rem;
      border-radius: 5px;
      margin: 0.5rem 0;
      border: 1px solid #bfaaaac1;
      outline: none;
      text-align: start;
    }
    .cellContainer {
      position: relative;
    }
    
    .clueNumber {
      position: absolute;
      top: 2px;  /* Adjust this value to fine-tune the vertical position */
      right: 2px;  /* Adjust this value to fine-tune the horizontal position */
      font-size: 10px;  /* Adjust this value for the desired font size */
      color: red;  /* Adjust this value for the desired color */
    }
    .disabledInput {
      background-color: rgb(217, 215, 215);
      
    }
    .BottomIcons {
      display: flex;
      flex-direction: row;
      justify-content: right;
      font-size: 1.25rem;
      padding: 3px 9px;
      color: #1a1e5d;
    }
    .BottomIcons p {
      margin: 0;
    }
    .DraggableItem {
      border: 2px solid #ccc;
      padding: 4px;
      cursor: pointer;
      margin: 4px 0;
    }
    .assessmentSuccessAnimation {
      width: 35rem;
      position: absolute;
      top: 50%;
      left: 55%;
      transform: translate(-50%, -50%);
      z-index: 9;
    }
    .assessmentFailAnimation{
      width: 10rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .AssessmentSummary{
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 3px 8px #0000003d;
      margin: 1rem 0;
      align-items: center;
      justify-content: center;
      padding: 4px 0;
      flex-direction: column;
      cursor: pointer;
    }
    .AssessmentSummary h4{
      margin: 4px 0;
      padding: 0;
      text-decoration: underline;
      
    }
    .Mark{
      height: fit-content;
      padding: 0 2rem;
     margin-top: 7px;
     
    }
    .Marks{
      display: flex;
      flex-direction: column;
    }
    .Marks p{
      margin:1px 0;
      font-size: 1.2rem;
      font-weight: 500;
      padding: 3px .75rem;
      color: #fff;
      
    }
    .Markdiv{
      width: fit-content;
      margin: 3px 0;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      color: #fff;
      font-size: 1.15rem;
      padding: 4px .5rem;
      
    }
    .FirstText{
      width: 9rem;
    }
    .SecondText{
      width: 4rem;
    }
    @media screen and (max-width: 1500px) {
      .AssesssmentSide {
        width: 24%;
        padding: 1rem 0.5rem;
        display: flex;
        flex-direction: column;
      }
      .Sidecard {
        /* width: 100%; */
        border: 1px solid;
        border-radius: 10px;
        padding: 1rem 0.5rem;
        color: #1a1e5d;
        background-color: #efefef;
      }
      .Grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        padding: 5px 0.5rem;
        row-gap: 1rem;
        column-gap: 0.75rem;
        margin-top: 2rem;
      }
    }
    @media screen and (max-width: 1300px){
      .DropContainer {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
      }
      .DropQuestions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 60%;
        padding-top: 1.5rem;
      }
      .DropAnswers {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 40%;
      }
      .DropAnswers h5 {
        margin: 0;
        text-decoration: underline;
        font-size: 1rem;
      }
      .Card {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 12rem;
        margin: 8px 0;
        border-radius: 6px;
        border: 1px solid #dbdbdb;
        background-color: #e1dfdf;
        padding: 4px 0;
      }
      .Card p {
        margin: 0;
        padding: 4px;
      }
      
      .DraggablePlaceholder {
        border: 2px dashed #ccc;
        padding: 4px;
        text-align: center;
        color: #999;
        cursor: pointer;
        margin: 4px 0;
      }
      .DraggableItem {
        border: 2px solid #ccc;
        padding: 4px;
        cursor: pointer;
        margin: 4px 0;
      }
      
      .DropQuestions table {
        width: 100%;
        border-spacing: 0;
        /* border: 2px solid #a8b1b9; */
        border-collapse: separate;
        border-spacing: 0 8px;
      }
      .DropQuestions tbody tr {
        text-align: center;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }
      .DropQuestions td {
        padding: 0.1rem 0.2rem;
      
        font-size: 1rem;
        text-align: center;
      }
      
      .DropQuestions td:nth-child(1) {
        text-align: left;
      }
      .DropQuestions td:nth-child(2) {
        width: 13rem;
      }    

    }
    @media screen and (max-width: 1025px){
      .DropContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .DropQuestions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 80%;
        padding-top: 1.5rem;
      }
      .DropAnswers {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
      }
    }
   
    @media screen and (max-width: 768px) {
      .assesment {
        display: flex;
        padding: 0 0.5rem;
        flex-direction: column-reverse;
        height: fit-content;
        justify-content: space-between;
        overflow-y: scroll;
        z-index: 9;
        align-items: center;
      }
    
      .AssesssmentSide {
        width: 100%;
        padding: 0.5rem 0;
        display: flex;
        flex-direction: column;
      }
      .AssessmentHead {
        width: 97.5%;
        display: flex;
        flex-direction: column;
        border: 1px solid #cccccc;
        padding: 6px 2px;
        height: fit-content;
        margin: 2px 0;
      }
      .Grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(25px, 1fr));
        padding: 5px;
        row-gap: 1rem;
        column-gap: 0.5rem;
        margin-top: 0.5rem;
      }
      .ValidateButton {
        background-color: #38b020;
        font-size: 1rem;
        outline: none;
        border-radius: 7px;
        padding: 0.5rem;
        color: #ffffff;
        cursor: pointer;
        text-transform: uppercase;
        border: none;
        margin: 0 0.25rem;
      }
      .SkipButton {
        background: #dde1ef;
        font-size: 1rem;
        outline: none;
        border-radius: 7px;
        padding: 0.5rem;
        color: #56197e;
        cursor: pointer;
        font-weight: 600;
        text-transform: uppercase;
        border: 2px solid #6356be;
        margin: 0 0.25rem;
      }
      .FinishButton {
        background-color: #0f6bd4;
        font-size: 1rem;
        outline: none;
        border-radius: 7px;
        padding: 0.5rem;
        cursor: pointer;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff;
        margin: 0 0.25rem;
        border: none;
      }
    }
    
    @media screen and (max-width: 600px) {
      .Container {
        padding: 0 0.5rem;
      }
      .DropQuestions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-top: 1.5rem;
      }
      .DropAnswers {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }
    }
    @media screen and (max-width: 500px) {
      .ValidateButton {
        background-color: #38b020;
        font-size: 0.9rem;
        outline: none;
        border-radius: 7px;
        padding: 0.5rem;
        color: #ffffff;
        cursor: pointer;
        font-weight: 600;
        text-transform: uppercase;
        border: none;
        margin: 0 0.25rem;
      }
      .SkipButton {
        background: #dde1ef;
        font-size: 0.9rem;
        outline: none;
        border-radius: 7px;
        padding: 0.5rem;
        color: #56197e;
        cursor: pointer;
        font-weight: 600;
        text-transform: uppercase;
        border: 2px solid #6356be;
        margin: 0 0.25rem;
      }
      .FinishButton {
        background-color: #0f6bd4;
        font-size: 0.9rem;
        outline: none;
        border-radius: 7px;
        padding: 0.5rem;
        cursor: pointer;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff;
        margin: 0 0.25rem;
        border: none;
      }
      .BottomIcons {
        display: flex;
        flex-direction: row;
        justify-content: right;
        font-size: 1rem;
        padding: 3px 9px;
        color: #1a1e5d;
      }
      .AddButton button:nth-child(3) {
        margin-right: 0;
        margin: 4px;
      }
      .assessmentSuccessAnimation{
        width: 15rem;
      }
      .assessmentFailAnimation{
        width: 6rem;
      }
      
    }
    .WhiteBoradContainer{
      width: 60rem;
    height: 36rem;
    border-radius: 16px;
    background-color: #fff;
    position: fixed;
    top: 19%;
    right: 1%;
    border: 1px solid #dbdbdb;
    /* transform: translate(-50%, -50%); */
    animation: slideIn 0.5s forwards;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 9;
    }
    .closing {
      animation: slideOut 0.5s; /* Adjust the duration as needed */
    }
    @keyframes slideIn {
      from {
        right: -60rem; /* Off-screen initially */
      }
      to {
        right: 1rem; /* Final position */
      }
    }
    @keyframes slideOut {
      from {
        right: 1rem; /* Initial position when closing */
      }
      to {
        right: -60rem; /* Move off-screen to the right */
      }
    }
    .WhiteBoradHead{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid;
      padding: 0rem 1rem;
      margin: 0;
      border: none;
      border-radius:15px 15px 0 0 ;
    }
    .WhiteBoradIcon{
      cursor: pointer;
      font-size: 1.25rem;
      padding: 8px;
    }
    .WhiteBoradContent{
      height: 30rem;
      padding:.5rem 1rem;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    
    
    .chatboticon {
      position: fixed;
      top: 50%;
     right: 0%;
      /* color: #fff;  */
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /* animation: slideIn 0.5s forwards; */
    }
    
    .Iconhead {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      color: #000;
      font-weight: bold;
      font-size: 1.25rem;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 99;
    }
    .icontext {
      padding: .5rem;
      text-align: center;
      font-weight: 500;
      font-size: 1rem;
      /* background-color: #e4c395; */
      border-radius: 6px;
      /* border: 1px solid; */
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color:#1a1e5d;
      background-color: #fff;
    }
    .icontext p{
      margin: 0;
      padding: 0;
    }
    .WhiteboardFloatingIcon{
    font-size: 1.5rem;
    margin: 2px 0;
    color:#1a1e5d;
    }
    .ChatImage {
      width: 11rem;
      /* border-radius: 50%; */
      /* border: 1px solid; */
      display: flex;
      align-items: center;
      /* background-color: #0f8bea; */
    }
    .ChatImage img {
      width: 100%;
    }
    