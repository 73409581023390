.Container {
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .topBar {
    display: flex;
    justify-content: space-between;
    padding: 2rem 5rem;
    align-items: center;
  }
  
  .topBarMenu {
    display: flex;
    justify-content: space-between;
    gap: 4rem;
    font-weight: 500;
    font-size: 21px;
    line-height: 27.65px;
    color:rgba(63, 63, 63, 1);
    ;
  }
  .topBarMenu p {
    cursor: pointer;
  }
  .topBarMenu a{
    font-weight: 500;
    font-size: 21px;
    line-height: 27.65px;
    color:rgba(63, 63, 63, 1);
    cursor: pointer;
    text-decoration: none;
  }
  
  .topImg {
    display: flex;
    align-items: center;
   padding-left: 2rem;
  }
  .hamburgMenu{
    display: none;
  }
  .hamburgPopup{
    display: none;
  }
  @keyframes slideIn {
    from {
      right: -60rem; 
    }
    to {
      right: 0;
    }
  }
  @keyframes slideOut {
    from {
      right: 0; 
    }
    to {
      right: -60rem; 
    }
  }

  @media screen and (max-width:768px) {
    .topBar {
      padding: 2rem ;
    }
    .topImg {
     padding-left: 0rem;
    }
    .topImg img{
      width: 60%;
    }
    .topBarMenu {
      gap: 1rem;
      font-size: 18px;
      display: none;
    }
    .topBarMenu a{
      font-size: 18px;
    }
    .hamburgMenu{
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .hamburgMenu svg{
      font-size: 2rem;
      cursor: pointer;
    }
    .hamburgPopup{
      top: 7%;
    position: fixed;
    z-index: 99999999;
    height: fit-content;
    bottom: 0;
    right: 0;
    border-radius: 0;
    background-color: #ffffff;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px 0 0 10px ;
    animation: slideIn 0.8s forwards;
    gap: 1rem;
    padding:  2rem;
    box-shadow: 0px 2px 3px 2px #00000040;
    }
    .closing {
      animation: slideOut 0.8s forwards;
    }
    .hamburgMenuItem{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
    .hamburgMenuItem a{
      font-weight: 500;
      font-size: 24px;
      line-height: 27.65px;
      color:#595BDB;
      cursor: pointer;
      text-decoration: none;
    }
  }
  @media screen and (max-width:575px) {
    .topImg img{
      width: 60%;
    }
    .topBarMenu {
      gap: 1rem;
      font-size: 16px;
    }
    .topBar {
      padding: 1rem ;
    }
  }
  @media screen and (max-width:475px) {
    .topImg img{
      width: 50%;
    }
    .topBarMenu {
      gap: 1rem;
      font-size: 14px;
    }
    .topBar {
      padding: 1rem ;
    }
    .topBarMenu a{
      font-size: 14px;
    }
  }