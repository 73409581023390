@media (max-width: 480px) {
  .container {
    width: 100%;
    height: 100vh;
  }
  .assessmentPage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background: #e2e2e2;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }
  .Overlay {
    background-color: #120d0d73;
    height: 100vh;
    position: fixed;
    top: 0;
    width: 100vw;
  }
  .questionPaletteSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    justify-content: flex-start;
    background: #fff;
    padding: 0.75rem;
    height: 30vh;
    overflow-y: scroll;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .paletteHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    box-shadow: 0 3px 8px #0000003d;
    padding: 0.5rem;
  }
  .paletteHeader p {
    font-size: 1.25rem;
    font-weight: 500;
    color: #aa4e26;
  }
  .paletteHeader svg {
    font-size: 1.25rem;
    cursor: pointer;
  }
  .paletteContainer {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    width: 100%;
    height: 80%;
    overflow-y: scroll;
  }
  .paletteGrid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    row-gap: 1rem;
    column-gap: 1rem;
    width: 100%;
    padding: 0.5rem.0;
  }
  .paletteGrid button {
    background: #cecef0;
    border: 2px solid #6868e8;
    padding: 0.25rem;
    color: #1b1b8e;
    font-size: 1.25rem;
    /* width: 30px; */
    border-radius: 5px;
  }
  .assessmentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 70%;
  }
  .assessmentSection {
    background: #fff;
    width: 90%;
    padding: 0.5rem;
    margin: 0.5rem;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .sectionTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 55%;
  }
  .assessmentCount {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 0.25rem 0;
  }
  .assessmentCount button {
    width: 70%;
    padding: 0.5rem;
    font-size: 1.15rem;
    color: #56197e;
    border: 2px solid #6356be;
    background: #dde1ef;
    border-radius: 5px;
    margin: 0.25rem 0;
  }
  .assessmentQuestionSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 80%;
    margin: 0.25rem 0;
  }
  .assessmentQuestion {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0.5rem 0;
  }
  .assessmentQuestion p {
    font-size: 1.25rem;
    font-weight: 700;
  }
  .questionValue {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .questionValue p {
    font-size: 1.25rem;
  }
  .questionValue input {
    margin: 0 0.5rem;
    outline: none;
    font-size: 1.15rem;
  }
  .questionValue span {
    font-size: 1.25rem;
    margin: 0 0.25rem;
  }
  .assesmentAnswer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0.5rem 0;
  }
  .answerTextarea {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .bottomIcons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0.5rem 0;
  }
  .bottomIcons p {
    font-size: 1.25rem;
    color: #1a1e5d;
  }
  .answer {
  }
  .addButton {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin: 0.75rem 0;
  }
  .previousAnswer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    justify-content: space-between;
    background: #fff;
    padding: 0.75rem;
    height: 55vh;
    overflow-y: scroll;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .answerHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    box-shadow: 0 3px 8px #0000003d;
    padding: 0.5rem;
  }
  .answerHeader h4 {
    font-size: 1.15rem;
    color: #15168cba;
  }
  .answerHeader svg {
    font-size: 1.25rem;
  }
  .previousAnswerContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 85%;
    overflow-y: scroll;
  }
  .AnswerList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    justify-content: flex-start;
  }
  .AnswerSend {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin: 0.75rem 0;
  }
  .AnswerIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
  }
  .AnswerIcon span {
    font-size: 1.25rem;
    background: #6398a6;
    color: #fff;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    text-align: center;
  }
  .AnswerText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
    justify-content: flex-start;
  }
  .AnswerReplay {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .replayText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .answerGrid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 1rem;
    column-gap: 1rem;
    width: 100%;
  }
  .dictationCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .dictationLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
  }
  .dictationLeft svg {
    font-size: 1.5rem;
    color: green;
  }
  .dictationRight {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75%;
  }
  .dictationRight input {
    width: 100%;
    font-size: 1.15rem;
    padding: 0.5rem;
    background: #e9e2e2;
    border: none;
    outline: none;
    border-radius: 6px;
    color: #743cd7;
  }
  .dictationRight input::-webkit-input-placeholder {
    color: #743cd7;
  }
  .DropContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    height: 100%;
    overflow-y: scroll;
  }
  .DropQuestions {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 1rem;
    column-gap: 1rem;
    width: 100%;
  }
  .dropCard {
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* width: 100%; */
    border: 2px solid #97a3ac;
    border-radius: 5px;
    height: 9rem;
  }
  .dropCardLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }

  .DropDownImage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8rem;
  }
  .DropDownImage img {
    width: 100%;
    height: 100%;
  }
  .dropCardRight {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }
  .DraggableItem {
    border: 2px dashed #ccc;
    padding: 0.25rem;
    text-align: center;
    color: #999;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .DraggablePlaceholder {
    border: 2px dashed #ccc;
    padding: 0.25rem;
    text-align: center;
    color: #999;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
  }
  .DropAnswers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0.75rem 0;
  }
  .DropAnswers h5 {
    font-size: 1.25rem;
    color: #cb2121;
  }
  .CardContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 0.5rem;
    column-gap: 1rem;
    width: 100%;
    margin: 0.5rem 0;
  }
  .Card {
    display: flex;
    align-items: center;
    /* margin: 0.5rem 0; */
    border-radius: 6px;
    border: 1px solid #dbdbdb;
    background-color: #e1dfdf;
    padding: 0.5rem 0;
    justify-content: center;
  }
  .Card p {
    font-size: 1.25rem;
  }
  .ValidateButton {
    background: #38b020;
    font-size: 1.15rem;
    outline: none;
    border-radius: 7px;
    padding: 0.75rem;
    color: #ffffff;
    cursor: pointer;
    text-transform: uppercase;
    border: none;
    width: 75%;
    font-weight: 600;
  }
  .SkipButton {
    background: #56197e;
    font-size: 1.15rem;
    outline: none;
    border-radius: 7px;
    padding: 0.75rem;
    color: #ffffff;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
    width: 75%;
  }
  .FinishButton {
    background-color: #0f6bd4;
    font-size: 1.15rem;
    outline: none;
    border-radius: 7px;
    padding: 0.75rem;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    margin: 0.5rem 0;
    border: none;
    width: 75%;
  }
  .matchCard {
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* width: 100%; */
    border: 2px solid #97a3ac;
    border-radius: 5px;
    padding: 0.75rem 0;
  }
  .matchCardLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
  }
  .matchCardRight {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
  }
  .multipleQuestion{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .container {
    width: 100%;
    height: 100vh;
  }
  .assessmentPage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background: #e2e2e2;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }
  .Overlay {
    background-color: #120d0d73;
    height: 100vh;
    position: fixed;
    top: 0;
    width: 100vw;
  }
  .questionPaletteSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    justify-content: flex-start;
    background: #fff;
    padding: 0.75rem;
    height: 30vh;
    overflow-y: scroll;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .paletteHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    box-shadow: 0 3px 8px #0000003d;
    padding: 0.5rem;
  }
  .paletteHeader p {
    font-size: 2rem;
    font-weight: 500;
    color: #aa4e26;
  }
  .paletteHeader svg {
    font-size: 2rem;
    cursor: pointer;
  }
  .paletteContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80%;
    overflow-y: scroll;
  }
  .paletteGrid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    row-gap: 1rem;
    column-gap: 1rem;
    width: 100%;
    /* padding: 0.5rem; */
  }
  .paletteGrid button {
    background: #cecef0;
    border: 2px solid #6868e8;
    padding: 0.25rem;
    color: #1b1b8e;
    font-size: 2rem;
    /* width: 30px; */
    border-radius: 5px;
  }
  .assessmentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 70%;
  }
  .assessmentSection {
    background: #fff;
    width: 90%;
    padding: 0.75rem;
    margin: 0.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .sectionTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 55%;
  }
  .assessmentCount {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 0.25rem 0;
  }
  .assessmentCount button {
    width: 85%;
    padding: 0.5rem;
    font-size: 2rem;
    color: #56197e;
    border: 2px solid #6356be;
    background: #dde1ef;
    border-radius: 5px;
    margin: 0.25rem 0;
  }
  .assessmentQuestionSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 80%;
    margin: 0.25rem 0;
  }
  .assessmentQuestion {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0.75rem 0;
  }
  .questionName {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .questionName,
  .assessmentQuestion p {
    font-size: 2rem;
    font-weight: 700;
  }
  .questionValue {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
  }
  .questionValue p {
    font-size: 2rem;
  }
  .questionValue input {
    margin: 0 0.5rem;
    font-size: 1.75rem;
    border: 1px solid #8686ac;
    padding: 0.5rem;
    outline: none;
  }
  .questionValue span {
    font-size: 2rem;
    margin: 0 0.25rem;
  }
  .assesmentAnswer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0.5rem 0;
  }
  .answerTextarea {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .bottomIcons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0.5rem 0;
  }
  .bottomIcons p {
    font-size: 2rem;
    color: #1a1e5d;
  }
  .answer {
  }
  .addButton {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin: 0.75rem 0;
  }
  .previousAnswer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    justify-content: space-between;
    background: #fff;
    padding: 0.75rem;
    height: 55vh;
    overflow-y: scroll;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .answerHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    box-shadow: 0 3px 8px #0000003d;
    padding: 0.75rem;
  }
  .answerHeader h4 {
    font-size: 1.75rem;
    color: #15168cba;
  }
  .answerHeader svg {
    font-size: 1.75rem;
  }
  .previousAnswerContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 85%;
    overflow-y: scroll;
  }
  .AnswerList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    justify-content: flex-start;
  }
  .AnswerSend {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin: 0.75rem 0;
  }
  .AnswerIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
  }
  .AnswerIcon span {
    font-size: 1.25rem;
    background: #6398a6;
    color: #fff;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    text-align: center;
  }
  .AnswerText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
    justify-content: flex-start;
  }
  .AnswerText p {
    font-size: 1.5rem;
  }
  .AnswerText span {
    font-size: 1.25rem;
  }
  .AnswerReplay {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .replayText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .replayText p {
    font-size: 1.5rem;
  }
  .answerGrid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 1rem;
    column-gap: 1rem;
    width: 100%;
  }
  .dictationCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .dictationLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
  }
  .dictationLeft svg {
    font-size: 2rem;
    color: green;
  }
  .dictationRight {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75%;
  }
  .dictationRight input {
    width: 100%;
    font-size: 2rem;
    padding: 0.75rem;
    background: #e9e2e2;
    border: none;
    outline: none;
    border-radius: 6px;
    color: #743cd7;
  }
  .dictationRight input::-webkit-input-placeholder {
    color: #743cd7;
  }
  .DropContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    height: 100%;
    overflow-y: scroll;
  }
  .DropQuestions {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 1rem;
    column-gap: 1rem;
    width: 100%;
  }
  .dropCard {
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* width: 100%; */
    border: 2px solid #97a3ac;
    border-radius: 5px;
    height: 9rem;
  }
  .dropCardLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }

  .DropDownImage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8rem;
  }
  .DropDownImage img {
    width: 100%;
    height: 100%;
  }
  .dropCardRight {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }
  .DraggableItem {
    border: 2px dashed #ccc;
    padding: 0.25rem;
    text-align: center;
    color: #999;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .DraggablePlaceholder {
    border: 2px dashed #ccc;
    padding: 0.25rem;
    text-align: center;
    color: #999;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    font-size: 1.75rem;
  }
  .DropAnswers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0.75rem 0;
  }
  .DropAnswers h5 {
    font-size: 2rem;
    color: #cb2121;
  }
  .CardContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 0.5rem;
    column-gap: 1rem;
    width: 100%;
    margin: 0.5rem 0;
  }
  .Card {
    display: flex;
    align-items: center;
    /* margin: 0.5rem 0; */
    border-radius: 6px;
    border: 1px solid #dbdbdb;
    background-color: #e1dfdf;
    padding: 0.5rem 0;
    justify-content: center;
  }
  .Card p {
    font-size: 2rem;
  }
  .ValidateButton {
    background: #38b020;
    font-size: 1.75rem;
    outline: none;
    border-radius: 7px;
    padding: 0.75rem;
    color: #ffffff;
    cursor: pointer;
    text-transform: uppercase;
    border: none;
    width: 85%;
    font-weight: 600;
  }
  .SkipButton {
    background: #56197e;
    font-size: 1.75rem;
    outline: none;
    border-radius: 7px;
    padding: 0.75rem;
    color: #ffffff;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
    width: 85%;
  }
  .FinishButton {
    background-color: #0f6bd4;
    font-size: 1.75rem;
    outline: none;
    border-radius: 7px;
    padding: 0.75rem;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    margin: 0.5rem 0;
    border: none;
    width: 85%;
  }
  .matchCard {
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* width: 100%; */
    border: 2px solid #97a3ac;
    border-radius: 5px;
    padding: 0.75rem 0;
  }
  .matchCardLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
  }
  .matchCardRight {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
  }
  .multipleQuestion{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .container {
    width: 100%;
    height: 100vh;
  }
  .assessmentPage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background: #e2e2e2;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }
  .assessmentPage::-webkit-scrollbar {
    display: none;
  }
  .Overlay {
    background-color: #120d0d73;
    height: 100vh;
    position: fixed;
    top: 0;
    width: 100vw;
  }
  .questionPaletteSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    justify-content: flex-start;
    background: #fff;
    padding: 0.75rem;
    height: 30vh;
    /* overflow-y: scroll; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .paletteHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    box-shadow: 0 3px 8px #0000003d;
    padding: 0.5rem;
  }
  .paletteHeader p {
    font-size: 1.5rem;
    font-weight: 500;
    color: #aa4e26;
  }
  .paletteHeader svg {
    font-size: 1.5rem;
    cursor: pointer;
  }
  .paletteContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80%;
    overflow-y: scroll;
  }
  .paletteGrid {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    row-gap: 1rem;
    column-gap: 1rem;
    width: 100%;
    /* padding: 0.5rem; */
  }
  .paletteGrid button {
    background: #cecef0;
    border: 2px solid #6868e8;
    padding: 0.25rem;
    color: #1b1b8e;
    font-size: 1.75rem;
    width: 55px;
    border-radius: 5px;
  }
  .assessmentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 70%;
  }
  .assessmentSection {
    background: #fff;
    width: 90%;
    padding: 0.75rem;
    margin: 0.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .sectionTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 55%;
  }
  .assessmentCount {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 0.25rem 0;
  }
  .assessmentCount button {
    width: 70%;
    padding: 0.5rem;
    font-size: 1.75rem;
    color: #56197e;
    border: 2px solid #6356be;
    background: #dde1ef;
    border-radius: 5px;
    margin: 0.25rem 0;
  }
  .assessmentQuestionSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 80%;
    margin: 0.25rem 0;
  }
  .assessmentQuestion {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0.5rem 0;
  }
  .assessmentQuestion p {
    font-size: 1.75rem;
    font-weight: 700;
  }
  .questionValue {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .questionValue p {
    font-size: 1.5rem;
  }
  .questionValue input {
    margin: 0 0.5rem;
    font-size: 1.75rem;
    border: 1px solid #8686ac;
    padding: 0.5rem;
    outline: none;
  }
  .questionValue span {
    font-size: 1.75rem;
    margin: 0 0.25rem;
  }
  .assesmentAnswer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0.5rem 0;
  }
  .answerTextarea {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .bottomIcons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0.5rem 0;
  }
  .bottomIcons p {
    font-size: 1.75rem;
    color: #1a1e5d;
  }
  .answer {
  }
  .addButton {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin: 0.75rem 0;
  }
  .previousAnswer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    justify-content: space-between;
    background: #fff;
    padding: 0.75rem;
    height: 55vh;
    overflow-y: scroll;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .answerHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    box-shadow: 0 3px 8px #0000003d;
    padding: 0.5rem;
  }
  .answerHeader h4 {
    font-size: 1.5rem;
    color: #15168cba;
  }
  .answerHeader svg {
    font-size: 1.5rem;
  }
  .previousAnswerContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 85%;
    overflow-y: scroll;
  }
  .AnswerList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    justify-content: flex-start;
  }
  .AnswerSend {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin: 0.75rem 0;
  }
  .AnswerIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
  }
  .AnswerIcon span {
    font-size: 1.5rem;
    background: #6398a6;
    color: #fff;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    text-align: center;
    padding: 0.25rem;
  }
  .AnswerText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
    justify-content: flex-start;
  }
  .AnswerText p {
    font-size: 1.25rem;
  }
  .AnswerReplay {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .replayText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .replayText p{
    font-size: 1.25rem;
  }
    .answerGrid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 1rem;
    column-gap: 1rem;
    width: 100%;
  }
  .dictationCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .dictationLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
  }
  .dictationLeft svg {
    font-size: 1.5rem;
    color: green;
  }
  .dictationRight {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75%;
  }
  .dictationRight input {
    width: 100%;
    font-size: 1.15rem;
    padding: 0.5rem;
    background: #e9e2e2;
    border: none;
    outline: none;
    border-radius: 6px;
    color: #743cd7;
  }
  .dictationRight input::-webkit-input-placeholder {
    color: #743cd7;
  }
  .DropContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    height: 100%;
    overflow-y: scroll;
  }
  .DropQuestions {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 1rem;
    column-gap: 1rem;
    width: 100%;
  }
  .dropCard {
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* width: 100%; */
    border: 2px solid #97a3ac;
    border-radius: 5px;
    height: 9rem;
  }
  .dropCardLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }

  .DropDownImage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8rem;
  }
  .DropDownImage img {
    width: 100%;
    height: 100%;
  }
  .dropCardRight {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }
  .DraggableItem {
    border: 2px dashed #ccc;
    padding: 0.25rem;
    text-align: center;
    color: #999;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .DraggablePlaceholder {
    border: 2px dashed #ccc;
    padding: 0.25rem;
    text-align: center;
    color: #999;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    font-size: 1.75rem;
  }
  .DropAnswers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0.75rem 0;
  }
  .DropAnswers h5 {
    font-size: 1.75rem;
    color: #cb2121;
  }
  .CardContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 0.5rem;
    column-gap: 1rem;
    width: 100%;
    margin: 0.5rem 0;
  }
  .Card {
    display: flex;
    align-items: center;
    /* margin: 0.5rem 0; */
    border-radius: 6px;
    border: 1px solid #dbdbdb;
    background-color: #e1dfdf;
    padding: 0.5rem 0;
    justify-content: center;
  }
  .Card p {
    font-size: 1.75rem;
  }
  .ValidateButton {
    background: #38b020;
    font-size: 1.5rem;
    outline: none;
    border-radius: 7px;
    padding: 0.75rem;
    color: #ffffff;
    cursor: pointer;
    text-transform: uppercase;
    border: none;
    width: 75%;
    font-weight: 600;
  }
  .SkipButton {
    background: #56197e;
    font-size: 1.5rem;
    outline: none;
    border-radius: 7px;
    padding: 0.75rem;
    color: #ffffff;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
    width: 75%;
  }
  .FinishButton {
    background-color: #0f6bd4;
    font-size: 1.5rem;
    outline: none;
    border-radius: 7px;
    padding: 0.75rem;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    margin: 0.5rem 0;
    border: none;
    width: 75%;
  }
  .matchCard {
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* width: 100%; */
    border: 2px solid #97a3ac;
    border-radius: 5px;
    padding: 0.75rem 0;
  }
  .matchCardLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
  }
  .matchCardRight {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
  }
  .multipleQuestion{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
}
