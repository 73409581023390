.Container {
  height: 100vh;
  padding: 0 1rem;
  overflow-y: scroll;
  /* position: relative; */
}
.Container::-webkit-scrollbar {
  display: none;
}
.topicHead {
  padding: 0.25rem;
  display: flex;
  line-height: 0;
  /* height: 50px; */
  color: #fff;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}
.topicHead h3 {
  font-size: 1.75rem;
}
.topicDetal {
  padding: 5px 1rem;
  height: 83vh;
  background-color: #fff;
  border: 1px solid #cccccc;
  /* border-radius: 5px; */
  overflow-y: scroll;
}
.topicDetal p {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0;
}
.HeaderIcons {
  font-size: 2rem;
  color: #1a1e5d;
}
.assesment {
  display: flex;
  flex-direction: row;
  padding: 0 0.5rem;
  height: 90vh;
  justify-content: space-between;
  overflow-y: scroll;
  z-index: 9;
}
/* .assesment::-webkit-scrollbar{
  display: none;
} */
.assesmentQuestion {
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  align-items: normal;
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  pointer-events: none; /* Disable mouse events */
}
.assesmentQuestion p{
  margin: 0;
  padding: 0;
}
.assesmentQuestion input {
  user-select: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  pointer-events: auto; /* Enable mouse events */
}
.assesmentAnswer {
  display: flex;
  /* width: 100%; */
  flex-direction: column;
  /* align-items: center; */

  border-radius: 6px;
  background-color: #fff;
  padding: 3px 5px;
}
.assesmentAnswer textarea {
  /* width: 100%; */
  height: 75px;
  border-radius: 5px;
  outline: none;
  padding: 5px;
  resize: none;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}
/* .AnswerIcon{
  position: absolute;
  right: 1.5rem;
  font-size: 1.25rem;
  color: green;
}
.CancelIcon{
  position: absolute;
  right: 3rem;
  font-size: 1.25rem;
  color: red;
} */
.answer {
  width: 100%;
  font-size: 1rem;
  padding: 8px 6px;
}
.answer p {
  margin: 0;
}
.AddButton {
  width: 100%;
  text-align: right;
}
.AddButton button:nth-child(3) {
  margin-right: 0;
}

.ValidateButton {
  background-color: #38b020;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: #ffffff;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  margin: 0 0.25rem;
}
.SkipButton {
  background: #dde1ef;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: #56197e;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #6356be;
  margin: 0 0.25rem;
}
.FinishButton {
  background-color: #0f6bd4;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  margin: 0 0.25rem;
  border: none;
}
.BottomIcons {
  display: flex;
  flex-direction: row;
  justify-content: right;
  font-size: 1.25rem;
  padding: 3px 9px;
  color: #1a1e5d;
}
.BottomIcons p {
  margin: 0;
}
.Upload {
  width: 13%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.validate {
  width: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}
.Tab {
  /* border: 1px solid #bbb8b8; */
  /* border-radius: 1rem; */
  height: fit-content;
  /* background: linear-gradient(#fff,#dbdbdb); */
  /* padding: 1rem ; */
}
.Practise {
  /* width: 100%; */
  padding: 0 1rem;
  overflow-y: scroll;
  height: 90vh;
  border: 1px solid #cccccc;
}
.PractiseAnswer {
  width: 100%;
  border: 1px solid;
  background-color: #fff;
}
.PractiseAnswer textarea {
  width: 99.5%;
  resize: none;
  height: 15rem;
  border: none;
  outline: none;
}
.Practise2 {
  width: 100%;
  height: auto;
}
.PractiseQuestion {
  width: 100%;
  color: #1a1e5d;
  height: auto;
}
.PractiseQuestion p {
  font-weight: 500;
  font-size: 1.15rem;
}
.PractiseIcons {
  font-size: 1.5rem;
  padding: 5px 10px;
  color: #1a1e5d;
}
.PractiseIcons .Upload {
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Attachment {
  color: #1a1e5d;
}
.LessonDiv {
  padding: 0rem 12px;
}
.TopicButtons {
  width: 100%;
  align-items: center;
  text-align: center;
  margin: 0.5rem 0;
}
.TopicButtons button {
  margin: 0 8px;
  font-size: 1.25rem;
  padding: 4px 10px;
  border-radius: 6px;
  border: 2px solid transparent;
  cursor: pointer;
}
.summarize {
  background-color: #1a1e5d;
  color: #fff;
}
.explain {
  background-color: #0f8bea;
  color: #fff;
}
.LessonHead {
  /* background-color: rgb(178, 133, 0); */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 6px 1rem;
  background: #dde1ef;
  color: #56197e;
}
.LessonHead p {
  font-size: 1.25rem;
  /* color: #fff; */
  font-weight: 600;
  margin: 0;
}
.SecondHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 14rem;
  padding: 0 2px;
}
.HeaderIcon {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.75rem;
  color: #1a1e5d;
  cursor: pointer;
  width: 5rem;
}
.Blank {
  color: transparent;
  opacity: 0;
}
.UploadPopUp {
  width: 50rem;
  min-height: 20rem;
  border-radius: 16px;
  /* border-top: 1px solid #0f8bea; */
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  padding-bottom: 6px;
  z-index: 9;
}
.POPUP {
  width: 60rem;
  height: 37rem;
  border-radius: 16px;
  border-top: 1px solid #0f8bea;
  background-color: #fff;
  position: fixed;
  top: 18%;
  right: 1rem;
  /* transform: translate(-50%, -50%); */
  border: 1px solid #dbdbdb;
  z-index: 99;
  animation: slideIn 0.5s forwards;
}
.closing {
  animation: slideOut 0.5s; /* Adjust the duration as needed */
}
@keyframes slideIn {
  from {
    right: -60rem; /* Off-screen initially */
  }
  to {
    right: 1rem; /* Final position */
  }
}
@keyframes slideOut {
  from {
    right: 1rem; /* Initial position when closing */
  }
  to {
    right: -60rem; /* Move off-screen to the right */
  }
}
.AnswerPopup {
  width: 50rem;
  height: 32rem;
  border-radius: 16px;
  /* border-top: 1px solid #0f8bea; */
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.Overlay {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: rgba(4, 4, 4, 0.3);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}
.POPUPOverlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(4, 4, 4, 0.793);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}
.popupHead {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
  padding: 0rem 1rem;
  background-color: #0f8bea;
  margin: 0;
  border: none;
  border-radius: 15px 15px 0 0;
}
.PopupIcon {
  cursor: pointer;
  color: #fff;
  font-size: 1.25rem;
}
.HeadText p {
  padding: 0;
  font-weight: 600;
  margin: 8px 0;
  color: #fff;
  font-size: 1.25rem;
}
.summaryContent {
  height: 23rem;
  padding: 1rem;
  overflow-y: scroll;
}
.summaryContent p {
  font-size: 1.25rem;
  margin: 0;
  padding: 0 6px;
}
.UploadContent {
  /* width: 100%; */
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: left;
}
.UploadContent label {
  font-size: 1.25rem;
  font-weight: 500;
  padding: 0 8px;
  cursor: pointer;
}
.UploadContent input {
  cursor: pointer;
  height: 2rem;
  width: 1.2rem;
}
.uploddiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}
.Copy {
  width: 40%;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: left;
  cursor: pointer;
}
.copyIcon {
  font-size: 1.5rem;
  color: #1a1e5d;
}
.copyText {
  font-size: 1.25rem;
  margin-left: 5px;
  color: #1a1e5d;
  font-weight: 500;
}
.attachmentContent {
  /* width: 60%; */
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  padding: 1rem;
}
.AttachmentInput {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  padding: 1rem;
}
.urlInput {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  justify-content: center;
  /* align-items: center; */
  width: 100%;
}
.UploadInput {
  width: 100%;
}
.UploadInput input {
  height: 2rem;
  font-size: 1.25rem;
  width: 100%;
}
.Button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.Button button {
  font-size: 1rem;
  color: #fff;
  border: none;
  outline: none;
  background-color: #1a1e5d;
  padding: 5px 1rem;
  cursor: pointer;
  border-radius: 6px;
  margin: 1rem 0;
}
.CopyContent {
  padding: 3px 1rem;
}
.Pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
  cursor: pointer;
  align-items: center;
}
.Pagination button {
  font-size: 1rem;
  border: none;
  outline: none;
  padding: 3px 8px;
  cursor: pointer;
  border-radius: 6px;
  align-items: center;
  display: flex;
  margin-left: 8px;
  background-color: #fff;
}
.SaveButton {
  padding: 8px 2rem;
  align-items: end;
  height: 2rem;

  display: flex;
  justify-content: flex-end;
}
.SaveButton button {
  font-size: 1rem;
  color: #fff;
  border: none;
  outline: none;
  background-color: #1a1e5d;
  padding: 7px 1.5rem;
  cursor: pointer;
  border-radius: 6px;
}
.PopupContent {
  height: 24rem;
  padding: 1rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.BotInput {
  width: 92%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  border: 1px solid;
  justify-content: center;
  margin: auto;
  border-radius: 10px;
}
.Input {
  width: 100%;
}
.Input input {
  /* width: 100%; */
  height: 3rem;
  border: none;
  outline: none;
  font-size: 1.2rem;
}
.Input .Upload {
  font-size: 1.25rem;
  padding: 1px;
}
.Icon {
  font-size: 2rem;
  color: #0f8bea;
  margin: 0 8px;
  cursor: pointer;
}
.BotImage img {
  width: 90%;
  height: 90%;
}
.teacherImage {
  width: 2rem;
  height: 2rem;
  position: relative;
  margin-right: 5px;
}
.teacherImage img {
  width: 100%;
  height: 100%;
}
.BotImage {
  width: 2rem;
  height: 2rem;
  position: relative;
  margin-right: 5px;
}
.PopupContent {
  height: 24rem;
  padding: 1rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 999;
  scroll-behavior: smooth;
}
.message {
  padding: 8px;
  margin: 4px;
  border-radius: 4px;
  max-width: 80%;
}

.sender {
  align-self: flex-end;
  background-color: #007bff;
  color: white;
}

.receiver {
  align-self: flex-start;
  background-color: #f0f0f0;
  color: #333;
}
.Input {
  display: flex;
  flex-direction: column;
}
.AssesssmentSide {
  width: 22%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.AssessmentHead {
  width: 70%;
  display: flex;
  flex-direction: column;
  border: 1px solid #cccccc;
  padding: 6px;
  height: fit-content;
  margin: 2px 0;
}
.Sidecard {
  /* width: 100%; */
  padding: 1rem;
  color: #1a1e5d;
  background-color: #fff;
  box-shadow: 0 3px 8px #0000003d;
}
.Grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 5px 1rem;
  row-gap: 1rem;
  column-gap: 1rem;
  margin-top: 2rem;
}
.span {
  border: 1px solid;
  padding: 5px 2px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  background-color: #fff;
}
.assessmentSuccessAnimation {
  width: 35rem;
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  z-index: 9;
}
.assessmentFailAnimation{
  width: 10rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.CardHead {
  font-size: 1.25rem;
}
.correct-number {
  background-color: green;
}

.incorrect-number {
  background-color: red;
}
.imageView img {
  width: 100%;
  height: 100%;
}
.Count {
  width: fit-content;
  padding: 8px 30px;
  background: #dde1ef;
  font-size: 1rem;
  outline: none;
  border-radius: 7px;
  color: #56197e;
  font-weight: 600;
  border: 2px solid #6356be;
  margin: 0 0.25rem;
}
.value {
  font-size: 1rem;
}
.correct {
  background-color: green;
}

.incorrect {
  background-color: red;
}

.notattended {
  background-color: yellow;
}
.Validtext {
  text-align: center;
  margin: 0;
}
.imageView {
  height: 76vh;
  width: fit-content;
}
.aiContent {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  padding: 1rem;
}
.aiContent textarea {
  height: 100px;
  border-radius: 5px;
  outline: none;
  padding: 0.5rem;
  resize: none;
}
.aiButton,
.aigenerateButton {
  text-align: right;
}
.aiButton button {
  font-size: 1rem;
  color: #fff;
  border: none;
  outline: none;
  background-color: #1a1e5d;
  padding: 5px 1rem;
  cursor: pointer;
  /* margin-bottom: 1rem; */
  border-radius: 6px;
  /* margin: 2rem 0 ; */
}
.aiContent input {
  width: 80%;
  height: 2rem;
  font-size: 1.25rem;
}
.aigenerate {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: left;
  /* align-items: center; */
  padding: 1rem 2rem;
}
.aigenerate textarea {
  height: 100px;
  border-radius: 5px;
  outline: none;
  padding: 0.5rem;
  resize: none;
}
.aigenerateButton button {
  font-size: 1rem;
  color: #fff;
  border: none;
  outline: none;
  background-color: #2291f2;
  padding: 5px 1rem;
  cursor: pointer;
  /* margin-bottom: 1rem; */
  border-radius: 6px;
  margin: 2rem 0;
}
.chatboticon {
  position: fixed;
  bottom: 20px; /* Adjust as needed */
  right: 2rem; /* Adjust as needed */
  /* color: #fff;  */
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Iconhead {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: #000;
  font-weight: bold;
  font-size: 1.25rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
}
.icontext {
  padding: 4px;
  text-align: center;
  font-weight: 500;
  /* background-color: #e4c395; */
  border-radius: 50%;
}
.ChatImage {
  width: 11rem;
  /* border-radius: 50%; */
  /* border: 1px solid; */
  display: flex;
  align-items: center;
  /* background-color: #0f8bea; */
}
.ChatImage img {
  width: 100%;
}
.PractiseHeadButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 6px 0;
}
.PractiseHeadButton button {
  font-size: 1rem;
  color: #fff;
  border: none;
  outline: none;
  background-color: #1a1e5d;
  padding: 5px 1rem;
  cursor: pointer;
  /* margin-bottom: 1rem; */
  border-radius: 6px;
}
.ActivityPopup {
  width: 50rem;
  min-height: 20rem;
  border-radius: 16px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.TitleInput input {
  width: 100%;
  height: 2rem;
  font-size: 1.25rem;
  outline: none;
}
.TitleInput {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
}
.urlInput input {
  height: 2rem;
  font-size: 1rem;
}
.urlContent {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  padding: 1rem;
}
.urlButton {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;
}
.urlButton button {
  font-size: 1rem;
  color: #fff;
  border: none;
  outline: none;
  background-color: #1a1e5d;
  padding: 5px 1rem;
  cursor: pointer;
  border-radius: 6px;
}
.ActivityHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.ActivityHeading p {
  font-size: 1.3rem;
  font-weight: 500;
  margin: 10px 0;
}

.ActivityHeadingIcons {
  font-size: 1.5rem;
  width: 6rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}
.ActivityHeadingIcons > span {
  margin-left: 10px; /* Add space between buttons */
}
.DeletePopup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1a1e5d;
  border: 3px solid #949393;
  border-radius: 20px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 8rem;
  z-index: 99;
}

.DeletePopupText p {
  font-size: 1.25rem;
  text-align: center;
  color: #fff;
  font-weight: 500;
  line-height: 25px;
}
.DeletePopupButton {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}
.DeletePopupButton button {
  margin: 0 0.5rem;
  padding: 0.5rem 1rem;
  background-color: transparent;
  border: 3px solid #fff;
  outline: none;
  width: 100%;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
}
.CommentHead p {
  margin: 0;
  padding: 4px 5px;
  font-weight: 600;
}
.CommentContent {
  padding: 5px;
  margin-top: 5px;
  border-radius: 10px;
}
.CommentButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px 0;
}
.CommentButton button {
  margin: 0 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #0e5dcb;
  outline: none;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
  border: none;
}
.CommentEditor {
  /* max-height: 10rem; */
  overflow-y: scroll;
}
.CommentEditor::-webkit-scrollbar {
  display: none;
}
.CommentList {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* height: 10rem; */
  padding: 4px 0;
}
.profileIcon {
  width: 3rem;
  min-width: 3rem;
  padding: 4px 0;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.profileIcon > span {
  border-radius: 50%;
  background-color: #0f8bea;
  color: #fff;
  padding: 0;
  width: 31px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CommentText {
  display: flex;
  flex-direction: column;
}
.CommentText > h5 {
  margin: 0;
}
.CommentText p {
  margin: 0;
}
.CommentDisplay {
  display: flex;
  flex-direction: column-reverse;
  padding: 8px 0;
  border-bottom: 1px solid;
}
.LoaderDiv {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 99999;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
}
.Loadertext p {
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  margin: 0;
}
.LoaderCircle {
  margin-top: 8px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #1a1e5d;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.previousAnswer {
  /* border: 1px solid #dbdbdb; */
  padding: 0px;
  border-radius: 6px;
  background-color: #fff;
}
.previousAnswerhead h4 {
  margin: 0;
  padding: 0 6px;
}
.previousAnswerhead {
  border-bottom: 1px solid;
}
.previousAnswer p {
  margin: 10px 0;
  padding: 0 6px;
}
.AnswerIcon > span {
  border-radius: 50%;
  background-color: #0f8bea;
  color: #fff;
  padding: 5px 10px;
}
.AnswerSend {
  display: flex;
  flex-direction: row;
  padding: 4px;
}
.AnswerReplay {
  display: flex;
  flex-direction: row;
  padding: 4px;
  padding-left: 1.5rem;
}
.AnswerText p {
  margin: 0;
}
.AnswerList {
  border-bottom: 1px solid #a8a7a7;
  padding: 3px 0;
}
.previousAnswer > h3 {
  padding: 0 4px;
  text-decoration: underline;
  margin: 0;
}
.previousAnswerContent {
  display: flex;
  flex-direction: column-reverse;
  margin: 9px 0;
}
.UploadLoaderDiv {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 99999;
  justify-content: center;
  /* align-items: center; */
  padding: 1rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #d0cece;
  border-radius: 5px;
}
.UploadLoaderContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.UploadLoaderText p {
  font-weight: 500;
  font-size: 1.1rem;
  margin: 0;
  padding: 6px;
}
.UploadLoaderContent p {
  font-weight: 500;
  font-size: 1.1rem;
  margin: 0;
  padding: 6px 1rem;
}
.validateLoaderDiv {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 99999;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 2rem;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #d0cece;
  border-radius: 5px;
}
.validateLoaderContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.validateLoaderText p {
  font-weight: 500;
  font-size: 1.1rem;
  margin: 0;
  padding: 6px;
}
.validateLoaderContent p {
  font-weight: 500;
  font-size: 1.1rem;
  margin: 0;
  padding: 6px 1rem;
}
.TopicDetailtext {
  display: flex;
  flex-direction: column;
}
.TopicDetailtext > span {
  text-decoration: underline;
  margin: 0;
  padding: 4px 0;
  font-size: 1.1rem;
  font-weight: 500;
}
.UploadText {
  position: absolute;
  top: -1.5rem;
  left: -1rem;
  display: none;
  font-size: 0.8rem;
  color: #1a1e5d;
  width: 5rem;
  font-weight: bold;
}
.FillQuestion {
  width: 90%;
}
.FillQuestion input {
  width: 30%;
  height: 1.25rem;
  border: none;
  border-bottom: 1px dotted;
  outline: none;
}
.ListDiv {
  margin: 0;
}
.ListDiv ul {
  margin: 0;
}
.DropContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.DropQuestions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 65%;
  padding-top: 1.5rem;
}
.DropAnswers {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35%;
}
.DropAnswers h5 {
  margin: 0;
  text-decoration: underline;
  font-size: 1rem;
}

.Card {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 14rem;
  margin: 8px 0;
  border-radius: 6px;
  border: 1px solid #dbdbdb;
  background-color: #e1dfdf;
  padding: 4px 0;
}
.Card p {
  margin: 0;
  padding: 4px;
}

.DraggablePlaceholder {
  border: 2px dashed #ccc;
  padding: 4px;
  text-align: center;
  color: #999;
  cursor: pointer;
  margin: 4px 0;
}
.DraggableItem {
  border: 2px solid #ccc;
  padding: 4px;
  cursor: pointer;
  margin: 4px 0;
}

.DropQuestions table {
  width: 100%;
  border-spacing: 0;
  /* border: 2px solid #a8b1b9; */
  border-collapse: separate;
  border-spacing: 0 8px;
}
.DropQuestions tbody tr {
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.DropQuestions td {
  padding: 0.1rem 0.2rem;

  font-size: 1rem;
  text-align: center;
}

.DropQuestions td:nth-child(1) {
  text-align: left;
}
.DropQuestions td:nth-child(2) {
  width: 13rem;
}
.AssesmentPopup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1a1e5d;
  border: 3px solid #949393;
  border-radius: 20px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  z-index: 99;
}
.AssesmentPopup p {
  text-align: center;
  color: #fff;
  text-align: center;
  font-size: 1.25rem;
  padding: 1rem;
}
.analyticsMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  margin: 1rem 0;
}
.analytics {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
  padding: 1rem;
  row-gap: 1rem;
}
.Graph{
  width: 100%;
  height: 60vh;
}
.assessmentCount {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 22rem;
  box-shadow: 0 3px 8px #0000003d;
}
.assessmentIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
}
.assessmentIcon svg {
  font-size: 1.75rem;
  color: slategray;
}
.assessmentText {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
}
.assessmentText span {
  font-size: 1.15rem;
  color: green;
  font-weight: 600;
}
.assessmentText p {
  margin: 0;
  font-size: 1.15rem;
  color: #3d0a24;
}
.ongoingTopic {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 22rem;
  box-shadow: 0 3px 8px #0000003d;
}
.ongoingTopic span {
  font-size: 1.5rem;
  font-weight: 600;
}
.topicLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.topicLeft p {
  margin: 0;
  font-size: 1.15rem;
  color: #3d0a24;
}
.topicRight {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.topicRight p {
  font-size: 1.15rem;
  color: green;
  font-weight: 600;
}
.topicText {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
}
.topicText span {
  font-size: 1.15rem;
  color: green;
  font-weight: 600;
}
.topicText p {
  margin: 0;
  font-size: 1.15rem;
  color: #3d0a24;
}
.assessmentSummaryCount{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 22rem;
  box-shadow: 0 3px 8px #0000003d;
}
.assessmentSummaryCount h4{
  margin: 4px 0;
  padding: 0;
  text-decoration: underline;
}
.TextContainer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

}
.LeftText{
  display: flex;
  align-items: center;
  justify-content: left;
  width: 50%;
  padding: 0 1rem;
}
.LeftText p{
  margin: 0;
  font-size: 1.15rem;
  color: #3d0a24;
  padding: 4px 0;
 
}
.RightText{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.RightText p{
  font-size: 1.15rem;
  color: green;
  padding: 4px 0;
  margin: 0;
}
.AssessmentSummary{
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 3px 8px #0000003d;
  margin: 1rem 0;
  align-items: center;
  justify-content: center;
  padding: 4px 0;
  flex-direction: column;
  cursor: pointer;
}
.AssessmentSummary h4{
  margin: 4px 0;
  padding: 0;
  text-decoration: underline;
  
}
.Mark{
  height: fit-content;
  padding: 0 2rem;
 margin-top: 7px;
 
}
.Marks{
  display: flex;
  flex-direction: column;
}
.Marks p{
  margin:1px 0;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 3px .75rem;
  color: #fff;
  
}
.Markdiv{
  width: fit-content;
  margin: 3px 0;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  color: #fff;
  font-size: 1.15rem;
  padding: 4px .5rem;
  
}
.FirstText{
  width: 9rem;
}
.SecondText{
  width: 4rem;
}
.DictationTabel {
  width: 80%;
}
.DictationTabel input{
  width: 90%;
  height: 2rem;
  padding: 4px;
  outline: none;
border-radius: 6px;
border: none;
background-color: transparent;
font-size: 1.15rem;
}
.DictationTabel td{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.DictationTabel tbody tr:hover {
  background-color: #ececec;
}
.speakerIcon{
  font-size: 1.5rem;
}
.WhiteBoradContainer{
  width: 60rem;
height: 36rem;
border-radius: 16px;
background-color: #fff;
position: fixed;
top: 19%;
right: 1%;
border: 1px solid #dbdbdb;
/* transform: translate(-50%, -50%); */
animation: slideIn 0.5s forwards;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
z-index: 99;
}
.closing {
  animation: slideOut 0.5s; /* Adjust the duration as needed */
}
@keyframes slideIn {
  from {
    right: -60rem; /* Off-screen initially */
  }
  to {
    right: 1rem; /* Final position */
  }
}
@keyframes slideOut {
  from {
    right: 1rem; /* Initial position when closing */
  }
  to {
    right: -60rem; /* Move off-screen to the right */
  }
}
.WhiteBoradHead{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
  padding: 0rem 1rem;
  margin: 0;
  border: none;
  border-radius:15px 15px 0 0 ;
}
.WhiteBoradIcon{
  cursor: pointer;
  font-size: 1.25rem;
  padding: 8px;
}
.WhiteBoradContent{
  height: 30rem;
  padding:.5rem 1rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


.WhiteBoardboticon {
  position: fixed;
  top: 50%;
 right: 0%;
  /* color: #fff;  */
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* animation: slideIn 0.5s forwards; */
}

.Iconhead {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: #000;
  font-weight: bold;
  font-size: 1.25rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
}
.icontext {
  padding: .5rem;
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  /* background-color: #e4c395; */
  border-radius: 6px;
  /* border: 1px solid; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:#1a1e5d;
  background-color: #fff;
}
.icontext p{
  margin: 0;
  padding: 0;
}
.WhiteboardFloatingIcon{
font-size: 1.5rem;
margin: 2px 0;
color:#1a1e5d;
}
.ChatImage {
  width: 11rem;
  /* border-radius: 50%; */
  /* border: 1px solid; */
  display: flex;
  align-items: center;
  /* background-color: #0f8bea; */
}
.ChatImage img {
  width: 100%;
}
.crosswordcontainer{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
}
.crosswordpuzzle {
  display: grid;
  grid-template-columns: repeat(10, 40px);
  /* grid-template-rows: repeat(10, 50px); */
  gap: 0;
 
  
}

.PuzzleQuestion{
  padding: 0 1rem;
  width: 100%;
}

.cell {
  width: 80%;
  height: 100%;
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
  border: 1px solid #000;
  padding:6px 4px; /* Remove padding */
  margin: 0; /* Remove margin */
  outline: none; /* Remove outline */
}
.clueNumber {
  position: absolute;
  top: 0;
  right: 0;
  font-size: smaller;
}
.QuestionAcross{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.QuestionAcrossInputContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.QuestionAcrossInputContainer input{
  font-family: "Poppins";
  width: 100%;
  font-size: 1.15rem;
  background-color: #fff;
  padding: 0.5rem 0.25rem;
  border-radius: 5px;
  margin: 0.5rem 0;
  border: 1px solid #bfaaaac1;
  outline: none;
  text-align: start;
}

.QuestionDown{
  display: flex;
  flex-direction: column;
}
.QuestionDownInputContainer{
  display: flex;
  flex-direction: column;
}
.QuestionDownInputContainer input{
  font-family: "Poppins";
  width: 100%;
  font-size: 1.15rem;
  background-color: #fff;
  padding: 0.5rem 0.25rem;
  border-radius: 5px;
  margin: 0.5rem 0;
  border: 1px solid #bfaaaac1;
  outline: none;
  text-align: start;
}
.cellContainer {
  position: relative;
}

.clueNumber {
  position: absolute;
  top: 2px;  /* Adjust this value to fine-tune the vertical position */
  right: 2px;  /* Adjust this value to fine-tune the horizontal position */
  font-size: 10px;  /* Adjust this value for the desired font size */
  color: red;  /* Adjust this value for the desired color */
}
.disabledInput {
  background-color: rgb(217, 215, 215);
  
}

.labSection{
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  margin: 1rem 0;
}
.labGrid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  padding: 1rem;
  row-gap: 1rem;
}
.labCard{
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
    0.3em 0.3em 1em #6d6d6d4d;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  padding: 1rem;
}
.labCardText{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 70%;
}
.labCardText p{
  font-size: 1rem;
  color: #000;
  padding: 6px;
  font-weight: 500;
}
.labCardLink{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 20%;
}
.labCardLink button{
  font-size: 1rem;
  color: #fff;
  padding: .5rem 1rem;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  border-radius: 6px;
  border: none;
  background-color: #1a1e5d;
}
.explanation{
  display: flex;
 flex-direction: column;
 justify-content: flex-start;
  padding: 4px ;
}
.explanation h4{
  padding: 2px;
  font-weight: 600;
  font-size: 1.2rem;
  color: coral;
}


@media screen and (max-width: 1500px) {
  .POPUP {
    top: 8%;
    right: 2rem;
    /* transform: translate(-50%, -50%); */
    border: 1px solid #dbdbdb;
    z-index: 99;
  }

  .AssesssmentSide {
    width: 24%;
    padding: 1rem 0.5rem;
    display: flex;
    flex-direction: column;
  }
  .Sidecard {
    /* width: 100%; */
    border: 1px solid;
    border-radius: 10px;
    padding: 1rem 0.5rem;
    color: #1a1e5d;
    background-color: #efefef;
  }
  .Grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 5px 0.5rem;
    row-gap: 1rem;
    column-gap: 0.75rem;
    margin-top: 2rem;
  }
  .topicDetal {
    height: 80vh;
  }
  .POPUP {
    width: 55rem;
    height: 35rem;
    border-radius: 16px;
    border-top: 1px solid #0f8bea;
    background-color: #fff;
    top: 8%;
    right: 1rem;
    /* transform: translate(-50%, -50%); */
    border: 1px solid #dbdbdb;
    z-index: 99;
  }
  .PopupContent {
    height: 23rem;
  }
}
@media screen and (max-width: 1300px){
  .DropContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  .DropQuestions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 60%;
    padding-top: 1.5rem;
  }
  .DropAnswers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
  }
  .DropAnswers h5 {
    margin: 0;
    text-decoration: underline;
    font-size: 1rem;
  }
  .Card {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 12rem;
    margin: 8px 0;
    border-radius: 6px;
    border: 1px solid #dbdbdb;
    background-color: #e1dfdf;
    padding: 4px 0;
  }
  .Card p {
    margin: 0;
    padding: 4px;
  }
  
  .DraggablePlaceholder {
    border: 2px dashed #ccc;
    padding: 4px;
    text-align: center;
    color: #999;
    cursor: pointer;
    margin: 4px 0;
  }
  .DraggableItem {
    border: 2px solid #ccc;
    padding: 4px;
    cursor: pointer;
    margin: 4px 0;
  }
  
  .DropQuestions table {
    width: 100%;
    border-spacing: 0;
    /* border: 2px solid #a8b1b9; */
    border-collapse: separate;
    border-spacing: 0 8px;
  }
  .DropQuestions tbody tr {
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .DropQuestions td {
    padding: 0.1rem 0.2rem;
  
    font-size: 1rem;
    text-align: center;
  }
  
  .DropQuestions td:nth-child(1) {
    text-align: left;
  }
  .DropQuestions td:nth-child(2) {
    width: 13rem;
  }    

}
@media screen and (max-width: 1025px) {
  .UploadPopUp {
    width: 80%;

    top: 50%;
    left: 52%;
  }
  .POPUP {
    width: 90%;
    height: 32rem;
    border-radius: 16px;
    border-top: 1px solid #0f8bea;
    background-color: #fff;
    top: 20%;
    right: 1rem;
    /* transform: translate(-50%, -50%); */
    border: 1px solid #dbdbdb;
    z-index: 99;
  }
  .PopupContent {
    height: 20rem;
  }
  .ActivityPopup {
    width: 90%;

    border-radius: 16px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }
  .CardHead {
    font-size: 1rem;
  }
  .analytics {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
    padding: 1rem;
    row-gap: 1rem;
  }
  .Graph{
    width: 100%;
    height: 60vh;
  }
  .DropContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .DropQuestions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    padding-top: 1.5rem;
  }
  .DropAnswers {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
  }
}
@media screen and (max-width: 768px) {
  .assesment {
    display: flex;
    padding: 0 0.5rem;
    flex-direction: column-reverse;
    height: 90vh;
    justify-content: space-between;
    overflow-y: scroll;
    z-index: 9;
    align-items: center;
  }

  .AssesssmentSide {
    width: 100%;
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;
  }
  .AssessmentHead {
    width: 97.5%;
    display: flex;
    flex-direction: column;
    border: 1px solid #cccccc;
    padding: 6px 2px;
    height: fit-content;
    margin: 2px 0;
  }
  .Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25px, 1fr));
    padding: 5px;
    row-gap: 1rem;
    column-gap: 0.5rem;
    margin-top: 0.5rem;
  }
  .ValidateButton {
    background-color: #38b020;
    font-size: 1rem;
    outline: none;
    border-radius: 7px;
    padding: 0.5rem;
    color: #ffffff;
    cursor: pointer;
    text-transform: uppercase;
    border: none;
    margin: 0 0.25rem;
  }
  .SkipButton {
    background: #dde1ef;
    font-size: 1rem;
    outline: none;
    border-radius: 7px;
    padding: 0.5rem;
    color: #56197e;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    border: 2px solid #6356be;
    margin: 0 0.25rem;
  }
  .FinishButton {
    background-color: #0f6bd4;
    font-size: 1rem;
    outline: none;
    border-radius: 7px;
    padding: 0.5rem;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    margin: 0 0.25rem;
    border: none;
  }
  .assessmentSuccessAnimation{
    width: 25rem;
  }
  .assessmentCount {
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 18rem;
    box-shadow: 0 3px 8px #0000003d;
  }
  .assessmentIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18%;
  }
  .assessmentIcon svg {
    font-size: 1.75rem;
    color: slategray;
  }
  .assessmentText {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 80%;
  }
  .assessmentText span {
    font-size: 1rem;
    color: green;
    font-weight: 600;
  }
  .assessmentText p {
    margin: 0;
    font-size: 1rem;
    color: #3d0a24;
  }
  .ongoingTopic {
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 18rem;
    box-shadow: 0 3px 8px #0000003d;
  }
  .ongoingTopic span {
    font-size: 1rem;
    font-weight: 600;
  }
  .topicLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
  }
  .topicLeft p {
    margin: 0;
    font-size: 1rem;
    color: #3d0a24;
  }
  .topicRight {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }
  .topicRight p {
    font-size: 1rem;
    color: green;
    font-weight: 600;
  }
  .topicText {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 80%;
  }
  .topicText span {
    font-size: 1rem;
    color: green;
    font-weight: 600;
  }
  .topicText p {
    margin: 0;
    font-size: 1rem;
    color: #3d0a24;
  }
}

@media screen and (max-width: 600px) {
  .topicHead {
    padding: 5px;
    display: flex;
    line-height: 0;
    height: fit-content;
    color: #fff;
    flex-direction: row;
    justify-content: left;
    align-items: center;
  }
  .LessonHead {
    background-color: rgb(178, 133, 0);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    padding: 6px 1rem;
  }
  .SecondHead {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 0 2px;
  }
  .Pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: fit-content;
    cursor: pointer;
    align-items: center;
  }
  .Pagination button {
    font-size: 1rem;
    border: none;
    outline: none;
    padding: 3px 8px;
    cursor: pointer;
    border-radius: 6px;
    align-items: center;
    display: flex;
    margin-left: 6px;
  }
  .imageView {
    width: 100%;
    height: auto;
  }
  .topicDetal {
    padding: 5px;
    /* height: 32rem; */
    background-color: #fff;
    border: 1px solid #cccccc;
    /* border-radius: 5px; */
    overflow-y: scroll;
  }
  .Container {
    padding: 0 0.5rem;
  }
  .UploadPopUp {
    width: 90%;

    top: 50%;
    left: 50%;
  }
  .HeadText p {
    padding: 0;
    font-weight: 600;
    margin: 8px 0;
    color: #fff;
    font-size: 1rem;
  }
  .analytics {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 1rem;
    padding: 1rem;
    row-gap: 1rem;
  }
  .Graph{
    width: 100%;
    height: 50vh;
  }
  .DropQuestions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 1.5rem;
  }
  .DropAnswers {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .topicHead {
    padding: 0.25rem;
    display: flex;
    line-height: 0;
    /* height: 50px; */
    color: #fff;
    flex-direction: row;
    justify-content: left;
    align-items: center;
  }
  .topicHead h3 {
    font-size: 1.5rem;
  }
  .topicDetal {
    /* height: 32rem; */
    background-color: #fff;
    border: 1px solid #cccccc;
    /* border-radius: 5px; */
    overflow-y: scroll;
  }
  .topicDetal p {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
  }
  .LessonHead {
    padding: 6px;
  }
  .LessonHead p {
    font-size: 1rem;
    color: #fff;
    font-weight: 600;
    margin: 0;
  }
  .HeaderIcon {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-size: 1rem;
    color: #1a1e5d;
    cursor: pointer;
    width: 5rem;
    /* background-color: yellow; */
  }
  .TopicDetailtext > span {
    text-decoration: underline;
    margin: 0;
    padding: 4px 0;
    font-size: 1rem;
    font-weight: 500;
  }
  .ActivityHeading p {
    font-size: 1rem;
    font-weight: 500;
    margin: 6px 0;
  }
  .CommentText p {
    font-size: 0.9rem;
  }
  .Practise {
    /* width: 100%; */
    padding: 0 0.5rem;
  }
  .UploadContent {
    /* width: 100%; */
    padding: 1rem;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: left;
  }
  .UploadContent label {
    font-size: 1rem;
    font-weight: 500;
    padding: 0 8px;
    cursor: pointer;
  }
  .UploadContent input {
    cursor: pointer;
    height: 1.5rem;
    width: 1rem;
  }
  .HeadText p {
    padding: 0;
    font-weight: 600;
    margin: 6px 0;
    color: #fff;
    font-size: 1rem;
  }
  .PopupIcon {
    cursor: pointer;
    color: #fff;
    font-size: 1rem;
  }
  .aigenerate {
    padding: 1rem;
  }
  .TitleInput input {
    width: 100%;
    height: 1.75rem;
    font-size: 1rem;
    outline: none;
  }
  .SaveButton {
    align-items: end;
    height: 2rem;
    margin-top: 0.25rem;
    display: flex;
    justify-content: flex-end;
  }
  .SaveButton button {
    font-size: 0.9rem;
    color: #fff;
    border: none;
    outline: none;
    background-color: #1a1e5d;
    padding: 7px 1rem;
    cursor: pointer;
    border-radius: 6px;
  }
  .UploadInput input {
    height: 2rem;
    font-size: 1rem;
    width: 100%;
  }
  .Button button {
    font-size: 0.9rem;
    color: #fff;
    border: none;
    outline: none;
    padding: 5px 1rem;
    cursor: pointer;
    border-radius: 6px;
    margin: 0.5rem 0;
  }
  .aiButton button {
    font-size: 0.9rem;
    color: #fff;
    border: none;
    outline: none;
    background-color: #1a1e5d;
    padding: 5px 1rem;
    cursor: pointer;
    /* margin-bottom: 1rem; */
    border-radius: 6px;
    /* margin: 2rem 0 ; */
  }
  .aiContent input {
    width: 100%;
    height: 2rem;
    font-size: 1rem;
  }
  .TopicButtons button {
    margin: 0 8px;
    font-size: 0.9rem;
    padding: 4px 8px;
    border-radius: 6px;
    border: 2px solid transparent;
    cursor: pointer;
  }

  .Input input {
    /* width: 100%; */
    height: 2rem;
    border: none;
    outline: none;
    font-size: 1rem;
  }
  .Icon {
    font-size: 2rem;
    color: #0f8bea;
    margin: 0 8px;
    cursor: pointer;
  }
  .CommentButton button {
    margin: 0 0.5rem;
    padding: 0.35rem 1rem;
    background-color: #1a1e5d;
    outline: none;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 600;
    border-radius: 6px;
    cursor: pointer;
    border: none;
  }
  .DeletePopup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #1a1e5d;
    border: 3px solid #949393;
    border-radius: 20px;
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 8rem;
    z-index: 99;
  }

  .DeletePopupText p {
    font-size: 1rem;
  }

  .DeletePopupButton button {
    margin: 0 0.5rem;
    padding: 0.35rem 1rem;
    background-color: transparent;
    border: 2px solid #fff;
    outline: none;
    width: 100%;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 600;
    border-radius: 6px;
    cursor: pointer;
  }
  .ValidateButton {
    background-color: #38b020;
    font-size: 0.9rem;
    outline: none;
    border-radius: 7px;
    padding: 0.5rem;
    color: #ffffff;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
    margin: 0 0.25rem;
  }
  .SkipButton {
    background: #dde1ef;
    font-size: 0.9rem;
    outline: none;
    border-radius: 7px;
    padding: 0.5rem;
    color: #56197e;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    border: 2px solid #6356be;
    margin: 0 0.25rem;
  }
  .FinishButton {
    background-color: #0f6bd4;
    font-size: 0.9rem;
    outline: none;
    border-radius: 7px;
    padding: 0.5rem;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    margin: 0 0.25rem;
    border: none;
  }
  .BottomIcons {
    display: flex;
    flex-direction: row;
    justify-content: right;
    font-size: 1rem;
    padding: 3px 9px;
    color: #1a1e5d;
  }
  .AddButton button:nth-child(3) {
    margin-right: 0;
    margin: 4px;
  }
  .assessmentSuccessAnimation{
    width: 15rem;
  }
  .assessmentFailAnimation{
    width: 6rem;
  }
  
}
