.loaderContainer{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  width: 100%;
  height: 100vh;
  background: #D9D9D980;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loader {
  width: 70px;
  aspect-ratio: 1;
  display: grid;
  border-radius: 50%;
  background:
    linear-gradient(0deg, rgba(89, 91, 219, 0.5) 25%, transparent 0 75%, rgba(89, 91, 219, 1) 0) 50%/4% 100%,
    linear-gradient(90deg, rgba(89, 91, 219, 0.25) 25%, transparent 0 75%, rgba(89, 91, 219, 0.75) 0) 50%/100% 4%;
  background-repeat: no-repeat;
  animation: l23 1.5s infinite steps(12);
  
}

.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}

.loader::after {
  opacity: 0.83;
  transform: rotate(60deg);
}

@keyframes l23 {
  100% {
    transform: rotate(1turn);
  }
}
@media screen and (max-width:768px) {
  .loaderContainer{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    width: 100%;
    height: 100%;
    background: #D9D9D980;
  }
}