.Container {
  width: 100%;
  height: 100vh;
  /* margin-left: auto;
  margin-right: auto; */
  overflow-y: scroll;
}
.Lessons {
  width: 98.4%;
  /* margin-left: auto;
  margin-right: auto; */
  padding: 10px 10px ;
  border: 1px solid;
  background-color: #e7e7f0;

}
.Container::-webkit-scrollbar{
    display: none;
}
.Content{
    width: 100%;
    height: 16rem;
    overflow-y: scroll;
    border:1px solid;
}
.Content p{
    font-size: 1rem;
    padding:0 1rem;
}
.Addbutton{
  font-size: 1.25rem;
  margin: 5px;
  background-color: firebrick;
  margin-left: 2rem;
  border: 1px solid;
  color: #fff;
  padding:2px 1rem ;
}
.Head {
    width: 100%;
    margin: 5px 0;
    border-radius: 10px;
    background-color: #3b82f6;
  }
  
  .Head p {
    text-align: left;
    margin: 0;
    font-family: drumshake_italic;
    font-size: 1.25rem;
    color: #fff;
    padding: 5px 10px;
  }
  .Revission{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .Refresh{
    position: absolute;
    right: 1rem;
    font-size: 1.5rem;
    margin-top: 7px;
  }
  .Checkbox{
    margin-left: 2rem;
  }
  .Checkbox input{
    width: 10%;
  }
  li{
    color: black;
  }
  .Progrctlists p{
    color: blue;
    text-decoration: underline;
  }
