.Container{
  height: 100vh;
  padding: 0 1rem;
  overflow-y: scroll;
}
.Container::-webkit-scrollbar{
    display: none;
}
.HistoryContent{
  padding: 4px 1rem;
}
.HistoryContent table{
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #bdbcbc;
}
td {
  text-align: center;
  padding: 7px;
}
.HistoryContent th,.HistoryContent td {
  border: 1px solid #bdbcbc; 
  text-align: center;
  padding: 7px;
  font-size: 1.1rem;
}
.HistoryContent th{
  background-color: #e4c395;
  color: #fff;
  border: none;
 }
 .HistoryContent caption{
  font-size: 1.5rem;
  font-weight: 500;
  padding: 2px 0;
 }
 .WhiteBoradContainer{
  width: 60rem;
height: 36rem;
border-radius: 16px;
background-color: #fff;
position: fixed;
top: 19%;
right: 1%;
border: 1px solid #dbdbdb;
/* transform: translate(-50%, -50%); */
animation: slideIn 0.5s forwards;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.closing {
  animation: slideOut 0.5s; /* Adjust the duration as needed */
}
@keyframes slideIn {
  from {
    right: -60rem; /* Off-screen initially */
  }
  to {
    right: 1rem; /* Final position */
  }
}
@keyframes slideOut {
  from {
    right: 1rem; /* Initial position when closing */
  }
  to {
    right: -60rem; /* Move off-screen to the right */
  }
}
.WhiteBoradHead{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
  padding: 0rem 1rem;
  margin: 0;
  border: none;
  border-radius:15px 15px 0 0 ;
}
.WhiteBoradIcon{
  cursor: pointer;
  font-size: 1.25rem;
  padding: 8px;
}
.WhiteBoradContent{
  height: 30rem;
  padding:.5rem 1rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


.chatboticon {
  position: fixed;
  top: 50%;
 right: 0%;
  /* color: #fff;  */
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* animation: slideIn 0.5s forwards; */
}

.Iconhead {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: #000;
  font-weight: bold;
  font-size: 1.25rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
}
.icontext {
  padding: .5rem;
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  /* background-color: #e4c395; */
  border-radius: 6px;
  /* border: 1px solid; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:#1a1e5d;
}
.icontext p{
  margin: 0;
  padding: 0;
}
.WhiteboardFloatingIcon{
font-size: 1.5rem;
margin: 2px 0;
color:#1a1e5d;
}
.ChatImage {
  width: 11rem;
  /* border-radius: 50%; */
  /* border: 1px solid; */
  display: flex;
  align-items: center;
  /* background-color: #0f8bea; */
}
.ChatImage img {
  width: 100%;
}
 @media only screen and (max-width: 768px) {
  .HistoryContent th,
  .HistoryContent td {
    font-size: 1.1rem;
  }
  .Container{
  padding: 0 1px;
}
}

@media only screen and (max-width: 600px) {
  .HistoryContent th,
  .HistoryContent td {
    font-size: 11px;
    padding: 5px 2px;
  }
}
@media only screen and (max-width: 400px) {
  .HistoryContent th,
  .HistoryContent td {
    font-size: 10px;
    padding: 5px 2px;
  }
}

