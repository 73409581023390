.Container {
    width: 100%;
    height: 90vh;
    perspective: 1000px; /* Enable 3D effects */
    padding: 1rem;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.Container::-webkit-scrollbar{
    display: none;
}

.Card {
    width: 50%;
    height: 300px;
    position: relative;
    transition: transform 0.5s;
    transform-style: preserve-3d;
    box-shadow: 0 3px 8px #0000003d;
}

.Front, .Back {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden; /* Hide the back side */
}

.Front {
    background-color: #ffffff;
}

.Back {
    background-color: lightblue; /* Change the background color of the back side as needed */
    transform: rotateX(180deg);
}

.Card.Flipped {
    transform: rotateX(180deg);
}
