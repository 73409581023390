.container {
  height: 100vh;
  overflow-y: scroll;
  background-color: #0b0f7705;
}

.container::-webkit-scrollbar {
  display: none;
}

.header {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  color: #131010;
  height: 6rem;
  padding-left: 4rem;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.headerText {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.headerText p {
  font-weight: 800;
  font-size: 1.35rem;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  color: darkblue;

}
.headerButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1rem;
}
.headerButton button {
  background: rgba(0, 0, 139, 0.123);
  font-size: 0.8rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: rgb(0, 0, 139);
  width: 130px;
  cursor: pointer;
  font-weight: 800;
  text-transform: uppercase;
  border: 2px solid rgb(0, 0, 139);
}

.headerButton button:hover{
  color: rgb(255, 255, 255);
  background:  rgb(0, 0, 139);

}

.eventCardsBox {
  padding: 4rem;
  display: flex;
  flex-wrap: wrap;
}

.eventCards {
  padding: 1rem;
}

.eventCards:hover {
  background-color:  rgba(0, 0, 139, 0.041);
  border-radius: 5px;
}

.eventTitle {
  padding-top: 10px;
}

.eventTitle h2 {
  color: #252555;
}

.host {
  color: #444443;
  display: flex;
  font-weight: 600;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  gap: 0.5rem;
}

.date {
  padding-top: 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  gap: 0.5rem;
}

.cardContainer {
  border: none;
  max-width: 345px;
  max-height: 435px;
}

.cardImage {
  border-radius: 10px;
}
.CardContent {
  padding: 0;
  width: 345px;
}

.CardActions {
  padding-top: 10px;
}
