.Conatiner {
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  transform: translateX(-100%);
  animation: slide-in 1s forwards;
}

@keyframes slide-in {
  to {
      transform: translateX(0);
  }
}

.MainBody {
  display: flex;
  flex-direction: column;
  margin-left: 155px;
  margin-top: 100px;
}

.bodyBox {
  display: flex;
  gap: 2rem;
}

.header p {
  font-size: 28px;
  font-weight: 300;
  line-height: 39.82px;
  text-align: left;
  margin: 0;
}

.header span {
  margin: 0;
  font-size: 30px;
  font-weight: 800;
  line-height: 39.82px;
  text-align: left;
  color: rgba(89, 91, 219, 1);
}

.header h3 {
  margin: 0;
  padding-top: 10px;
  font-size: 18px;
  font-weight: 100;
  line-height: 27.65px;
  text-align: left;
  color: rgba(63, 63, 63, 1);
}

.cardHeader p {
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 22px;
  font-weight: 500;
  line-height: 33.19px;
  text-align: left;
  color: rgba(63, 63, 63, 1);

}

.cardBody {
  display: flex;
  justify-content: center;
  font-size: 19.2px;
  font-weight: 500;
  line-height: 23.04px;
  gap: 3rem;
}

.cardBody p {
  margin: 0;
}

.colOne p {
  margin-top: 0.5rem;
}

.learning p {
  margin-top: -0.5rem;
}

.milestone p {
  margin-top: -0.7rem;
}

.lessons,
.assessment,
.milestone,
.learning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 250px;
  height: 110px;
  gap: 0px;
  border-radius: 36px;
  margin-bottom: 3rem;
  font-size: 16px;
  font-weight: 500;
  color: rgba(63, 63, 63, 1);
}

.milestoneImg {
  margin-top: -1rem;
}

.content {
  padding: 2rem;
}

.boxDialogue {
  border-radius: 40px;
  background: rgba(89, 91, 219, 0.11);
  margin-left: 6rem;
  margin-right: 5rem;
}

.content p {
  margin: 0;
  font-size: 17px;
  font-weight: 300;
  text-align: left;
  padding-bottom: 8px;
  color: rgba(0, 0, 0, 1);
  font-family: "Urbane Extra";
  line-height: 32px;
}

.cmntImage {
  height: 100%;
  margin-left: 8rem;

}

.cmntImage img {
  width: 425px;
  height: 345px;
}

.commentBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content span {
  margin: 0;
  font-family: 'Urbane Demi';
  font-size: 28px;
  font-weight: 600;
  line-height: 27.65px;
  text-align: left;
  color: rgba(89, 91, 219, 1);
}

.startButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.startButton button {
  padding: 1rem 8rem 1rem 8rem;
  font-size: 20px;
  font-weight: 500;
  line-height: 27.65px;
  text-align: left;
  border: none;
  border-radius: 20px;
  background: rgba(89, 91, 219, 1);
  color: white;
  cursor: pointer;
  font-family: Urbane;
  font-size: 23.04px;
  font-weight: 500;
  line-height: 27.65px;
  text-align: center;
  margin-bottom: .5rem;

}
@media screen and (max-width:768px) {
  .bodyBox {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .MainBody {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 1rem;
  }
  .startButton button {
    padding: 1rem 2rem;
    border: none;
    border-radius: 20px;
    background: rgba(89, 91, 219, 1);
    color: white;
    cursor: pointer;
    font-family: Urbane;
    font-size: 18px;
    font-weight: 500;
    line-height: 27.65px;
    text-align: center;
    margin-bottom: .5rem;
  
  }
  .cmntImage {
    height: 100%;
    margin-left: 0;
    width: 80%;
  
  }
  .cardBody {
    display: flex;
    justify-content: center;
    font-size: 19.2px;
    font-weight: 500;
    line-height: 23.04px;
    gap: 2rem;
    flex-wrap: wrap;
    /* flex-direction: column;
    align-items: center; */
  }
  .cardHeader p {
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 33.19px;
    text-align: left;
    color: rgba(63, 63, 63, 1);
    word-break: break-all;
  }
  .boxDialogue {
    border-radius: 40px;
    background: rgba(89, 91, 219, 0.11);
    margin: 0;
  }
  .commentBox {
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-content: center;
  }
  .cmntImage img {
    width: 100%;
    height: 100%;
  }
}