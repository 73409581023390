.Container{
    width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-start;
  padding: 0 1rem;
  overflow-y: scroll;
}
.Container::-webkit-scrollbar{
    display: none;
}
.Header{
    width: 100%;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    flex-direction: row;

}
.Search{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 4px 7px;
    font-size: 1.25rem;
    border: 1px solid;
    background-color: white;

}
.Search input{
    width: 100%;
    height: 2rem;
    border: none;
    font-size: 1.25rem;
    padding: 0 8px;
}
.Filter{
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    font-size: 1.25rem;
}
.Filter p{
    margin: 0;
    padding: 0 2px;
}
.Message{
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
}
.First{
    width: 40%;
    height: 80vh;
    padding:0 4px;
    border: 1px solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.Second{
    width: 53%;
    height: 75vh;
    border: 1px solid;
    padding: 1rem;
}
.Card{
    margin-top: 8px;
    height: 7rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #dbdbdb;
    box-shadow: 2px 2px #888888;
    border-radius: 1rem;
    width: 95%;
}
.name{
    width: 3rem;
    padding: 0 8px;
}
.name p{
    background-color: cornflowerblue;
    border-radius: 50%;
   padding: 6px 0;
    margin: 0;
    text-align: center;
    align-items: center;
}
.Details{
    width: 90%;
    padding: 0 1rem;
}
.Details p{
    font-weight: 500;
}
.Time{
    width: 6rem;
    font-weight: 500;
}
.Sub{
    color: #4f58e2;
}
.Heading{
    height: 3rem;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.NameIcon{
    width: 10%;
    align-items: center;
    display: flex;
    justify-content: center;
}
.NameText{
    background-color: #2181f7;
    border-radius: 50%;
    padding: 7px 8px;
}
.NameDetails{
    width: 85%;
    align-items: center;
}
.NameDetails p{
    margin: 6px;
    font-weight: 500;
}
.Content{
    height: 65vh;
    margin-top: 5px;
    padding:5px 1rem;
}