.Container{
    height: 100vh;
  overflow-y: scroll;
  padding: 0 1rem;
}
.Container::-webkit-scrollbar{
  display: none;
}
.Header {
    display: flex;
    justify-content: space-between;
    background-color: #a29f9f;
    align-items: center;
    color: #fff;
    height: 3rem;
    border-radius: 10px;
    margin: 1rem 0;
  }
  .HeaderText {
    width: 80%;
    display: flex;
    align-items: center;
    padding: 1rem;
    justify-content: flex-start;
  }
  .HeaderText p {
    margin: 0;
    font-size: 1.5rem;
  }
  .HeadButton {
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;
  }
  .HeadButton button {
    background: #49618b;
    font-size: 1rem;
    outline: none;
    border-radius: 7px;
    border: none;
    padding: 0.5rem;
    color: #fff;
    width: 115px;
    cursor: pointer;
  }
  .table {
    width: 100%;
    border-spacing: 0;
  }
  .table tr {
    text-align: center;
  }
  .table th {
    background-color: #542b47;
    width: 10rem;
    height: 3rem;
    color: #fff;
    border: 1px solid #4a4450;
    font-size: 1.25rem;
  }
  .table td {
    height: 3rem;
    border: 1px solid #a08ea4;
    color: #2f6f72;
  }
  .Buttons {
    display: flex;
    justify-content: space-evenly;
    /* align-items: center; */
    width: 100%;
    font-size: 1.5rem;
  }
  .iconContainer {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .tooltipText {
    visibility: hidden;
    width: 80px;
    background-color: #857c7c;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* padding: 5px; */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 1rem;
  }
  
  .iconContainer:hover .tooltipText {
    visibility: visible;
    opacity: 1;
  }
  
  .editButton {
    color: #0076de;
  }
  .deleteButton {
    color: red;
  }
  .memberCreate {
    width: 50rem;
    position: absolute;
    top: 50%;
    left: 55%;
    z-index: 99999;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #fff;
    border-radius: 1rem;
    padding: .5rem 1rem;
    align-items: center;
    max-height: 35rem;
  }
  .memberClose {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    font-size: 1.5rem;
  }
  .membersection {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: .5rem 0;
    /* align-items: center; */
    justify-content: center;
    /* height: 70%; */
  }
  .membersection input {
    font-family: "Poppins";
    width: 90%;
    font-size: 1rem;
    padding: 0.4rem 0;
    text-align: center;
    border-radius: 5px;
    margin: 0.3rem 0;
    border: 3px solid #696969c1;
    outline: none;
    color: #3e4486;
  }
  .membersection input::-webkit-input-placeholder {
    text-align: center;
    text-transform: uppercase;
    font-size: 1rem;
    color: #a7a6a6;
  }
  .createButton {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    /* height: 30%; */
    padding:.5rem 1rem;
  }
  .create {
    width: 10rem;
    border-radius: 6px;
    background-color: #49618b;
    border: none;
    outline: none;
    font-size: 1.25rem;
    color: #fff;
    padding: 0.5rem;
  }
  .cancel {
    width: 10rem;
    border-radius: 6px;
    background-color: #7e3e3e;
    border: none;
    outline: none;
    font-size: 1.25rem;
    color: #fff;
    padding: 0.5rem;
  }
  .Overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    background-color:rgba(4, 4, 4, 0.793);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
  }
.InputContainer{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.LabelContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
  
}
.InputTable{
    width: 100%;
    border-spacing: 0;
  }
   .InputTable input{
    width: 90%;
    border: none;
   } 
   .InputTable tr {
    text-align: center;
  }
  .InputTable th {
    background-color: #542b47;
    height: 2rem;
    color: #fff;
    font-size: 1rem;
  }
  .InputTable td{
    border: 1px solid #b3b1b1;
  }
  .Receipt{
    width: 25rem;
    position: absolute;
    top: 50%;
    left: 55%;
    z-index: 99999;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #fff;
    border-radius: 1rem;
    padding: .5rem 1rem;
    max-height: 35rem;
    border: 3px solid #7c7c7c;
  }
  .ReceiptDate{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .ReceiptHead p{
    margin: 0;
    text-align: center;
    font-weight: 700;
    padding: 4px 0;
    text-decoration: underline;
  }
  .bodyText{
    display: flex;
    align-items: center;
  }
  .FirstText{
    width: 8rem;
    font-weight: 500;
    padding: 2px 0;
  }
  .SecondText{
    padding: 2px 10px;
  }
  .Buttons button{
    outline: none;
    padding: 6px 10px;
    border-radius: 6px;
    background-color: #0076de;
    border: none;
    color: #fff;
    font-size: 1rem;
  }
  .WhiteBoradContainer{
    width: 60rem;
height: 36rem;
border-radius: 16px;
background-color: #fff;
position: fixed;
top: 19%;
right: 1%;
border: 1px solid #dbdbdb;
/* transform: translate(-50%, -50%); */
animation: slideIn 0.5s forwards;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.closing {
    animation: slideOut 0.5s; /* Adjust the duration as needed */
  }
  @keyframes slideIn {
    from {
      right: -60rem; /* Off-screen initially */
    }
    to {
      right: 1rem; /* Final position */
    }
  }
  @keyframes slideOut {
    from {
      right: 1rem; /* Initial position when closing */
    }
    to {
      right: -60rem; /* Move off-screen to the right */
    }
  }
  .WhiteBoradHead{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid;
    padding: 0rem 1rem;
    margin: 0;
    border: none;
    border-radius:15px 15px 0 0 ;
  }
  .WhiteBoradIcon{
    cursor: pointer;
    font-size: 1.25rem;
    padding: 8px;
  }
  .WhiteBoradContent{
    height: 30rem;
    padding:.5rem 1rem;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  

  .chatboticon {
    position: fixed;
    top: 50%;
   right: 0%;
    /* color: #fff;  */
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* animation: slideIn 0.5s forwards; */
  }
 
  .Iconhead {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: #000;
    font-weight: bold;
    font-size: 1.25rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
  }
  .icontext {
    padding: .5rem;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    /* background-color: #e4c395; */
    border-radius: 6px;
    /* border: 1px solid; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:#1a1e5d;
    background-color: #fff;
  }
  .icontext p{
    margin: 0;
    padding: 0;
  }
  .WhiteboardFloatingIcon{
  font-size: 1.5rem;
  margin: 2px 0;
  color:#1a1e5d;
  }
  .ChatImage {
    width: 11rem;
    /* border-radius: 50%; */
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    /* background-color: #0f8bea; */
  }
  .ChatImage img {
    width: 100%;
  }