@media (max-width: 480px) {
  .container {
    width: 100%;
    height: 100vh;
  }
  .insightPage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 1rem;
    background: #e2e2e2;
    height: 100%;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0.5rem;
    background: #7e9dee;
    padding: 0.5rem;
    border-radius: 5px;
  }
  .header p {
    font-size: 1.25rem;
    color: #071439;
    font-weight: 600;
  }
  .insightDetail {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    /* width: 100%; */
    height: 100%;
    margin: 0.5rem;
  }
  .insightGrid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 1rem;
    /* padding: 1rem; */
    row-gap: 1rem;
  }
  .card {
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 22rem;
    box-shadow: 0 3px 8px #0000003d;
    background: #fff;
  }
  .card span {
    font-size: 1.35rem;
    font-weight: 600;
  }
  .cardLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
  }
  .cardLeft svg {
    font-size: 1.75rem;
    color: slategray;
  }
  .cardRight {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 80%;
  }
  .cardRight p {
    font-size: 1.25rem;
    color: #3d0a24;
    font-weight: 500;
  }
  .cardRight span {
    font-size: 1.35rem;
    color: green;
    font-weight: 600;
  }
  .ongoingTopicLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }
  .ongoingTopicLeft p {
    font-size: 1.25rem;
    color: #3d0a24;
    font-weight: 500;
  }
  .ongoingTopicRight {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }
  .ongoingTopicRight p {
    font-size: 1.35rem;
    color: green;
    font-weight: 600;
  }
  .Graph {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 45%;
    background: #fff;
    margin: 1.25rem 0;
    box-shadow: 0 3px 8px #0000003d;
  }
  .graphData {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .container {
    width: 100%;
    height: 100vh;
  }
  .insightPage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 1rem;
    background: #e2e2e2;
    height: 100%;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0.5rem;
    background: #7e9dee;
    padding: 0.5rem;
    border-radius: 5px;
  }
  .header p {
    font-size: 1.25rem;
    color: #071439;
    font-weight: 600;
  }
  .insightDetail {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content:space-around;
    width: 100%;
    height: 100%;
    margin: 0.5rem;
  }
  .insightGrid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
    width: 100%;
  }
  .card {
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 22rem; */
    box-shadow: 0 3px 8px #0000003d;
    background: #fff;
  }
  .card span {
    font-size: 1.35rem;
    font-weight: 600;
  }
  .cardLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
  }
  .cardLeft svg {
    font-size: 2rem;
    color: slategray;
  }
  .cardRight {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 80%;
  }
  .cardRight p {
    font-size: 2rem;
    color: #3d0a24;
    font-weight: 500;
  }
  .cardRight span {
    font-size: 2rem;
    color: green;
    font-weight: 600;
  }
  .ongoingTopicLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }
  .ongoingTopicLeft p {
    font-size: 2rem;
    color: #3d0a24;
    font-weight: 500;
  }
  .ongoingTopicRight {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }
  .ongoingTopicRight p {
    font-size: 2rem;
    color: green;
    font-weight: 600;
  }
  .Graph {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 45%;
    background: #fff;
    margin: 1.25rem 0;
    box-shadow: 0 3px 8px #0000003d;
  }
  .graphData {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .container {
    width: 100%;
    height: 100vh;
  }
  .insightPage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 1rem;
    background: #e2e2e2;
    height: 100%;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0.75rem;
    background: #7e9dee;
    padding: 0.75rem;
    border-radius: 5px;
  }
  .header p {
    font-size: 2rem;
    color: #071439;
    font-weight: 600;
  }
  .insightDetail {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;
  }
  .insightGrid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
    width: 100%;
  }
  .card {
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 3px 8px #0000003d;
    background: #fff;
  }
  .card span {
    font-size: 2rem;
    font-weight: 600;
  }
  .cardLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
  }
  .cardLeft svg {
    font-size: 2rem;
    color: slategray;
  }
  .cardRight {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 80%;
  }
  .cardRight p {
    font-size: 1.75rem;
    color: #3d0a24;
    font-weight: 500;
  }
  .cardRight span {
    font-size: 1.75rem;
    color: green;
    font-weight: 600;
  }
  .ongoingTopicLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }
  .ongoingTopicLeft p {
    font-size: 1.75rem;
    color: #3d0a24;
    font-weight: 500;
  }
  .ongoingTopicRight {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }
  .ongoingTopicRight p {
    font-size: 1.75rem;
    color: green;
    font-weight: 600;
  }
  .Graph {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 45%;
    background: #fff;
    margin: 1.25rem 0;
    box-shadow: 0 3px 8px #0000003d;
  }
  .graphData {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
