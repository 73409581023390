.Container {
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  transform: translateX(-100%);
  animation: slide-in 1s forwards;
}

@keyframes slide-in {
  to {
      transform: translateX(0);
  }
}

.mainBody {
  margin-left: 155px;
  margin-top: 6rem;
}

.user p {
  font-size: 22px;
  font-weight: 300;
  line-height: 33.19px;
  text-align: left;
  font-family: Urbane Extra;
  padding-bottom: 1rem;
}

.user span {
  font-size: 30px;
  font-weight: 600;
  line-height: 39.82px;
  text-align: left;
  color: rgba(89, 91, 219, 1);
  font-family: Urbane Demi;
}

.course p {
  font-size: 18px;
  font-weight: 500;
  line-height: 27.65px;
  text-align: left;
  padding: 3rem 0 3rem 0;
  font-family: Urbane;
}

.cardContainer {
  display: flex;
  gap: 8rem;
}

.courseCard {
  width: 50%;
  gap: 0px;
  border-radius: 20px 0px 0px 0px;
  border: 3px solid rgba(89, 91, 219, 1);
  border-radius: 20px;
  height: 20%;
}

.CardHeader {
  background-color: rgba(89, 91, 219, 0.15);
  padding: 1.6rem;
  border-radius: 20px;
}

.CardHeader p {
  font-size: 23.04px;
  font-weight: 300;
  line-height: 27.65px;
  margin: 0;
}

.slogan {
  padding: 0 2rem;
  padding-left: 5.5rem;

}
.slogan p{
  font-family: Urbane Extra;
  color: #3F3F3F;
  font-size: 1.2rem;
}

.package {
  margin: 0;
  padding: 0;
}

.package p {
  margin: 0;
  font-size: 54px;
  font-weight: 600;
  line-height: 81.61px;
  text-align: left;
  color: rgba(89, 91, 219, 0.8);
  padding: 0rem 1rem 1rem 1rem;
  font-family: Urbane;
}

.package span {
  font-size: 40px;
  font-weight: 600;
  line-height: 57.35px;
  text-align: left;
  color: #595BDB;
  font-family: Urbane;
}

.CardBody {
  padding: 1rem 2rem;
}

.CardBody p {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color:#3F3F3F;
  margin: 0;
  font-family: Urbane;
}
.pointsHeader{
  padding: 1rem 0;
}

.points {
  display: flex;
  align-items: center;
  padding: 5px;
}

.points span {
  font-size: 18px;
  font-style: italic;
  font-weight: 800;
  line-height: 28.8px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  font-family: Urbane;
}

.points p {
  font-size: 18px;
  font-weight: 500;
  line-height: 28.8px;
  text-align: left;
  color: rgba(63, 63, 63, 1);
  font-family: Urbane;
}

.points img {
  width: 22px;
  padding: 0 1rem 0 1rem;
}

.subscribeCard {
  width: 50%;
}

.subscribeCardBox {
  padding: 0 7rem 6rem 0;
}

.subscribeCardHead {
  display: flex;
  justify-content: space-between;
}

.amount {
  text-align: right;
  width: 30%;
}
.summary{
  width: 70%;
}

.summaryTitle {
  font-size: 28px;
  font-weight: 500;
  line-height: 39.82px;
  margin: 0;
  font-family: Urbane ;
}

.summarySpan {
  font-size: 21px;
  font-weight: 500;
  line-height: 33.19px;
  color:#3F3F3F;
  padding: 2rem 0 0 0;
  margin: 0;
}

.amountTitle {
  font-size: 28px;
  font-weight: 500;
  line-height: 39.82px;
  margin: 0;
  font-family: Urbane ;
}

.amountSpan {
  font-size: 21px;
  font-weight: 500;
  line-height: 33.19px;
  color:#3F3F3F;
  padding: 2rem 0 0 0;
  margin: 0;
}

.declare {
  padding-top: 2rem;
  padding-bottom: 1rem;

}

.declare input {
  height: 1rem;
  width: 1rem;
  margin-right: 1rem;
}

.declare label {
  display: flex;
  align-items: center;
  color: #3F3F3F;
  font-family: Urbane Extra;
}

.declare p {
  font-size: 17px;
  font-weight: 500;
  line-height: 23.04px;
  text-align: left;
}
.declare a {
  font-size: 17px;
  font-weight: 600;
  line-height: 23.04px;
  text-align: left;
  color: #595BDB;
  text-decoration: none;
}

.SubscriptionBtn{
  padding-bottom: 1rem;
}

.SubscriptionBtn button {
  padding: 1rem;
  margin-top: 0rem;
  border: none;
  border-radius: 25px;
  background: rgba(89, 91, 219, 1);
  font-size: 22px;
  font-weight: 600;
  line-height: 31.21px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  width: 100%;
  font-family: Urbane Demi;
}

.separator {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.separator span {
  flex: 1;
  text-align: center;
  position: relative;
  font-weight: 500;
  font-size: 15px;
}

.separator span::before,
.separator span::after {
  content: "";
  height: 1px;
  background: rgba(95, 95, 95, 1);
  position: absolute;
  top: 50%;
  width: calc(50% - 20px);
}

.separator span::before {
  left: 0;
  margin-right: 10px;
}

.separator span::after {
  right: 0;
  margin-left: 10px;
}

.startTrail button {
  padding: 1rem;
  margin-top: 0rem;
  border: none;
  border-radius: 25px;
  background: rgba(255, 190, 0, 1);
  width: 576px;
  font-size: 22px;
  font-weight: 600;
  line-height: 31.21px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  width: 100%;
  font-family: Urbane Demi;
}

.startTrail p {
  padding-bottom: 1rem;
  font-size: 17px;
  font-weight: 500;
  line-height: 23.04px;
  text-align: center;
  margin: 0;
  color: #3F3F3F;
  font-family: Urbane Extra;
}
.discountSection{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: .5rem;
}
.discountSection button{
  padding: 1rem;
  margin: 0.3rem;
  border-radius: 25px;
  background: rgb(255, 255, 255);
  font-size: 18px;
  font-weight: 400;
  line-height: 31.21px;
  cursor: pointer;
  width: 100%;
  font-family: Urbane;
  border: 1px solid #03CD09;
  color: #3E3D42;
  margin-top: .75rem;
}
.discountSection button span{
  color: #34A520;
  font-size: 20px;
  font-weight: 600;
}
.discountAmount{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: .5rem;
  padding: .5rem 0;
}
.discountAmountContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #D0D0D0;
}
.discountAmountLeft{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: .5rem 0;

}
.discountAmountLeft p{
  font-family: Urbane;
font-size: 18px;
font-weight: 500;
line-height: 27.6px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #000;
}
.discountAmountLeft h4{
  font-family: Urbane;
font-size: 24px;
font-weight: 500;
line-height: 36.01px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #000;
}
.discountAmountRight{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: .5rem 0;
}
.discountAmountRight p{
  font-family: Urbane;
  font-size: 22px;
  font-weight: 500;
  line-height: 33.19px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #3F3F3F;  
}
@media screen and (max-width:768px) {
  .mainBody {
    margin-left: 0;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
  }
  
  .cardContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .courseCard {
    width: 90%;
    gap: 0px;
    border-radius: 20px 0px 0px 0px;
    border: 3px solid rgba(89, 91, 219, 1);
    border-radius: 20px;
    height:fit-content;
  }
  .subscribeCard {
    width: 90%;
  }
  .subscribeCardBox {
    padding: 0 ;
  }
  .package p {
    margin: 0;
    font-size: 42px;
    padding: 0rem 1rem 1rem 1rem;
  }
  .package span {
    font-size: 30px;
  }
  
}
@media screen and (max-width:480px) {
  .courseCard {
    width: 100%;
  }
  .subscribeCard {
    width: 100%;
  }
  .package p {
    margin: 0;
    font-size: 32px;
    padding: 0rem ;
    line-height: 21.61px;
  }
  .package span {
    font-size: 20px;
  }
  .slogan {
    padding: 0 2rem;
    padding-left: 2rem;
  
  }
  .points p {
    font-size: 16px;
  }
  .points span {
    font-size: 16px;
  }
}