.Container {
    height: 100vh;
    padding: 0 1rem;
    /* background: linear-gradient(#fff,#dbdbdb); */
    overflow-y: scroll;
  }
  .Container::-webkit-scrollbar{
    display: none;
  }

.Header{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: .5rem;
    border-bottom: 2px solid #b1b1b1;
  }
  .HeaderText{
    /* width: 100%; */
  }
  .HeaderText p{
    font-size: 1.25rem;
    margin: 0;
    color:#1a1e5d ;
    font-weight: 500;
  }
  .HeaderIcons{
    width: 100%;
    text-align: right;
    color:#1a1e5d ;
    font-size: 1.5rem;
  }
  .Answer{
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    height: 67vh;
    padding:1px 2rem;
    overflow-y: scroll;
  }
  
  .QuestionCount{
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .QuestionCount span{
    background-color: yellow;
    padding: 5px;
  }
  .Question{
    margin-top: 1rem;
    padding: 0;
  }
  .Question p{
    font-size: 1.1rem;
    margin: 0;
  }
  .AnswerCard{
    border: 1px solid #dbdbdb;
    padding: 1rem;
    margin-top: 5px;
  }
  .CorrectAnswer{
    height: fit-content;
    padding: 8px;
    background-color: #48f8a0;
    font-size: 15px;
  }
  .Mark{
    height: fit-content;
    padding: 0 3rem;
   margin-top: 7px;
   
  }
  .Marks{
    display: flex;
    flex-direction: column;
  }
  .Marks p{
    margin:1px 0;
    font-size: 1.2rem;
    font-weight: 500;
    padding: 3px .75rem;
    color: #fff;
    
  }
  .HeaderButton{
   width: fit-content;
   padding: 6px 0;
  }
  .HeaderButton button{
    padding: 1px 7px;
    border-radius: 4px;
    background-color: #1a1e5d;
    border: none;
    outline: none;
    height: 2rem;
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
  }
  .Markdiv{
    width: fit-content;
    margin: 3px 0;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    color: #fff;
    font-size: 1.24rem;
    padding: 4px 1rem;
  }
  .FirstText{
    width: 10rem;
  }
  .SecondText{
    width: 3rem;
  }
  @media only screen and (max-width: 499px) {
    .HeaderText p{
      font-size: 1.2rem;
    }
    .HeaderButton button{
      padding: 1px 7px;
      height: 2rem;
      font-size: .9rem;
    }
    .Mark{
      height: fit-content;
      padding: 0 .5rem;
     margin-top: 7px;
     
    }
    .Marks{
      display: flex;
      flex-direction: column;
    }
    .Marks p{
      margin:1px 0;
      font-size: 1rem;
      font-weight: 500;
      padding: 3px .75rem;
      color: #fff;
      
    }
    .Answer{
      margin-top: 4px;
      padding:1px .5rem;
      
    }
    .Container {
      padding: 0 4px;
    }
    .Markdiv{
      
      font-size: 1rem;
      padding: 4px .5rem;
    }
    .Header {
      flex-direction: column;
      align-items: stretch;
    }
  }
  .QuestionContent{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .DropContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .DropQuestions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 65%;
    padding-top: 1.5rem;
  }
  .DropAnswers {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;
  }
  .DropAnswers h5 {
    margin: 0;
    text-decoration: underline;
    font-size: 1rem;
  }
  
  .Card {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 14rem;
    margin: 8px 0;
    border-radius: 6px;
    border: 1px solid #dbdbdb;
    background-color: #e1dfdf;
    padding: 4px 0;
  }
  .Card p {
    margin: 0;
    padding: 4px;
  }
  
  .DraggablePlaceholder {
    border: 2px dashed #ccc;
    padding: 4px;
    text-align: center;
    color: #999;
    cursor: pointer;
    margin: 4px 0;
  }
  .DraggableItem {
    border: 2px solid #ccc;
    padding: 4px;
    cursor: pointer;
    margin: 4px 0;
  }
  
  .DropQuestions table {
    width: 100%;
    border-spacing: 0;
    /* border: 2px solid #a8b1b9; */
    border-collapse: separate;
    border-spacing: 0 8px;
  }
  .DropQuestions tbody tr {
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .DropQuestions td {
    padding: 0.1rem 0.2rem;
  
    font-size: 1rem;
    text-align: center;
  }
  
  .DropQuestions td:nth-child(1) {
    text-align: left;
  }
  .DropQuestions td:nth-child(2) {
    width: 13rem;
  }