.Container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-start;
  /* padding: 0 1rem; */
  overflow-y: scroll;
}
.MyLearning {
  width: 100%;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;

  /* border: 1px solid; */
}
.Container::-webkit-scrollbar {
  display: none;
}
.Cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 75%;
  height: 100%;
  position: absolute;
}
.cardContent {
  border: 1px solid black;
  height: 20rem;
  /* width: 40%; */
  border-radius: 10px;
  padding: 1rem;
  background-color: aliceblue;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}
.close {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 95%;
  font-size: 1.25rem;
  /* margin: 0.75rem 0; */
  position: absolute;
  top: 20px;
}
.cardCategories {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  height: 60%;
  padding: 3rem 0;
}

.Title {
  display: flex;
  padding: 1rem;
  background: #b7b7d3;
  line-height: 0;
  height: 40px;
  color: #fff;
  align-items: center;
}
.Title h4 {
  font-size: 1.75rem;
}
.LearningHead {
  display: flex;
  margin: 1rem;
  align-items: center;
  justify-content: space-between;
}
.Searchinput {
  width: 70%;
  display: flex;
}
.Searchinput input {
  width: 90%;
  height: 2rem;
  border: none;
  border-bottom: 1px solid;
  outline: none;
  font-size: 1.25rem;
}
.SearchIcon {
  font-size: 1.5rem;
  position: absolute;
  right: 2rem;
}
.Grid {
  width: 100%;
  height: auto;
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* margin: 0 auto; */
}
.status {
  /* color: green; */
}
.NewLearning button {
  color: #fff;
  border: none;
  background: #1d1dce;
  width: 125px;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 1rem;
}
.learningGrid {
  border: 1px solid;
  width: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  margin: 0.25rem;
}
.Goal {
  border: 1px solid black;
  /* height: 20rem; */
  width: 40%;
  border-radius: 10px;
  padding: 1rem;
  background-color: aliceblue;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}
.goalHead {
  font-size: 1.25rem;
  border-bottom: 1px solid;
  width: 100%;
  line-height: 0;
}
.goalInput {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.25rem 0;
  align-items: center;
}
.goalInput p {
  font-size: 1.25rem;
}
.goalInput input {
  width: 80%;
}
.goalInput textarea {
  width: 80%;
}
.goalInput select {
  width: 80%;
  height: 25px;
  width: 80%;
  height: 32px;
  font-size: 1rem;
  outline: none;
}
.goalButton {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  margin: 1rem 0;
}
.goalButton button {
  width: 100px;
  height: 40px;
  background: #1a1a5b;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  padding: 0.5rem;
}
.topic {
  flex-direction: column;
  display: flex;
  width: 30%;
  border: 1px solid;
  border-radius: 10px;
  padding: 1rem;
  align-items: flex-start;
  background-color: aliceblue;
}
.topicHead {
  border-bottom: 1px solid;
  width: 100%;
  line-height: 0;
}
.topicHead h4 {
  font-size: 1.25rem;
}
.topicDetails {
  width: 100%;
  margin: 0.5rem 0;
  line-height: 0;
}
.topicDetails p {
  font-size: 1.25rem;
  border-bottom: 1px solid #46d5df;
  line-height: 2;
}
.learningButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 0;
}
.learningButton button {
  background: #262673;
  outline: none;
  border: none;
  color: #fff;
  width: 130px;
  height: 35px;
  font-size: 1rem;
  border-radius: 6px;
  /* padding: 0.5rem; */
  cursor: pointer;
  margin: 0 6px;
}
.saveCard {
  display: flex;
  flex-direction: column;
  width: 65%;
  align-items: flex-start;
  border: 1px solid;
  padding: 1rem;
  border-radius: 10px;
  margin: 1rem 0;
}
.saveHead {
  border-bottom: 1px solid rgb(13, 13, 15);
  width: 100%;
  line-height: 0;
}
.saveHead h4 {
  font-size: 1.25rem;
}
.saveList {
  line-height: 1;
}
.saveList h5 {
  font-size: 1.25rem;
}
.saveList p {
  font-size: 1.25rem;
}
.saveedCardsDisplay {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  width: 95%;
  height: auto;
  margin: 2rem;
}
.AddressSection {
  width: 25rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.AddressSection form {
  background-color: #efefef;
  padding: 2rem 2rem;
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 99;
}
.AddressSection form input {
  width: 100%;
  font-size: 1rem;
  background-color: #fff;
  padding: 5px 1px;
  text-align: center;
  border-radius: 5px;
  margin: 10px 0;
  border: 3px solid #696969c1;
}
.AddressSection form input:focus {
  border-color: transparent;
}
.AddressSection form {
  align-items: center;
}
.AddressSection form button {
  width: 70%;
  border-radius: 50px;
  background-color: #3b82f6;
  border: none;
  outline: none;
  height: 2rem;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  margin-top: 1rem;
}
.AddressSection form input::-webkit-input-placeholder {
  text-align: center;
  text-transform: uppercase;
}
.Formtext {
  width: 100%;
}
.Formtext p {
  margin: 4px 1rem;
  font-size: 1.2rem;
}
.Overlay {
  background-color: #3e3e3e1a;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 99;
}
.session {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1a1e5d;
  border: 3px solid #949393;
  border-radius: 20px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 25rem;
  height: 10rem;
}
.session_close {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  color: #fff;
  font-size: 1.25rem;
}
.session_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50%;
  color: #fff;
  font-size: 1.25rem;
}
.session_content p {
  /* margin: 0; */
}
.session_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.session_button button {
  border: 2px solid #fff;
  background: transparent;
  color: #fff;
  width: 75px;
  height: 40px;
  font-size: 1rem;
  padding: 0.5rem;
  border-radius: 1rem;
}

.LearningDiv {
  /* width: 100%; */
  border: 2px solid #dbdbdb;
  margin: 1rem;
  padding: 1rem 1rem;
  background: linear-gradient(#fff, #dbdbdb);
  height: auto;
}
.Head {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  align-items: center;
}
.label {
  color: #1a1e5d;
  font-weight: 500;
  margin: 6px 0;
}
.Input {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  border: 2px solid #dbdbdb;
  padding: 4px 0;
  background-color: #fff;
  justify-content: center;
}
.Input input {
  width: 100%;
  font-size: 1.2rem;
  height: 1.75rem;
  border: none;
  color: #1a1e5d;
  outline: none;
  background-color: transparent;
}
.Icons {
  font-size: 1.5rem;
  padding-right: 1px;
  color: #1a1e5d;
}
.Grid {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 0.5rem;
  column-gap: 3rem;
  row-gap: 1rem;
}
.Card {
  background-color: #fff;
  /* height: 17rem; */
  /* padding:1rem 2rem; */
  border: 1px solid #dbdbdb;
  border-radius: 1rem;
  cursor: pointer;
}
.Cardhead {
  /* width: 100%; */
  background: #dde1ef;
  color: #56197e;
  border-radius: 1rem 1rem 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
}
.Cardhead p {
  font-size: 1rem;
  /* color: #fff; */
  font-weight: 500;
  margin: 0;
  padding: 3px 0;
  padding-left: 4px;
  text-transform: uppercase;
}
.cardData {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.cardData p {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  padding: 8px 0;
}
.lesson {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.lesson p {
  margin: 0;
  padding: 0;
}
.roundGraph {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 10rem;
}
.flashingButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flashing {
  padding: 0.5rem;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  background: linear-gradient(45deg, #3498db, #2ecc71);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background 0.3s, color 0.3s, transform 0.3s;
  animation: heartbeat 1.5s ease-in-out infinite;
}

.flashing:hover {
  background: linear-gradient(45deg, #2ecc71, #27ae60);
  color: #ffffff;
  transform: scale(1.05);
}
@keyframes heartbeat {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
.mainContent {
  /* width: 60%; */
  display: flex;
  flex-direction: column;
}
.mainContent p {
  font-weight: 700;
}
.SearchGrid {
}
.SearchCard {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  background-color: #fff;
  height: fit-content;
  /* padding:1rem 2rem; */
  border: 1px solid #dbdbdb;
  border-radius: 1rem;
  cursor: pointer;
}
.SearchCardHeading {
  background-color: #e4c395;
  border-radius: 1rem 1rem 0 0;
  padding: 4px;
}
.SearchCardHeading p {
  text-align: center;
  padding: 0;
  margin: 6px 0;
  font-size: 1.2rem;
  color: #fff;
  font-weight: 500;
}
.SearchCardDes {
  min-height: 8rem;
}
.SearchCardDes p {
  /* text-align: center; */
  padding: 0 10px;
  margin: 0;
  font-size: 1.1rem;
}
.SearchCardButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
}
.SearchCardButton button {
  background: #262673;
  outline: none;
  border: none;
  color: #fff;
  /* width: 150px;
  height: 35px; */
  font-size: 1rem;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  cursor: pointer;
}
.SearchCardMain {
  width: 100%;
  height: auto;
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* margin: 0 auto; */
  row-gap: 1rem;
  column-gap: 1rem;
}
.PriceDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  background-color: #ededed;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  width: 38rem;
  z-index: 999;
}
.PriceTable {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.PriceTable table {
  width: 100%;
  border-collapse: collapse;
  /* box-shadow: 2px 5px #d3d3d155; */
  border-spacing: 0;
}
td {
  text-align: center;
}
.PriceTable th,
.PriceTable td {
  text-align: center;
}
.PriceTable td {
  border-right: 1px solid #dbdbdb;
}
.PriceTable th {
  color: #fff;
  border: none;
}
.PriceAmount {
  margin: 0;
  font-size: 1.5rem;
}
.PriceText {
  margin: 0;
  font-size: 1.1rem;
  padding: 4px;
}
.PriceMonth {
  font-size: 14px;
}
.TableButton {
  border: none;
  outline: none;
  font-size: 1.2rem;
  color: #fff;
  padding: 6px 10px;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
}
.ButtonRow > td {
  padding: 0;
  border: none;
}
.TableHeadTextfirst {
  margin: 0;
  color: black;
  font-size: 1.75rem;
}
.TableHeadTextsecond {
  margin: 0;
  color: #9b9a99;
  font-size: 1.25rem;
}
.CardHeadButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 0.4rem;
}
.CardHeadButton button {
  background: #bb382f;
  outline: none;
  border: none;
  color: #fff;
  font-size: 0.9rem;
  border-radius: 6px;
  padding: 4px 6px;
  cursor: pointer;
  margin-left: 3px;
}
.PriceDivOverlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: rgba(4, 4, 4, 0.6);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}
.CardHeadButton > span {
  background-color: rgb(72, 152, 237);
  padding: 2px 10px;
  color: #fff;
  border-radius: 6px;
  cursor: default;
}
.CardheadText {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.Lessondiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.FirstText {
  font-size: 0.9rem;
  font-weight: 700;
  padding: 8px 0;
}
.SecondText {
  font-size: 0.9rem;
  font-weight: 500;
  padding: 8px 0;
}
.FirstContent {
  font-size: 0.9rem;
  font-weight: 700;
  padding: 8px 0;
  width: 7.65rem;
}
.SecondContent {
  font-size: 0.9rem;
  font-weight: 500;
  padding: 2px 0;
}
.leaderBoard {
  align-items: center;
  background: #101079;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  left: 55%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40rem;
  border: 5px solid #62a7c5;
  z-index: 99999;
  padding: 0 0.75rem 0.75rem 0.75rem;
  height: 50vh;
}
.leaderBoard::-webkit-scrollbar {
  display: none;
}
.boardHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  border: 5px solid #3047b6;
  border-radius: 2rem;
  margin-top: -30px;
  background: #101079;
  color: #fff;
  padding: 0.75rem;
}
.boardHeader h3 {
  margin: 0;
  font-size: 1.5rem;
  background: linear-gradient(to right, #737edd, #04c1ff);
  -webkit-background-clip: text;
  color: transparent;
}
.boardSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 0.75rem 0;
  height: 100%;
  overflow-y: scroll;
}
.boardSection::-webkit-scrollbar {
  display: none;
}
.boardValues {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  margin: 0.5rem 0;
}
.valueBadge {
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.valueBadge p {
  font-size: 1.35rem;
  color: #fff;
  margin: 0;
  font-weight: 800;
}
.valueBadge img {
  width: 100%;
  height: 100%;
}
.valueAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.valueAvatar svg {
  font-size: 3rem;
  color: #ffff;
}
.playerNameStar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 50%;
  background: linear-gradient(to right, #3d7dd4, #3720c9);
  border-radius: 1rem;
  padding: 0.75rem 0.5rem;
}
.playerNameStar p {
  font-size: 1.25rem;
  color: #fff;
  font-weight: 700;
  margin: 0;
}
.WhiteBoradContainer{
  width: 60rem;
height: 36rem;
border-radius: 16px;
background-color: #fff;
position: fixed;
top: 19%;
right: 1%;
border: 1px solid #dbdbdb;
/* transform: translate(-50%, -50%); */
animation: slideIn 0.5s forwards;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
z-index: 99;
}
.closing {
  animation: slideOut 0.5s; /* Adjust the duration as needed */
}
@keyframes slideIn {
  from {
    right: -60rem; /* Off-screen initially */
  }
  to {
    right: 1rem; /* Final position */
  }
}
@keyframes slideOut {
  from {
    right: 1rem; /* Initial position when closing */
  }
  to {
    right: -60rem; /* Move off-screen to the right */
  }
}
.WhiteBoradHead{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
  padding: 0rem 1rem;
  margin: 0;
  border: none;
  border-radius:15px 15px 0 0 ;
}
.WhiteBoradIcon{
  cursor: pointer;
  font-size: 1.25rem;
  padding: 8px;
}
.WhiteBoradContent{
  height: 30rem;
  padding:.5rem 1rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


.chatboticon {
  position: fixed;
  top: 50%;
 right: 0%;
  /* color: #fff;  */
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* animation: slideIn 0.5s forwards; */
  z-index: 99;
}

.Iconhead {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: #000;
  font-weight: bold;
  font-size: 1.25rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
}
.icontext {
  padding: .5rem;
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  /* background-color: #e4c395; */
  border-radius: 6px;
  /* border: 1px solid; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:#1a1e5d;
  background-color: #fff;
}
.icontext p{
  margin: 0;
  padding: 0;
}
.WhiteboardFloatingIcon{
font-size: 1.5rem;
margin: 2px 0;
color:#1a1e5d;
}
.ChatImage {
  width: 11rem;
  /* border-radius: 50%; */
  /* border: 1px solid; */
  display: flex;
  align-items: center;
  /* background-color: #0f8bea; */
}
.ChatImage img {
  width: 100%;
}
@media screen and (max-width: 1325px) {
  .cardData {
    padding: 0.5rem;
  }
  .Grid {
    column-gap: 1rem;
    row-gap: 1rem;
  }
  .LearningDiv {
    /* width: 100%; */
    padding: 1rem 0.5rem;
  }
  .SearchCardMain {
    width: 100%;
    height: auto;
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* margin: 0 auto; */
    row-gap: 1rem;
    column-gap: 1rem;
  }
}
@media screen and (max-width: 1240px) {
  .FirstContent {
    padding: 8px 0;
    width: 5.5rem;
  }
  .SecondContent {
    font-size: 0.9rem;
    font-weight: 500;
    padding: 2px 0;
    width: fit-content;
  }
}
@media screen and (max-width: 1130px) {
  .FirstContent {
    padding: 8px 0;
    width: fit-content;
  }
  .SecondContent {
    font-size: 0.9rem;
    font-weight: 500;
    padding: 2px 0;
    width: fit-content;
  }
  .roundgraph {
    width: 40%;
    right: 0;
  }
  .SearchCardDes {
    height: 10rem;
  }
}
@media screen and (max-width: 1024px) {
  .FirstText {
    font-size: 0.9rem;
    font-weight: 700;
    padding: 8px 0;
  }
  .SecondText {
    font-size: 0.9rem;
    font-weight: 500;
    padding: 8px 0;
  }
  .FirstContent {
    font-size: 0.9rem;
    font-weight: 700;
    padding: 8px 0;
    width: 7.65rem;
  }
  .SecondContent {
    font-size: 0.9rem;
    font-weight: 500;
    padding: 2px 0;
  }
  .SearchCard {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    background-color: #fff;
    height: fit-content;
    /* padding:1rem 2rem; */
    border: 1px solid #dbdbdb;
    border-radius: 1rem;
    cursor: pointer;
  }
}
@media screen and (max-width: 850px) {
  .Grid {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 0.5rem;
    column-gap: 3rem;
    row-gap: 1rem;
  }
  .roundgraph {
    width: 11rem;
    right: 0;
  }
  .SearchCardHeading p {
    text-align: center;
    padding: 0;
    margin: 6px 0;
    font-size: 1rem;
    color: #fff;
    font-weight: 500;
  }
}
@media screen and (max-width: 768px) {
  .Head {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 6px;
  }
  .Input {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    border: 2px solid #dbdbdb;
    padding: 4px 0;
    background-color: #fff;
    justify-content: center;
  }
  .Input input {
    /* width: 100%; */
    font-size: 1.2rem;
    height: 1.75rem;
    border: none;
    color: #1a1e5d;
    outline: none;
    background-color: transparent;
  }
  .Icons {
    font-size: 1.25rem;
    padding-right: 1px;
    color: #1a1e5d;
  }
}
@media screen and (max-width: 700px) {
  .SearchCardMain {
    width: 100%;
    height: auto;
    position: relative;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* margin: 0 auto; */
    row-gap: 1rem;
    column-gap: 1rem;
  }
  .PriceDiv {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    background-color: #ededed;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    width: 90%;
    z-index: 999;
  }
  .PriceTable {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .PriceTable table {
    width: 100%;
    border-collapse: collapse;
    /* box-shadow: 2px 5px #d3d3d155; */
    border-spacing: 0;
  }
  td {
    text-align: center;
  }
  .PriceTable th,
  .PriceTable td {
    text-align: center;
  }
  .PriceTable td {
    border-right: 1px solid #dbdbdb;
  }
  .PriceTable th {
    color: #fff;
    border: none;
  }
  .PriceAmount {
    margin: 0;
    font-size: 1.25rem;
  }
  .PriceText {
    margin: 0;
    font-size: 1.1rem;
    padding: 2px;
  }
  .PriceMonth {
    font-size: 14px;
  }
  .TableButton {
    border: none;
    outline: none;
    font-size: 1rem;
    color: #fff;
    padding: 6px 10px;
    margin: 0;
    background-color: transparent;
    cursor: pointer;
  }
  .ButtonRow > td {
    padding: 0;
    border: none;
  }
  .TableHeadTextfirst {
    margin: 0;
    color: black;
    font-size: 1.75rem;
  }
  .TableHeadTextsecond {
    margin: 0;
    color: #9b9a99;
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 560px) {
  .cardData {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5rem 1rem;
  }
  .Card {
    background-color: #fff;
    height: fit-content;
    border: 1px solid #dbdbdb;
    border-radius: 1rem;
    cursor: pointer;
    width: 98%;
  }
  .roundgraph {
    text-align: center;
    width: 10rem;
    right: 0;
  }
  .Cardhead {
    /* width: 100%; */
    background-color: #dbb073;
    border-radius: 1rem 1rem 0 0;
    display: flex;
    justify-content: space-between;
    align-items: unset;
    flex-direction: column;
    padding: 4px;
  }
  .CardheadText {
    display: flex;
    flex-direction: row;
    align-items: start;
  }
  .CardheadText p {
    margin: 0;
  }
  .CardHeadButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 0.4rem;
  }
  .learningButton button {
    width: fit-content;
    height: fit-content;
    font-size: 0.9rem;
    padding: 5px 8px;
    margin: 0 4px;
  }
  .Input input {
    /* width: 100%; */
    font-size: 0.9rem;
    height: 1.5rem;
    border: none;
    color: #1a1e5d;
    outline: none;
    background-color: transparent;
  }
  .PriceAmount {
    margin: 0;
    font-size: 1rem;
  }
  .PriceText {
    margin: 0;
    font-size: 0.9rem;
    padding: 2px;
  }
  .PriceMonth {
    font-size: 12px;
  }
  .TableButton {
    border: none;
    outline: none;
    font-size: 0.9rem;
    color: #fff;
    padding: 6px 5px;
    margin: 0;
    background-color: transparent;
    cursor: pointer;
  }
  .ButtonRow > td {
    padding: 0;
    border: none;
  }
  .TableHeadTextfirst {
    margin: 0;
    color: black;
    font-size: 1.15rem;
  }
  .TableHeadTextsecond {
    margin: 0;
    color: #9b9a99;
    font-size: 1rem;
  }
  .PriceDiv {
    padding: 6px;
  }
  .PriceTable td {
    border-right: 1px solid #dbdbdb;
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 400px) {
  .PriceTable th,
  .PriceTable td {
    text-align: center;
  }
  .PriceTable td {
    border-right: 1px solid #dbdbdb;
    font-size: 0.8rem;
  }
  .PriceTable th {
    color: #fff;
    border: none;
  }
  .PriceAmount {
    margin: 0;
    font-size: 0.8rem;
  }
  .PriceText {
    margin: 0;
    font-size: 0.8rem;
    padding: 2px;
  }
  .PriceMonth {
    font-size: 11px;
  }
  .TableButton {
    border: none;
    outline: none;
    font-size: 0.8rem;
    color: #fff;
    padding: 4px 0px;
    margin: 0;
    background-color: transparent;
    cursor: pointer;
  }
  .ButtonRow > td {
    padding: 0;
    border: none;
  }
  .TableHeadTextfirst {
    margin: 0;
    color: black;
    font-size: 1.15rem;
  }
  .TableHeadTextsecond {
    margin: 0;
    color: #9b9a99;
    font-size: 1rem;
  }
  .PriceDiv {
    padding: 6px;
  }
}
