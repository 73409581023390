.container {
  height: calc(100vh - 17vh);
  overflow-y: auto;
  padding: 0 2rem;
}

.container::-webkit-scrollbar {
  width: 10px;
}

.container::-webkit-scrollbar-track {
  background: #d9d9d9;
  border-radius: 10px;
}

.container::-webkit-scrollbar-thumb {
  background: #8c8c8c;
  border-radius: 10px;
}

.container::-webkit-scrollbar-thumb:hover {
  background: #8c8c8c;
}

.learningSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 93%;
}

.learningHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.learningHeader h3 {
  font-family: Urbane;
  font-size: 24px;
  font-weight: 500;
  line-height: 39.82px;
  text-align: left;
  color: #595bdb;
  margin: 0;
}

.learningHeader p {
  margin: 0;
  font-family: Urbane ;
  font-size: 14px;
  font-weight: 300;
  line-height: 30px;
  text-align: left;
  color: #5f5f5f;
  padding: 10px 0;
}
.learningHeader span{
  color: #595bdb;
}

.graphSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: fit-content;
  gap: 1rem;
}

.graphSectionLeft {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #e9eaff7a;
  border-radius: 35px;
  width: 47%;
  padding: 1rem;
}

.graphSectionLeftTop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.5rem 0;
  gap: 1rem;
}
.tab {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #d9d9d9;
  cursor: pointer;
}

.selectedTab {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #595bdb;
}

.graphSectionRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #e9eaff7a;
  border-radius: 35px;
  width: 46%;
  padding: 1rem;
}

.leaderBoardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.leaderBoardHeader p {
  margin: 0;
  padding: 6px 1rem;
  color: #595bdb;
  font-family: Urbane;
  font-size: 18px;
  font-weight: 500;
  line-height: 27.65px;
  text-align: left;
}

.leaderBoardBody {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  padding: 1rem;
  height: 38vh;
}

.leaderBoardBody::-webkit-scrollbar {
  width: 10px;
}

.leaderBoardBody::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
}

.leaderBoardBody::-webkit-scrollbar-thumb {
  background: #595bdb;
  border-radius: 10px;
}

.leaderBoardBody::-webkit-scrollbar-thumb:hover {
  background: #595bdb;
}

.leaderBoardCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.75rem 2rem;
  background-color: #b1b2ee;
  border-radius: 2rem;
  margin: 0.5rem 0;
}

.leaderCardLeft {
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
}

.leaderCardRight {
  width: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.leaderCardRight img {
  width: 100%;
}

.leaderCardLeftTop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.leaderName {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 70%;
}

.leaderName p {
  margin: 0;
  font-family: Urbane;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #3f3f3f;
}

.leaderPoint {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30%;
}

.leaderPoint p {
  margin: 0;
  font-family: Urbane;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  text-align: left;
  color: #3f3f3f;
}

.leaderCardLeftBottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.leaderProgressBAr {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 70%;
}

.leaderAssessment {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30%;
}

.leaderAssessment p {
  font-family: Urbane;
  font-size: 10px;
  font-weight: 500;
  line-height: 13.33px;
  text-align: left;
  color: #3f3f3f;
}

.moduleSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.moduleSectionHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.moduleSectionHeader p {
  font-family: Urbane;
  font-size: 22px;
  font-weight: 500;
  line-height: 39.82px;
  text-align: left;
  color: #595bdb;
  margin: 0;
  padding: 0.5rem 0;
}

.moduleSectionBody {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.5rem 0;
}

.moduleCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 20px;
  box-shadow: 0px 4px 5px 0px #00000040;
  padding: 1.25rem 2rem;
  margin: 0.5rem 0;
  border: 1px solid #0000001c;
}
.complete {
  background-color: #00dfc7;
}

.inProgress {
  background-color: #ffb563;
}

.notStarted {
  background-color: #d9d9d9;
}
.disabledCard {
  background-color: #d4d4d4;
  opacity: 0.5;
  pointer-events: none;
}

.moduleCardTop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.moduleCardName {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.moduleCardName p {
  margin: 0;
  font-family: Urbane;
  font-size: 18px;
  font-weight: 500;
  line-height: 28.8px;
  text-align: left;
  color: #5f5f5f;
}

.moduleCount {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.moduleCountColor {
  background: #d9d9d9;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 0.35rem;
}

.moduleCardBottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px 0;
}

.moduleCardBottomLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.moduleCardBottomLeft p {
  font-family: Urbane;
  font-size: 22px;
  font-weight: 500;
  line-height: 39.82px;
  text-align: left;
  margin: 0;
  color: #595bdb;
}

.moduleCardBottomRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.moduleCardBottomRight svg {
  font-size: 1.35rem;
  color: #5f5f5f;
  padding: 0.5rem;
  cursor: pointer;
  padding-top: 1rem;
}

.topicContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem 0;
}

.topicCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 1rem;
  border-radius: 53px;
  margin: 0.35rem 0;
  cursor: pointer;
}

.topicCard p {
  font-family: Urbane Extra;
  font-size: 14px;
  font-weight: 300;
  line-height: 27.65px;
  text-align: left;
  color: #3f3f3f;
  margin: 0;
}
.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.info p {
  color: #5f5f5f;
  font-family: Urbane;
  font-size: 1rem;
}
.graphData {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 1rem;
}
.graphDataHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.graphDataHeader p {
  font-family: Urbane;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  margin: 0;
  color: #5f5f5f;
}
.graphDataSectionRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.graphDataSubHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  height: 6vh;
  gap: 0.5rem;
}
.graphDataSubHeaderLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  border-radius: 4px;
  background-color: #d9d9d9;
}
.subHeaderCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.35rem;
  width: 3rem;
  border-right: 0.4px solid #89889061;
}
.subHeaderCard:last-child {
  border: none;
}
.subHeaderCard p {
  font-family: Urbane;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  text-align: left;
  margin: 0;
  color: #000;
}
.selectedSubHeaderCard {
  background-color: #000;
  border: none;
  border-radius: 4px;
}
.selectedSubHeaderCard p {
  color: #ffffff;
}
.graphDataSubHeaderRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 1rem;
}
.graphDataSubHeaderRight select {
  border: 1px solid #878787;
  border-radius: 23px;
  padding: 0.2rem 0.75rem;
  max-width: 150px;
}
.barGraph {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.levelHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
}
.levelHeaderCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
}
.levelHeaderCard h4 {
  font-family: Urbane;
  font-size: 11px;
  font-weight: 500;
  line-height: 13.2px;
  text-align: left;
  color: #000;
  white-space: nowrap;
}
.levelHeaderCard p {
  font-family: Urbane;
  font-size: 8px;
  font-weight: 300;
  line-height: 9.6px;
  text-align: left;
  margin: 0;
  color: #3e3d42;
}
.levelHeaderCard span {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #ffbe00;
}
.levelHeaderCard:last-child span {
  background-color: #595bdb;
}
.statusSection{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem 0;
  gap: 3rem;
  flex-wrap: wrap;
}
.statusCard{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: .5rem;
}
.statusCard p{
  font-family: Urbane Extra;
font-size: 14px;
font-weight: 300;
line-height: 23.2px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #000;
}
.statusCard span{
  width: 4rem;
  height: 1.75rem;
  background-color: #FFCD64;
  border-radius: 8px;
}
.progressButton{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}
.progressButton button{
  outline: none;
  border: none;
  font-family: Urbane;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
  border-radius: 35px;
  background-color: #595BDB;
  width: 30%;
  padding: .7rem;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .graphDataSubHeaderRight select {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .container {
    height: 100%;
    padding: 0 0;
  }
  .learningHeader {
    padding: 0 1rem;
  }
  .learningHeader h3 {
    font-size: 24px;
    line-height: 1.5;
  }
  .learningHeader p {
    font-size: 1rem;
  }

  
  .graphSection {
    padding: 0 1.5rem 0 1rem;
  }
  .graphDataHeader p {
    font-size: 1rem;
  }
  .moduleSection {
    padding: 0 1.5rem 0 1rem;
  }
  .moduleSectionHeader p {
    font-size: 1.5rem;
    line-height: 1.5;
  }
  .leaderBoardHeader p {
    font-size: 1.125rem;
  }
  .leaderAssessment p {
    font-size: 0.625rem;
  }
  .leaderBoardCard {
    padding: 0.75rem 1rem;
  }
  .leaderPoint p,
  .leaderName p {
    font-size: 0.75rem;
  }
  .moduleCardName p {
    font-size: 1.1875rem;
  }
  .moduleCardBottomLeft p {
    font-size: 1.5rem;
  }
  .topicCard p {
    font-size: 1rem;
  }
  .statusSection{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem 0;
    gap: 3rem;
    flex-wrap: wrap;
    padding: 0 1rem;
  }
}

@media (max-width: 768px) {
  .learningHeader h3 {
    font-size: 20px;
  }
  .learningHeader p {
    font-size: 0.875rem;
    line-height: 1.75rem;
  }
  .graphSection {
    flex-direction: column;
    align-items: center;
    padding: 0 2.5rem 0 2rem;
  }
  .graphSectionRight,
  .graphSectionLeft {
    width: 100%;
  }

  .graphDataHeader p {
    font-size: 0.875rem;
  }
  .moduleSectionHeader p {
    font-size: 1.25rem;
  }
  .leaderBoardHeader p {
    font-size: 1rem;
  }
  .leaderAssessment p {
    font-size: 0.5625rem;
  }
  .leaderBoardBody {
    padding: 1rem 0.5rem;
  }
  .leaderPoint p,
  .leaderName p {
    font-size: 0.8125rem;
  }
  .moduleCardName p {
    font-size: 1.125rem;
  }
  .moduleCardBottomLeft p {
    font-size: 1.375rem;
  }

  .topicCard p {
    font-size: 0.9375rem;
  }
  .learningSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 97%;
  }
  .progressButton button{
    outline: none;
    border: none;
    font-family: Urbane;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #fff;
    border-radius: 35px;
    background-color: #595BDB;
    width: fit-content;
    padding: .7rem 1rem;
    cursor: pointer;
  }
}

@media (max-width: 480px) {
  .learningHeader h3 {
    font-size: 18px;
  }
  .learningHeader p {
    font-size: 0.75rem;
  }

  .moduleSectionHeader p {
    font-size: 1.125rem;
  }

  .graphDataHeader p {
    font-size: 0.75rem;
  }

  .graphData {
    padding: 0;
  }
  .leaderBoardHeader p {
    font-size: 0.875rem;
  }
  .leaderAssessment p {
    font-size: 0.5rem;
  }
  .leaderBoardCard {
    padding: 0.75rem 1.1rem;
  }
  .leaderPoint p,
  .leaderName p {
    font-size: 0.75rem;
  }
  .moduleCardName p {
    font-size: 1rem;
  }
  .moduleCardBottomLeft p {
    font-size: 18px;
  }
  .moduleCountColor {
    height: 15px;
    width: 15px;
    margin: 0 0.2rem;
  }
  .topicCard p {
    font-size: 0.875rem;
  }
  .moduleCardTop {
   flex-wrap: wrap;
  }
  .moduleCard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 20px;
    box-shadow: 0px 4px 5px 0px #00000040;
    padding: 1rem;
    margin: 0.5rem 0;
    border: 1px solid #0000001c;
  }
  .statusSection{
    padding: 2rem 0;
    gap: 3rem;
    flex-wrap: wrap;
    padding:  1rem;
  }
}
