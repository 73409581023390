.container {
   position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
    z-index: 9;
    background: #D9D9D980;

}

.banner {
    position: fixed;
    bottom: 0;
    z-index: 999;
    background: #595BDB;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 300px;
    width: 100%;
    border-radius: 60px 60px 0 0;
}

.bannerLeft {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 10%;
    position: relative;
}

.cookieImageOne {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 40px;
    position: absolute;
    bottom: 1rem;
    left: 0;
}
.cookieImageTwo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100px;
    position: absolute;
    bottom: -30px;
    right: 0;
    overflow: hidden;
}
.cookieImageThree {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 70px;
    position: absolute;
    top: 54%;
    left: 44%;
}

.cookieImageOne img,.cookieImageTwo img,.cookieImageThree img {
    width: 100%;
    height: 100%;
}

.bannerRight {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 90%;
}

.cookieText {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.cookieText p {
    margin: 0;
    font-family: Urbane;
    font-size: 20px;
    font-weight: 500;
    line-height: 27.65px;
    text-align: left;
    color: #fff;
    width: 90%;

}

.cookieText span {
    font-family: Urbane;
    font-size: 20px;
    font-weight: 700;
    line-height: 27.65px;
    text-align: left;
    color: #fff;
    text-decoration: underline;

}

.cookieBottomContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0;
    flex-wrap: wrap;
    z-index: 999;
    gap: 1rem;
}

.cookieBottomLeftContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.cookieBottomLeftContent p {
    font-family: Urbane;
    font-size: 20px;
    font-weight: 600;
    line-height: 27.65px;
    text-align: left;
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
}

.cookieBottomRightContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 3rem;
    width: 40%;
}

.cookieBottomRightContent button {
    font-family: Urbane;
    font-size: 20px;
    font-weight: 500;
    line-height: 27.65px;
    text-align: center;
    color: #595BDB;
    border: none;
    outline: none;
    border-radius: 36px;
    width: 14rem;
    height: 3rem;
    cursor: pointer;
}

.privacyPopUp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2rem 3rem;
    border-radius: 1rem;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:60rem;
    z-index: 9999;
    box-shadow: 2px 3px 8px #0000001f;
}

.privacyPopUpHeder {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.privacyPopUpHeder {
    font-family: Urbane;
    font-size: 20px;
    font-weight: 500;
    line-height: 27.65px;
    text-align: left;
    color: #000;
    margin: 0;
}
.privacyPopUpBody{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
}
.privacyPopUpBodyText{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
}
.privacyPopUpBodyText p{
    font-family: Urbane;
font-size: 16px;
font-weight: 300;
line-height: 23.04px;
letter-spacing: 0.06em;
text-align: left;
color: #000;
margin: 0;
}
.privacyPopUpBodyText a{
    font-family: Urbane;
font-size: 16px;
font-weight: 300;
line-height: 23.04px;
letter-spacing: 0.06em;
text-align: left;
color: #595BDB;
padding: 8px 0;
text-decoration: underline;
}
.privacyPopUpBodyButton{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0;
}
.privacyPopUpBodyButton button{
    font-family: Urbane;
    font-size: 20px;
    font-weight: 500;
    line-height: 27.65px;
    text-align: center;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 36px;
    width: 14rem;
    height: 3rem;
    background-color: #595BDB;
    cursor: pointer;
}
.privacyPopUpBodyTable{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.privacyTableRight{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 25%;
    align-items: center;
}
.privacyTableRight button{
    font-family: Urbane;
    font-size: 20px;
    font-weight: 500;
    line-height: 27.65px;
    text-align: center;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 36px;
    width: 14rem;
    height: 3rem;
    background-color: #595BDB;
    margin-top: 2rem;
    cursor: pointer;
}
.privacyTableLeft{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    border: 1px solid #dbdbdb;
    border-radius: 10px;
}
.privacyTableRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #dbdbdb;
}
.privacyTableRowLeft{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 70%;
}
.privacyTableRowLeft p{
    font-family: Urbane;
font-size: 18px;
font-weight: 500;
line-height: 23.04px;
text-align: left;
margin: 0;
color: #3F3F3F;

}
.privacyTableRowRight{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 30%;
}  
.privacyTableRow:last-child{
border-bottom: 0;
}
.privacyTableRowRight p{
    font-family: Urbane;
font-size: 11px;
font-weight: 500;
line-height: 13.33px;
text-align: left;
margin: 0;
color: #3F3F3F;
}
.overlay{
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}
.disabledButton button{
    color: #3F3F3F;
    opacity: 0.75;
    pointer-events: none;
}
@media screen and (max-width: 1024px) {
    .banner {
        height:fit-content;
        padding-top: 1rem;
    }
    
    .cookieBottomLeftContent p {
        font-family: Urbane;
        font-size: 18px;
        font-weight: 500;
        line-height: 27.65px;
        text-align: left;
        color: #fff;
        text-decoration: underline;
        cursor: pointer;
    }
    
    .cookieBottomRightContent {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-right: 3rem;
        width:fit-content;
        gap: 1rem;
    }
    
    .cookieBottomRightContent button {
        font-family: Urbane;
        font-size: 20px;
        font-weight: 500;
        line-height: 27.65px;
        text-align: center;
        color: #595BDB;
        border: none;
        outline: none;
        border-radius: 36px;
        width:fit-content;
        height: 3rem;
        cursor: pointer;
        padding: .3rem 1.5rem;
    }
    .cookieText p {
        margin: 0;
        font-family: Urbane;
        font-size: 18px;
        font-weight: 500;
        line-height: 27.65px;
        text-align: left;
        color: #fff;
        width: 90%;
        z-index: 999;
    
    }
    
    .cookieText span {
        font-family: Urbane;
        font-size: 20px;
        font-weight: 600;
        line-height: 27.65px;
        text-align: left;
        color: #fff;
        text-decoration: underline;
    
    }
    .privacyPopUp {
        padding: 2rem ;
        top: 70%;
        left: 50%;
        width:90%;
    }
    
    .privacyPopUpHeder {
        font-family: Urbane;
        font-size: 18px;
        font-weight: 500;
        line-height: 27.65px;
        text-align: left;
        color: #000;
        margin: 0;
    }
    .privacyPopUpBody{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 1rem;
    }
    .privacyPopUpBodyText{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 90%;
    }
    .privacyPopUpBodyText p{
        font-family: Urbane;
    font-size: 16px;
    font-weight: 300;
    line-height: 23.04px;
    letter-spacing: 0.06em;
    text-align: left;
    color: #000;
    margin: 0;
    }
    .privacyPopUpBodyText a{
        font-family: Urbane;
    font-size: 16px;
    font-weight: 300;
    line-height: 23.04px;
    letter-spacing: 0.06em;
    text-align: left;
    color: #595BDB;
    padding: 8px 0;
    text-decoration: underline;
    }
    .privacyPopUpBodyButton{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem 0;
    }
    .privacyPopUpBodyButton button{
        font-family: Urbane;
        font-size: 18px;
        font-weight: 500;
        line-height: 27.65px;
        text-align: center;
        color: #fff;
        border: none;
        outline: none;
        border-radius: 36px;
        width: fit-content;
        height: 3rem;
        background-color: #595BDB;
        cursor: pointer;
        padding: .3rem 1.5rem;
    }
    .privacyPopUpBodyTable{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .privacyTableRight{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 25%;
        align-items: center;
    }
    .privacyTableRight button{
        font-family: Urbane;
        font-size: 18px;
        font-weight: 500;
        line-height: 27.65px;
        text-align: center;
        color: #fff;
        border: none;
        outline: none;
        border-radius: 36px;
        width: 14rem;
        height: 3rem;
        background-color: #595BDB;
        margin-top: 2rem;
        cursor: pointer;
    }
    .privacyTableLeft{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 70%;
        border: 1px solid #dbdbdb;
        border-radius: 10px;
    }
    .privacyTableRow{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid #dbdbdb;
    }
    .privacyTableRowLeft{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 70%;
    }
    .privacyTableRowLeft p{
        font-family: Urbane;
    font-size: 18px;
    font-weight: 500;
    line-height: 23.04px;
    text-align: left;
    margin: 0;
    color: #3F3F3F;
    
    }
}
@media screen and (max-width:800px) {
    .privacyPopUpBodyTable{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: normal;
    }
    .privacyTableLeft{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 95%;
        border: 1px solid #dbdbdb;
        border-radius: 10px;
    }
    
    .privacyTableRight{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        align-items: center;
        gap: .5rem;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 500px) {
    .cookieBottomRightContent button {
        font-family: Urbane;
        font-size: 16px;
        font-weight: 500;
        line-height: 27.65px;
        text-align: center;
        color: #595BDB;
        border: none;
        outline: none;
        border-radius: 36px;
        width:fit-content;
        height: 3rem;
        cursor: pointer;
        padding: .3rem 1.5rem;
    }
    .cookieText p {
        margin: 0;
        font-family: Urbane;
        font-size: 16px;
        font-weight: 500;
        line-height: 27.65px;
        text-align: left;
        color: #fff;
        width: 90%;
        z-index: 999;
    
    }
    .cookieText span {
        font-family: Urbane;
        font-size: 17px;
        font-weight: 700;
        line-height: 27.65px;
        text-align: left;
        color: #fff;
        text-decoration: underline;
    
    }
    .privacyPopUp {
        padding: 1rem ;
        top: 70%;
        left: 50%;
        width:80%;
    }
    .privacyPopUpHeder {
        font-size: 17px;

    }
    .privacyPopUpBodyText p{
        font-family: Urbane;
    font-size: 12px;
    line-height: 17px;

    }
    .privacyPopUpBodyText a{
    font-size: 12px;
    }
    .privacyPopUpBodyButton{
        padding: .5rem 0;
    }
    .privacyPopUpBodyButton button{
        font-family: Urbane;
        font-size: 12px;
        font-weight: 500;
        line-height: 27.65px;
        text-align: center;
        color: #fff;
        border: none;
        outline: none;
        border-radius: 36px;
        width: fit-content;
        height: 2rem;
        background-color: #595BDB;
        cursor: pointer;
        padding: 0 1.5rem;
    }
    .privacyTableRight button{
        font-family: Urbane;
        font-size: 14px;
        font-weight: 500;
        line-height: 27.65px;
        text-align: center;
        color: #fff;
        border: none;
        outline: none;
        border-radius: 36px;
        width: 14rem;
        height: fit-content;
        background-color: #595BDB;
        margin-top: 1rem;
        cursor: pointer;
        padding: .1rem 0;
    }
    .privacyTableRowLeft p{
        font-family: Urbane;
    font-size: 14px;
    
    }
}