.Container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    position: relative;
    background-image: url("../../assests/bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0 1rem;
  }
  .learnersIMg {
    width: 10%;
  }
  .learnersIMg img {
    width: 100%;
  }
  .HeaderContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
  } 
  .left {
    width: 40rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .right {
    width: 40rem;
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
   
  }
  .LeftContent {
    width: 32rem;
  }
  .LogoImage {
    width: 6rem;
  }
  .LogoImage img {
    width: 100%;
    border-radius: 10rem;
  }
  .heading {
    width: 100%;
  }
  .heading p {
    font-size: 3rem;
    font-weight: 700;
    color: #d7ae74;
  }
  .HeadingText {
    width: 100%;
  }
  .HeadingText p {
    font-size: 1.75rem;
    font-weight: 500;
    color: #fff;
  }
  .formText {
    font-size: 1.2rem;
    margin: 15px 2rem;
    width: 100%;
    align-items: center;
    color: #1a1e5d;
  }
  .text1 {
    margin: 4px 0;
    font-weight: 500;
  }
  .text2 {
    margin: 0;
    font-weight: 700;
  }
  .Roles{
    display: flex;
    flex-direction: column;
  }

    .Roles p{
      font-size: 2rem;
      font-weight: 500;
    }
    .Cards{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .Card{
      width: 15rem;
      border: 2px solid ;
      text-align: center;
      margin: 6px 0;
      border-radius: 1rem;
      cursor: pointer;
      background-color: #ebbc7b;
    }
    .Card p{
      font-size: 1.2rem;
      margin: 0;
      padding: 1rem;
    }
    .SearchText p{
      font-weight: 700;
      font-size: 3rem;
      margin: 0;
      padding:1rem 0;
      color: #fff;
      text-align: center;
      
    }
    @media screen and (max-width:599px){
      .right {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
      }
      .SearchText p{
        font-weight: 700;
        font-size: 2.5rem;
        margin: 0;
        padding:1rem 0;
        color: #fff;
        text-align: center;
        
      }
      .Roles p{
        font-size: 1.5rem;
        font-weight: 500;
      }
      .Card{
        width: 12rem;
        margin: 6px 0;
      }
      .Card p{
        font-size: 1rem;
        margin: 0;
        padding: .8rem;
      }
    }