.Container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  padding: 0 1rem;
  margin-left: 1rem;
  display: grid;
  justify-content: center;
}

.Header {
  width: 100%;
  padding: 1rem 0px;
  display: flex;
  align-items: center;
}

.heading {
  width: 110vh;
  display: flex;
  align-items: center;
}

.heading h1 {
  color: #222872;
}

.forumIcon {
  font-size: 30px;
  color: #c2902e;
}

.HeaderMain {
  width: 110vh;
}
.content {
  display: flex;
  justify-content: flex-end;
  width: 110vh;
}

.InfoActions {
  width: 100%;
}

.InfoActionsHead {
  width: 110vh;
  display: flex;
  flex-direction: row;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.InfoActionsHead button {
  color: #fff;
  border: none;
  background: #222872;
  padding: 0.5rem;
  width: 5rem;
  border-radius: 5px;
  font-size: 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
}

.Query {
  width: 30rem;
}

.Query p {
  font-weight: 800;
  color: #222872;
}

.Search {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 2px solid #dbdbdb;
  padding: 4px 6px;
  border-radius: 10px;
}
.Search input {
  width: 100%;
  height: 1.75rem;
  outline: none;
  border: none;
  font-size: 1.2rem;
}
.SearchIcon {
  font-size: 1.5rem;
  padding-right: 2px;
  color: #1a1e5d;
}

.AddButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
.add button {
  color: #fff;
  border: none;
  background: #222872;
  padding: 0.5rem;
  gap: 0.5rem;
  width: 11rem;
  height: 2.8rem;
  border-radius: 5px;
  font-size: 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
}

.AddIcon {
  font-size: 1.5rem;
  padding-right: 2px;
  color: #ffffff;
}

.filter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.filterIcon {
  font-size: 1.5rem;
  padding-right: 2px;
  color: #ffffff;
  width: 6rem;
}

.QueryBody {
  width: 110vh;
}

.Faq {
  /* margin-left: 4rem; */
  background-color: rgba(51, 79, 107, 0.068);
  padding: 20px;
  border-radius: 0px 10px 0px 10px;
  margin-top: 20px;
  box-shadow: 0px 3px 6px 0px rgba(140, 149, 159, 0.15);
}
.Querryinfo {
  width: 100vh;
  padding-bottom: 1rem;
}
.Querryinfo .user {
  font-weight: 700;
  color: #1a1e5d;
  font-size: 1.2rem;
}

.Querryinfo .date {
  font-weight: 500;
  color: #c2902e;
  font-size: 0.7rem;
}

.question {
  font-size: 1rem;
  font-weight: 700;
  color: #1a1e5d;
  padding: 20px;
}

.AnswerList {
  width: 65vh;
  background-color: white;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1) 0px 4px 6px -4px
    rgba(0, 0, 0, 0.1);
}

.answers {
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 1rem;
}

.Answersinfo {
  width: 45vh;
  padding-bottom: 1rem;
}

.Answersinfo .user {
  font-weight: 600;
  color: #1a1e5d;
  font-size: 0.6;
}

.Answersinfo .date {
  font-weight: 400;
  font-size: 0.6rem;
  color: #c2902e;
}

.answersActions {
  display: flex;
  gap: 1rem;
  justify-content: end;
}

.EditAns {
  font-size: 1.3rem;
  color: #1a1e5d;
  cursor: pointer;
}

.DeleteAns {
  font-size: 1.3rem;
  color: #1a1e5d;
  cursor: pointer;
}

.CommentQuestion {
  font-size: 1.5rem;
  color: #1a1e5d;
  cursor: pointer;
}

.DeleteQuestion {
  font-size: 1.5rem;
  color: #1a1e5d;
  cursor: pointer;
}

.More {
  font-size: 1.5rem;
  color: #1a1e5d;
  cursor: pointer;
}

.EditQuestion {
  font-size: 1.5rem;
  color: #1a1e5d;
  cursor: pointer;
}

.QuestionAction {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
  padding-left: 1rem;
  gap: 1.5rem;
}

.QaLike {
  font-size: 1.5rem;
  color: #1a1e5d;
  cursor: pointer;
}

.CommentBox {
  width: 65vh;
  padding: 20px;
  margin: 20px;
  display: flex;
  gap: 2rem;
  height: 20vh;
  border-radius: 8px;
}

.CommentBox textarea {
  width: 100%;
  font-size: 1.2rem;
  font-weight: 800;
  padding: 2rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  resize: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.CommentBox button {
  padding: 10px;
  background-color: #1a1e5d;
  color: #fff;
  border: none;
  height: 2.3rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.CommentBox button:hover {
  background-color: rgb(1, 59, 121);
}

.CommentBox textarea:focus {
  outline: none;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modalContent {
  background-color: white;
  padding: 20px;
  width: 60vh;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.modalContent h2 {
  color: #1a1e5d;
}

.QuesDelmodal {
  background-color: white;
  padding: 20px;
  width: 45vh;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.QuesDelmodal h2 {
  color: #1a1e5d;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #888;
}

.closeButton {
  background-color: #1a1e5d;
  color: #ffffff;
  border: none;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.closeButton:hover {
  background-color: #b40000;
  color: #ffffff;
  border: none;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.AddmodalClose {
  display: flex;
  justify-content: flex-end;
}

.DeleteQuestionModalButtons {
  padding-top: 20px;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.DelAnsBtnConfirm {
  padding: 10px;
  background-color: #1a1e5d;
  color: #fff;
  border: none;
  height: 2.3rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.DelAnsBtnConfirm:hover {
  background-color: #4bb543;
}

.DelAnsBtnCancel:hover {
  background-color: #b40000;
}

.DelAnsBtnCancel {
  padding: 10px;
  background-color: #1a1e5d;
  color: #fff;
  border: none;
  height: 2.3rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.DeletemodalClose {
  display: flex;
  justify-content: flex-end;
}

.closeIcon {
  font-size: 2rem;
  cursor: pointer;
}

.AddQuestionBox {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
}

.AddQuestionBox textarea {
  width: 100%;
  height: 5rem;
  border: none;
  border-radius: 4px;
  resize: none;
  font-weight: 900;
  font-size: 1rem;
}

.AddQuestionBox button {
  padding: 10px;
  background-color: #1a1e5d;
  color: #fff;
  border: none;
  height: 2.3rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.AddQuestionBox button:hover {
  background-color: rgb(1, 59, 121);
}

.AddQuestionBox textarea:focus {
  outline: none;
}

.addtxt {
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #ced4da;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.attachments {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.fileicon {
  font-size: 1.3rem;
  color: #1a1e5d;
  cursor: pointer;
}

.PreviewBody {
  max-width: 50vh;
  max-height: 30vh;
  overflow: auto;
  padding-top: 2rem;
}

.filePreview {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.filePreview img {
  max-width: 90%;
  max-height: 90%;
  width: auto;
  height: auto;
}

.filePreview audio {
  max-width: 100%;
  max-height: 100%;
}

.viewBody {
  max-width: 40vh;
  max-height: 80vh;
  padding-top: 20px;
  justify-content: center;
}

.view {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.view img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.InputIcons {
  display: flex;
  gap: 1rem;
}

.DeletePreview {
  font-size: 1.5rem;
  color: #1a1e5d;
  cursor: pointer;
}

.ImagePreview {
  display: block;
}

.AudioPreview {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
}

.VideoPreview {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.player {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.PreviewBody::-webkit-scrollbar {
  width: 8px;
}

.PreviewBody::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.PreviewBody::-webkit-scrollbar-thumb {
  background: #1a1e5d;
  border-radius: 10px;
}

.PreviewBody::-webkit-scrollbar-thumb:hover {
  background: #1a1e5d;
}

.addtxt textarea::-webkit-scrollbar {
  width: 8px;
}

.addtxt textarea::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.addtxt textarea::-webkit-scrollbar-thumb {
  background: #1a1e5d;
  border-radius: 10px;
}

.addtxt textarea::-webkit-scrollbar-thumb:hover {
  background: #1a1e5d;
}

.showItems {
  background-color: transparent;
  font-size: 1rem;
  font-weight: 500;
  color: #888;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.showItems:hover {
  color: #1a1e5d;
}

.AnsLike {
  font-size: 1.3rem;
  color: #1a1e5d;
  cursor: pointer;
}

.dropbtn {
  background-color: #04aa6d;
  color: transparent;
  padding: 20px;
  font-size: 20px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: transparent;
  min-width: 30px;
  border: none;
  z-index: 1;
}

.dropdownContent a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* .dropdownContent a:hover {
  background-color: transparent;
} */

.dropdownContent {
  display: Block;
  gap: 3rem;
}

.listEdit,
.listDelete {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.listEdit:hover {
  background-color: #1a1e5d;
}
.EditQuestion:hover {
  color: #fff;
}

.listDelete:hover {
  background-color: #1a1e5d;
}
.DeleteQuestion:hover {
  color: #fff;
}

.QuerryinfoBox {
  display: flex;
  justify-content: flex-end;
}

.Imagelisting {
  padding: 20px;
}
