.container {
  height: 80vh;
  overflow-y: auto;
  padding: 0 2rem;
  padding-bottom: 1rem;
}

.container::-webkit-scrollbar {
  width: 10px;
}

.container::-webkit-scrollbar-track {
  background: #d9d9d9;
  border-radius: 10px;
}

.container::-webkit-scrollbar-thumb {
  background: #8c8c8c;
  border-radius: 10px;
}

.container::-webkit-scrollbar-thumb:hover {
  background: #8c8c8c;
}

.support {
  display: flex;
  flex-direction: column;
  width: 93%;
  gap: 1.3rem;
}

.welcomeSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(
    91.43deg,
    #00cbf4 7.29%,
    rgba(0, 142, 225, 0.35) 142.96%,
    #0151cd 142.97%,
    #032f82 142.99%
  );

  border-radius: 45px;
}

.welcomeSectionLeft {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 45%;
  padding: 1rem;
}

.welcomeSectionLeft p {
  font-family: Urbane Demi;
  font-size: 24px;
  font-weight: 600;
  line-height: 39.82px;
  text-align: left;
  margin: 0;
  padding: 0.75rem 1rem;
  color: #fff;
}

.welcomeSectionLeft span {
  color: #fff;
  font-family: Urbane Extra;
  font-size: 16px;
  font-weight: 300;
  line-height: 27.65px;
  text-align: left;
  margin: 0;
  padding: 0 1rem;
}

.welcomeSectionRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 45%;
}

.welcomeImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding-right: 20%;
}

.welcomeImage {
  width: 10rem;
  height: 10rem;
}

.welcomeImage img {
  width: 100%;
  height: 100%;
}

.supportSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  gap: 1rem;
  border: 2px solid #8988901f;
  box-shadow: 0px -4px 22.8px 2px #d9d9d9;
  border-radius: 12px;
}

.supportSectionRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
}

.supportSectionImage {
  width: 23rem;
}

.supportSectionImage img {
  width: 100%;
  height: 100%;
}

.supportSectionLeft {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 50%;
  padding: 1rem 0;
  padding-left: 1rem;
}

.supportHeder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.supportHeder p {
  font-family: Urbane;
  font-size: 24px;
  font-weight: 600;
  line-height: 36.01px;
  text-align: left;
  margin: 0;
  color: #595bdb;
  padding-bottom: 2rem;
}

.supportSubHeder {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.supportSubHeder p {
  font-family: Urbane;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: #5f5f5f;
  margin: 0;
}

.supportSubHeder span {
  font-family: Urbane Extra;
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  text-align: left;
  color: #5f5f5f;
  margin: 0;
}

.supportDetail {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.5rem;
}

.supportDetail p {
  font-family: Urbane Extra;
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  text-align: left;
  margin: 0;
  color: #5F5F5F;
}

.supportDetail span {
  font-family: Urbane;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.04px;
  text-align: left;
  margin: 0;
  color: #5F5F5F;
  text-decoration: underline;
}
.supportDetail a {
  font-family: Urbane;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.04px;
  text-align: left;
  margin: 0;
  color: #5F5F5F;
  text-decoration: underline;
}

.faqSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 2rem;
}

.faqHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 0;
}

.faqHeader p {
  font-family: Urbane;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #595bdb;
}

.faqBody {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 1rem;
  gap: .5rem;
}

.faqCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-bottom: 1px solid #ACACAC;
  padding: .75rem 0;
  gap: 1rem;
}
.faqBody .faqCard:last-child {
  border-bottom: none;
}


.faqQuestion {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.faqQuestionLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
}

.faqQuestionLeft p {
  font-family: Urbane;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000;
}

.faqQuestionRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 5%;
}

.faqQuestionRight svg {
  font-size: 1.6rem;
  color: #595bdb;
  cursor: pointer;
}

.faqAnswer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.faqAnswer p {
  font-family: Urbane Extra;
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000;
}
.faqTable{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 1rem;
}
.faqTable table {
  width: 100%;
  border-collapse: collapse;
}

.faqTable th,
.faqTable td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.faqTable th {
  background-color:#4477c9;
  color: white;
}

.faqTable td {
  color: black;
}

.faqTable td:first-child {
  background-color:#4477c9;
  color: white;
}
.statusTable {
  padding-top: 1rem;
}

.statusTable table {
  width: 100%;
  border-collapse: collapse;
}

.statusTable td {
  border: 1px solid #121212; /* Add borders around cells */
  padding: 8px;
  text-align: left;
}

.statusTable td:first-child {
  font-weight: bold;
}


@media screen and (max-width: 1024px) {
  .container {
    height: 100%;
    width: 100vw;
    padding: 0;
  }
  .support {
    width: 100%;
  }
  .supportSection,
  .welcomeSection {
    margin: 0 0.5rem;
  }
}
@media screen and (max-width: 768px) {
  .welcomeSection {
    flex-direction: column;
    border-radius: 30px;
  }
  .welcomeSectionLeft {
    width: 100%;
    height: 50%;
  }
  .welcomeSectionLeft p {
    font-size: 1.5rem;
  }
  .welcomeSectionLeft span {
    font-size: 1.5rem;
  }
  .supportHeder p {
    font-size: 24px;
  }
  .supportSubHeder P {
    font-size: 1.5rem;
  }
  .supportSubHeder span,
  .supportDetail p,
  .supportDetail span {
    font-size: 0.875rem;
  }
  .supportSectionImage {
    width: 18rem;
  }
}
@media screen and (max-width: 576px) {
  .supportSection {
    flex-direction: column;
  }
  .supportSectionLeft {
    width: 100%;
  }
  .supportSectionImage {
    width: 23rem;
  }
  .supportDetail {
    gap: 0.5rem;
  }
}

@media screen and (max-width: 480px) {
  .welcomeSection {
    border-radius: 25px;
  }
  .welcomeSectionLeft p {
    font-size: 1.375rem;
  }
  .welcomeSectionLeft span {
    font-size: 1.125rem;
  }
  .welcomeSectionRight {
    width: 100%;
  }
  .welcomeImageContainer {
    padding-right: 0;
    align-items: center;
  }
  .supportHeder p {
    font-size: 18px;
  }
  .supportSubHeder P {
    font-size: 1rem;
  }
  .supportSubHeder span,
  .supportDetail p,
  .supportDetail span {
    font-size: 0.75rem;
  }
}
