.container {
    height: 82vh;
    overflow-y: auto;
    padding: 0 2rem;
}

.container::-webkit-scrollbar {
    width: 10px;
}

.container::-webkit-scrollbar-track {
    background: #D9D9D9;
    border-radius: 10px;
}

.container::-webkit-scrollbar-thumb {
    background: #8C8C8C;
    border-radius: 10px;
}

.container::-webkit-scrollbar-thumb:hover {
    background: #8C8C8C;

}

.assessmentSection {
    display: flex;
    flex-direction: column;
    width: 93%;
}

.header {
    padding: 2rem;
    background: #FFC962;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.headerIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 60px;
}

.headerIcon img {
    width: 100%;
    height: 100%;
}

.headerText {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.headerText span {
    font-family: Urbane;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    margin: 0;
    color: #fff;
}

.progressBarSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 0;
}

.body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 0;
}

.bodyLeft {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 67%;
    padding: 0 1rem;
    height: fit-content;
    padding-bottom: 0;
}

.questionSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 1px solid #898890;
    border-radius: 25px;
    padding: 1rem;
}

.question {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.question img {
    max-width: 60%;
    display: inline;
    margin-bottom: .5rem;
}

.question span {
    font-family: Urbane;
    font-size: 16px;
    font-weight: 300;
    line-height: 40px;
    text-align: left;
    margin: 0;
    color: #000;
    padding-right: 5px;
}

.question p {
    font-family: Urbane;
    font-size: 16px;
    font-weight: 300;
    line-height: 40px;
    text-align: left;
    margin: 0;
    color: #000;
}

.questionBody {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-height: 5rem;
}

.questionButtonSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: .5rem 2rem;
}

.questionButtonLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.questionButtonLeft span {
    font-family: Urbane;
    font-size: 13px;
    font-style: italic;
    font-weight: 300;
    line-height: 40px;
    text-align: left;
    width: 90%;
}

.questionButtonRight p {
    font-family: Urbane;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    text-align: left;
    text-decoration: underline;
    color: #000;
    margin: 0;
    cursor: pointer;
}
.questionButtonRight:has(:only-child) {
    justify-content: flex-end;
}

.questionButtonRight {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.questionButtonRight button {
    outline: none;
    border: none;
    width: 9rem;
    height: 2.25rem;
    background: #FFBE00;
    border-radius: 20px;
    font-family: Urbane;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    text-align: center;
    color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

/*Ordinary*/
.ordinaryContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 1rem;
    width: 100%;
}

.ordinarySection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem .1rem;
}

.ordinarySectionRight,.rewriteSectionRight {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

.ordinarySectionRight input {
    border: 1px solid #898890;
    background-color: #fff;
    border-radius: 25px;
    font-family: Urbane;
    font-size: 16px;
    font-weight: 300;
    line-height: 32px;
    text-align: left;
    color: #000;
    padding: .5rem;
    outline: none;
}

.ordinarySectionRight textarea{
    border: 1px solid #898890;
    background-color: #fff;
    border-radius: 25px;
    font-family: Urbane Extra;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #000;
    padding: .5rem;
    outline: none;
    resize: none;
}

.ordinarySectionRight input:focus {
    border-color: #FF7A00;
}
.ordinarySectionRight textarea:focus {
    border-color: #FF7A00;
}

/*multiple choice*/
.multipleChoiceContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem 2rem;
    width: 100%;
}

.multipleChoiceAnswer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #898890;
    border-radius: 25px;
    padding: .5rem 1rem;
    margin: .5rem 0;
    width: 50%;
}

.multipleChoiceAnswer p {
    font-family: Urbane;
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    text-align: left;
    color: #000;
    margin: 0;
}


/*fillTheBlank*/
.fillTheBlank {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem 2rem;
    width: 100%;
}

.fillTheBlankQuestion {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.fillTheBlankQuestion p {
    margin: 0;
    font-family: Urbane;
    font-size: 16px;
    font-weight: 300;
    line-height: 45px;
    text-align: left;
    color: #000;
}

.fillTheBlankQuestion input {
    outline: none;
    border: none;
    border-bottom: 1px solid #000000;
    font-family: Urbane;
    font-size: 16px;
    font-weight: 300;
    line-height: 32px;
    text-align: left;
    color: #000;
    padding: .5rem;
    width: 20%;

}
.inputWrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.blankInput {
    /* min-width:200px; */
    height: 1.25rem;
    border: none;
    border-bottom: 1px solid #000;
    outline: none;
    background-color: transparent;
    font-family: Urbane;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    padding-bottom: 4px;
    width: fit-content

}
/*match the following*/
.matchFollowingContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding: 1rem 2rem;
    width: 100%;
}

.matchFollowingLeft,
.matchFollowingRight {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.matchFollowingCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #898890;
    border-radius: 20px;
    padding: .5rem 1rem;
    min-height: 80px;
    margin: .25rem 0;
}
.matchFollowingImageCard{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #898890;
    border-radius: 20px;
    margin: .25rem 0;
    width: 19.125rem;
    height: 15rem;
}
.matchFollowingImageCard img{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: fill;
}

.matchFollowingCard p {
    font-family: Urbane;
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
    text-align: left;
    margin: 0;
    color: #000;
}

.correct {
    background-color: #00DFC7;
}

.incorrect {
    background-color: #FF2443;
}

.selected {
    border: 1px solid #FF7A00;
}

.unselected {
    background-color: #FFFFFF;
    /* Default background color */
}

/*Re arrange*/
.reArrangeContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 1rem 0;
    width: 100%;
    max-width: 100%; 
    flex-wrap: wrap;
    overflow: hidden;
    position: relative; 
    gap: .75rem;
}
.reArrangeCard{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #898890;
    border-radius: 25px;
    padding:.5rem 1rem;
    margin: 0 .5rem;
    height: fit-content;
}
.reArrangeCard p{
    font-family: Urbane Extra;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
    margin: 0;
    color: #000;
}
.statusMark {
    display: flex;
   font-size: 2rem;
   margin-top: 16px;
  }
  .reArrangeAnswerCard{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #898890;
    border-radius: 25px;
    padding:0 1rem;
    margin: 0 .5rem;
    height: fit-content;
}
  

/*Selection*/
.blankDropdown {
    height: 2rem;
    outline: none;
    border-radius: .5rem;
    color: #000;
    margin: 0 .3rem;
    font-family: Urbane;
    font-size: 16px;
    font-weight: 300;
    line-height: 40px;
    text-align: center;
}
.dropDownWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.bodyRight {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 26%;

}

.timeContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background: #EDEDFB;
    padding: .5rem;
    margin-bottom: .5rem;
    height: 9vh;
    gap: .7rem;
}

.timerIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 36px;
}

.timerIcon img {
    width: 100%;
    height: 100%;
}

.timerText {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
}

.timerText p {
    margin: 0;
    font-family: Urbane;
    font-size: 18px;
    font-weight: 600;
    line-height: 40px;
    text-align: left;
    color: #3E3D42;
}

.markContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 20px;
    background: #EDEDFB;
    padding: 1rem 2rem;
}

.markData {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.markDataLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 70%;
}

.markDataLeft p {
    font-family: Urbane Extra;
    font-size: 13px;
    font-weight: 255;
    line-height: 40px;
    text-align: left;
    color: #000;
    margin: 0;
}

.markDataRight {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 25%;
}

.markDataRight p {
    font-family: Urbane;
    font-size: 18px;
    font-weight: 500;
    line-height: 40px;
    text-align: left;
    margin: 0;
    color: #000;
}

.markDataRight span {
    font-family: Urbane;
    font-size: 26px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
    margin: 0;
    color: #000;

}

.questionPaletteHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 0;
}

.questionPaletteHeader p {
    margin: 0;
    font-family: Urbane;
    font-size: 19.2px;
    font-weight: 300;
    line-height: 40px;
    text-align: left;
    color: #000;
}

.questionPalette {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    row-gap: 1rem;
}

.number {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #89889033;
    width: 2.6875rem;
    height: 2.125rem;
    background: #FFFFFF;
    border-radius: 10px;
    cursor: pointer;
}

.number p {
    font-family: Urbane;
    font-size: 13px;
    font-weight: 300;
    line-height: 40px;
    text-align: center;
    margin: 0;
    color: #000;

}

.explanationSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: .5rem 2rem;
    margin-bottom: 2rem;
}

.resultText {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-top: 2px solid #898890;
    padding: 1rem 0;
}

.resultText p {
    margin: 0;
    font-family: Urbane;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #00DFC7;
}

.explanationHeading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 0;
}

.explanationHeading p {
    margin: 0;
    font-family: Urbane;
    font-size: 20px;
    font-style: italic;
    font-weight: 300;
    line-height: 30px;
    text-align: left;
    color: #FF7A00;

}

.explanationText {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.explanationText p {
    font-family: Urbane;
    font-size: 19.2px;
    font-weight: 300;
    line-height: 40px;
    text-align: left;
    color: #000;
    margin: 0;
}

.summeryContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 20px;
    background: #fff;
    padding: 1rem 2rem;
    box-shadow: 0px 1px 0px 2px #EDEDFB;

}

.summeryTopContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #00000029;
    padding: 1rem 0;
}

.roundGraph {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 60%;
    position: relative;
    padding: .5rem 0;

}

.graphText {
    font-family: Urbane;
    font-size: 28px;
    font-weight: 300;
    line-height: 33.61px;
    text-align: center;
    color: #5F5F5F;
}

.summeryTopText {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.summeryTopText p {
    font-family: Urbane Extra;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.4px;
    text-align: left;
    color: #818085;

}

.summeryBottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem 0;
}

.summeryBottomData {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: .5rem 0;
    gap: 1rem;
}

.summeryBottomDataLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 20%;
}

.summeryBottomDataLeft span {
    border: 1px solid #89889033;
    border-radius: 8px;
    font-family: Urbane;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #fff;
    width: 2.015rem;
    height: 1.928rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.summeryBottomDataRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.summeryBottomDataRight p {
    margin: 0;
    font-family: Urbane;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.4px;
    text-align: left;
    color: #818085;

}

.resultSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #fff;
    box-shadow: 0px 1px 0px 2px #EDEDFB;
    padding: 1rem;
    border-radius: 20px;
}

.resultSectionHeder {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.resultSectionHeder h4 {
    font-family: Urbane;
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    color: #D09B00;
    margin: 0;
    padding: 1rem 0;

}

.resultSectionHeder p {
    font-family: Urbane;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    margin: 0;
    color: #5F5F5F;

}

.resultSectionImage {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 11rem;
    padding: 2rem 0;
}

.resultSectionImage img {
    width: 100%;
    height: 100%;
}

.resultSectionBottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.resultSectionBottomContent p {
    font-family: Urbane;
    font-size: 20px;
    font-weight: 300;
    line-height: 24.14px;
    text-align: center;
    margin: 0;
    color: #595BDB;
}

.resultSectionBottomContent h4 {
    font-family: Urbane;
    font-size: 36px;
    font-weight: 600;
    line-height: 57.94px;
    text-align: center;
    margin: 0;
    color: #595BDB;
    padding: 1rem 0;
}

.finalAnswerSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #fff;
    box-shadow: 0px 1px 0px 2px #EDEDFB;
    border-radius: 20px;
    margin: 1rem 0;
}

.finalAnswerSectionHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
}

.finalAnswerSectionHeader p {
    margin: 0;
    font-family: Urbane;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #3E3D42;
}

.finalAnswerSectionBody {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.finalAnswerBodyData {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.expanded {
    border: 1px solid #00000033;
    box-shadow: 0px 2px 1.7px -1px #00000040;
    border-radius: 0px 0px 20px 20px;

}

.answerBodyHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #00000045;
    padding: 1rem;
    margin: 0 1rem;
    align-items: flex-start;

}

.answerBodyHeaderLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 90%;
    gap: .5rem;
}

.answerBodyHeaderLeft svg {
    font-size: 1.5rem;
    padding-right: 1rem;
    cursor: pointer;
}

.answerBodyHeaderLeft p {
    font-family: Urbane Extra;
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    text-align: left;
    margin: 0;
    width: 80%;
}

.answerBodyHeaderLeft span {
    font-family: Urbane Extra;
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    text-align: left;
    margin: 0;
    padding-right: .5rem;
    width: 5%;
}

.answerBodyHeaderRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.answerBodyHeaderRight svg {
    font-size: 1.5rem;
    color: #5F5F5F;
}

.answerBodyData {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: .5rem 2rem;
    padding-left: 5.65rem;
}

.answerBodyQuestion {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 1rem;
}

.answerBodyQuestion h4 {
    font-family: Urbane Extra;
    font-size: 15px;
    font-weight: 300;
    line-height: 30px;
    text-align: left;
    color: #445CDA;
    text-decoration: underline;
    padding-bottom: .5rem;
}

.answerBodyQuestion p,.answerBodyQuestion span {
    font-family: Urbane Extra;
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    text-align: left;
    color: #000000;
}

.answerBodyCorrectAnswer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 1rem;
}

.answerBodyCorrectAnswer h4 {
    font-family: Urbane Extra;
    font-size: 15px;
    font-weight: 300;
    line-height: 30px;
    text-align: left;
    color: #00DFC7;
    text-decoration: underline;
}

.answerBodyCorrectAnswer p {
    font-family: Urbane Extra;
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    text-align: left;

    color: #000000;
}
.answerBodyExplanation{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 1rem;
}
.answerBodyExplanation h4 {
    font-family: Urbane Extra;
    font-size: 15px;
    font-weight: 300;
    line-height: 30px;
    text-align: left;
    color: #FF7A00;
    text-decoration: none;
}

.answerBodyExplanation p {
    font-family: Urbane Extra;
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    text-align: left;
    color: #000000;
}
.finalAnswerBodyData:last-child .answerBodyHeader{
    border: none;
}
.FinishButton{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 1rem;
}
.FinishButton button{
    height: 2.25rem;
    background: #FF2443;
    border-radius: 8px;
    font-family: Urbane;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    text-align: center;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    outline: none;
    width: 100%;
}
.answerBodyOptions {
    padding: 6px 0;
}
.answerBodyOptions ul{
    list-style: inside;
    font-family: Urbane Extra;
}
.answerBodyOptions ul li{
    padding: .3rem 0;
}
.answerBodyMatch {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  }
  
  .matchRow {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }
  
  .matchRowCardLeft,
  .matchRowCardRight {
    width: 48%; 
    border: 1px solid #898890;
    border-radius: 20px;
    padding: 10px;
    height: fit-content;
    min-height: 60px;
  }
  .matchRowImageCard{
    width: 48%; 
  }

  .matchRowCardLeft {
    text-align: left;
  }
  
  .matchRowCardRight {
    text-align: right;
  }
  
  .matchRowCardLeft p,
  .matchRowCardRight p {
    font-family: Urbane Extra;
    font-size: 14px;
    font-weight: 300;
    line-height: 25px;
    text-align: left;
    margin: 0;
    color: #000;
    word-break: break-word; 
  }
  .popupOverlay{
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  width: 100%;
  height: 100vh;
  background: #D9D9D980;
}
.Popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    border-radius: 31px;
    padding:4rem 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99;
    box-shadow: 1px 1px 2.3px 0px #CFCFCF;

  }
  .popupText {
    padding: 2rem 0;
  }
  .popupText p {
    font-family: Urbane;
    font-size: 20px;
    font-weight: 600;
    line-height: 27.65px;
    text-align: left;
    color: #505050;
    margin: 0;
  }
  .PopupButton {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    padding: 2rem 0;
  }
  .noButton{
    width: 16rem;
    height: 3.5rem;
    border-radius: 2.5rem;
    font-family: Urbane;
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    text-align: center;
    color: #fff;
    background: #595BDB;
    border: none;
    cursor: pointer;
  }
  .yesButton{
    width: 16rem;
    height: 3.5rem;
    border-radius: 2.5rem;
    background: #D9D9D9;
    font-family: Urbane;
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    text-align: center;
    color: #898890;
    border: none;
    cursor: pointer;
  }
  .info{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.info p{
    color: #5F5F5F;
    font-family: Urbane;
    font-size: 1.1rem;
    margin-top: 20%;
}
.crossWordContainer {
    display: flex;
    flex-direction: row;
    height: fit-content;
  }
  
  .crossWordPuzzle {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 0;
    background-color: #d9d9d9; /* Grey background for inactive cells */
  }
  
  .PuzzleQuestion {
    padding: 0 1rem;
    width: 100%;
  }
  
  .cell {
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    outline: none; 
  }
  
  .activeInput {
    background-color: #fff; 
    border: 2px solid #000; 
    width: 100%;
    height: 100%;
    font-size: 20px;
    outline: none;
    text-align: center;
  }
  
  .clueNumber {
    position: absolute;
    top: 2px;
    left: 4px;
    font-size: 10px;
    color: rgb(45, 45, 45);
  }
  
  .QuestionAcross {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .QuestionAcrossInputContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .QuestionAcrossInputContainer input {
    font-family: "Poppins";
    width: 100%;
    font-size: 1.15rem;
    background-color: #fff;
    padding: 0.5rem 0.25rem;
    border-radius: 5px;
    margin: 0.5rem 0;
    border: 1px solid #bfaaaac1;
    outline: none;
    text-align: start;
  }
  
  .QuestionDown {
    display: flex;
    flex-direction: column;
  }
  
  .QuestionDownInputContainer {
    display: flex;
    flex-direction: column;
  }
  
  .QuestionDownInputContainer input {
    font-family: "Poppins";
    width: 100%;
    font-size: 1.15rem;
    background-color: #fff;
    padding: 0.5rem 0.25rem;
    border-radius: 5px;
    margin: 0.5rem 0;
    border: 1px solid #bfaaaac1;
    outline: none;
    text-align: start;
  }
  
  .cellContainer {
    position: relative;
    width: 2.75rem;
    height: 2.5rem;
    padding: 1px;
  }
  .highlightAnswer{
    outline: none;
    height: 100px;
    margin: 0 auto;
    width: 94%;
    overflow-y: auto;
    font-size:20px,                    
  }
  :global(.ql-container) {
    border: none !important;
    box-shadow: none !important;
}
:global(.ql-editor) {
    padding: 0 !important;
    font-family: Urbane;
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    text-align: left;
    color: #000;
   }
   
   .highlightContainer{
    display: flex;
    flex-direction: row;
    width: 95%;
    margin: 0 auto;
   }
   .highlightContainer p{
    font-family: Urbane;
    font-size: 16px;
    font-weight: 300;
    line-height: 40px;
    text-align: left;
    margin: 0;
    color: #000;
}
.achievementPopup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #F6F7FC;
    border-radius: 20px;
    box-shadow: 0px 1px 21.5px -2px #D4D4FF;
    padding: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 18rem;
    z-index: 999;
}

.achievementPopupHeder {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}

.achievementPopupHeder h2 {
    font-family: Urbane;
    font-size: 30px;
    font-weight: 600;
    line-height: 36.01px;
    text-align: center;
    margin: 0;
    color: #F5871F;
}

.achievementPopupHeder span {
    font-family: Urbane;
    font-size: 16px;
    font-weight: 600;
    line-height: 24.14px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #5F5F5F;
    
}
.achievementPopupHeder p {
    font-family: Urbane Extra;
    font-size: 16px;
    font-weight: 300;
    line-height: 19.2px;
    text-align: center;
    color: #5F5F5F;
}

.achievementPopupImageContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 2rem 0;
}

.achievementPopupImage {
    width: 9.5rem;
    height: 8rem;
    margin-top: .75rem;

}

.achievementPopupImage img {
    width: 100%;
    height: 100%;
}

.achievementPoint {
    position: absolute;
    bottom: 10px;
    right: 1.5rem;
}

.achievementPoint p {
    font-family: Urbane;
    font-size: 25px;
    font-weight: 600;
    line-height: 30.18px;
    text-align: left;
    color: #595BDB;
}
.achievementPopupSection{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-image: url("../../assests/Baground\ leaf.png");
    background-size: 80% auto;
    background-position: center;
    background-repeat: no-repeat;
}


.carouselText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: .5rem;
    gap: .5rem;
}
.carouselText h3{
    font-family: Urbane;
    font-size: 22px;
    font-weight: 600;
    line-height: 33.61px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #595BDB;    
}
.carouselText p {
    font-family: Urbane;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #5F5F5F;
}

.achievementPopupButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
}

.achievementPopupButton button {
    background-color: #F5871F;
    font-family: Urbane;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.14px;
    text-align: center;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 15px;
    height: 3rem;
    padding: 0 1rem;
    width: 70%;
}
.carouselButtonLeft,
.carouselButtonRight {
  background-color: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  color: #818085;
}

.carouselButtonLeft {
  left: 0px;
}

.carouselButtonRight {
  right: 0px;
}
.achievementCarousel {
    position: relative;
    overflow: hidden; 
    width: 90%;
    margin: 0 auto;
  }
  
  .carouselTrackContainer {
    overflow-x: auto; /* Enables horizontal scrolling */
    overflow-y: hidden; /* Prevents vertical scroll */
    display: flex;
    gap: 1rem; /* Adds spacing between items */
    scroll-behavior: smooth; /* Smooth scrolling effect */
  }
  
  .carouselTrack {
    display: flex;
    gap: 1rem; /* Spacing between slides */
    width: max-content; /* Ensures the track's width adapts to its content */
  }
  
  .carouselSlide {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto; 
    scroll-snap-align: center;
    align-items: center;
    background-image: url("../../assests/Animations/SprinklesGif.gif");
    background-size: cover;
  background-position: center;
  }
  
  .carouselTrackContainer::-webkit-scrollbar {
    height: 8px;
  }
  
  .carouselTrackContainer::-webkit-scrollbar-thumb {
    background: #818085; 
    border-radius: 4px;
  }
  
  .carouselTrackContainer::-webkit-scrollbar-thumb:hover {
    background: #595BDB;
  }
  
  .carouselTrackContainer::-webkit-scrollbar-track {
    background: #f6f7fc;
  }
  
  .collectPopupHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .collectPopupHeader p{
    font-family: Urbane;
    font-size: 24px;
    font-weight: 600;
    line-height: 33.61px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color:#D09B00;
  }
  
.collectPopupText{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    padding: 1rem 0;
}
.collectPopupText span {
    font-family: Urbane;
    font-size: 18px;
    font-weight: 300;
    line-height: 24.14px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #3E3D42;
}

.collectPopupText h3 {
    font-family: Urbane;
    font-size: 40px;
    font-weight: 500;
    line-height: 48.02px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #3E3D42;
    margin: 0;
    padding-bottom: .3rem;

}

.collectPopupText p {
    font-family: Urbane;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.21px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #595BDB;
}
.collectPopupImage{
    width: 14rem;
    margin: 0 auto;
    z-index: 9;
}
.collectPopupImage img{
    width: 100%;
    height: 100%;
}
.collectPopupImageContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}
.collectPopupLottie{
    position: absolute;
    transform: translate(90%,-22%) rotate(50deg);
    width: 15vw;
}
.dragContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
  .dragOptionContainer{
    display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  min-height: 10vh;
  margin: 6px 0;
  }
  .dragCard{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 16px;
    padding: 4px 1.2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: fit-content;
  }
  .dragCard p{
    font-family: Urbane Extra;
    font-size: 14px;
    font-weight: 300;
    line-height: 25px;
    text-align: left;
    margin: 0;
    color: #000;
  }
  .table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    border: 2px solid #000000;
  }
  
  .table thead th, .table tbody td {
    max-width: 50px; 
}
  
  .table thead th {
    border: 1px solid #000000;
    padding: 8px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    height: 1.5rem;
    font-family: Urbane;
    line-height: 25px;
    text-align: left;
    margin: 0;
    color: #000;
    
  }
  
  .tableCell {
    padding:8px 15px;
    border: 1px solid #000000;
    height: 1.5rem;
    font-family: Urbane Extra;
    font-size: 14px;
    font-weight: 300;
    line-height: 25px;
    text-align: center;
    margin: 0;
    color: #000;
   
  }
  .cardInCell{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 26px;
    padding: 4px 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: fit-content;
    text-align: center;
    gap: 3px;
    width: fit-content;
    margin: 0 auto;
  }
  .cardInCell svg{
    color: red;
    cursor: pointer;
    font-size: 1rem;
  }