.Container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  padding: 0 1rem;
}
.Header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  border-bottom: 2px solid #b1b1b1;
}
.HeaderText {
  width: 100%;
}
.HeaderText p {
  font-size: 1.25rem;
  margin: 0;
  color: #1a1e5d;
  font-weight: 500;
}
.HeaderIcons {
  width: 100%;
  text-align: right;
  color: #1a1e5d;
  font-size: 1.5rem;
}
.Container::-webkit-scrollbar {
  display: none;
}
.Content {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.Grid {
  display: grid;
  /* width: 70rem; */
  height: auto;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1rem;
  column-gap: 0.5rem;
}
.cards1 {
  /* width: 100%; */
  height: fit-content;
  border: 1px solid #cb9c73;
  background-color: #fff;
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  color: rgb(106, 41, 41);
  background: linear-gradient(#f3d3a8, #cb9c73);
}
.cards2 {
  /* width: 100%; */
  height: fit-content;
  border: 1px solid #518bb4;
  background-color: #fff;
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  color: #133f5e;
  background: linear-gradient(#a8d9f3, #518bb4);
}
.profile {
  /* align-items: right; */
  /* border-bottom: 1px solid; */
  margin: 10px 0;
  padding-bottom: 3px;
}

.CardIcons {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 8rem;
}
.CardIcons img {
  width: 100%;
}
.CardContent {
  width: 100%;
  text-align: right;
  padding: 0 2rem;
}
.CardContent p {
  font-weight: 700;
  font-size: 2rem;
}
.profileimage img {
  width: 100%;
  /* height: 100%; */
}
.ProfileImageDiv {
  width: 12rem;
}
.profileimage {
  width: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profileCard {
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  border: 1px solid #dbdbdb;

  border-radius: 1rem;
  color: #1a1e5d;
  padding: 1rem;
  box-shadow: 1px 2px #dbdbdb;
}
.profileCard2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 15rem;
  background: linear-gradient(#f3f0f0, #c2c2c4);
  height: fit-content;
  border-radius: 1rem;
  color: #1a1e5d;
  padding: 5px 1rem;
}
.ProfileHead {
  width: 100%;
  margin-left: 3rem;
}
.ProfileDetails {
  text-align: left;
  color: #1a1e5d;
  /* margin-left: 3rem; */
  font-weight: 500;
  font-size: 1rem;
  padding: 0 1rem;
  width: 100%;
}
.Card {
  height: 12rem;
  border: 1px solid;
  background-color: #fff;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
}
.CardMentor {
  padding: 5px;
}
.CardHead {
  width: 100%;
  align-items: center;
  height: fit-content;
  border-radius: 1rem 1rem 0 0;
  border-bottom: 1.5px solid;
}
.CardHead p {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 3px 1rem;
}
.Image {
  width: 8rem;

  height: 9rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Image span {
  border-radius: 50%;
  border: 1px solid;
  padding: 3rem 3rem;
}
.PostCard {
  border: 1px solid #ccc;
  margin: 5px;
  text-align: left;
  border-radius: 0 0 8px 8px;
  cursor: pointer;
  width: 19rem;
}

.PostCardImage img {
  width: 100%;
  height: 100%;
  /* object-fit: contain; */
}

.PostCardImage {
  height: 14rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.PostCard p {
  margin-top: 8px;
  padding: 0 5px;
}
.PostCard h4 {
  margin: 0;
  padding: 2px 5px;
  text-overflow: ellipsis;
}
.Post {
  /* padding: 0 2rem; */
  padding: 6px 0;
}
.Contact {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: left;
}
.Contact p {
  margin: 5px 0;
  padding-right: 5rem;
}
.PostsHead {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.PostsHead p {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0 5px;
}
.analytics {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  padding: 1rem;
  row-gap: 1rem;
}
.assessmentCount {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 20rem;
  box-shadow: 0 3px 8px #0000003d;
}
.assessmentIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
}
.assessmentIcon svg {
  font-size: 1.75rem;
  color: slategray;
}
.assessmentText {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
}
.assessmentText span {
  font-size: 1.25rem;
  color: green;
  font-weight: 600;
}
.assessmentText p {
  margin: 0;
  font-size: 1.25rem;
  color: #3d0a24;
}
.ongoingTopic {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 20rem;
  box-shadow: 0 3px 8px #0000003d;
}
.topicText {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
}
.topicText span {
  font-size: 1.25rem;
  color: green;
  font-weight: 600;
}
.topicText p {
  margin: 0;
  font-size: 1.25rem;
  color: #3d0a24;
}
.Bold {
  font-size: 1.1rem;
  font-weight: bold;
}
.contacthead {
  display: flex;
  flex-direction: row;
}
.Popup {
  width: 26rem;
  /* height: 30rem; */
  border-radius: 0 0 10px 10px;
  padding: 0px;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  min-height: 10rem;
}
.SuccessPopup {
  width: 19rem;
  height: 8rem;
  border-radius: 10px;
  padding: 0px;
  background-color: #1a1e5d;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: 2px solid #dbdbdb;
  font-size: 1.2rem;
}
.PopupContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}
.PopUpImage {
  height: 20rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.PopUpImage img {
  width: 100%;
  height: 100%;
}
.Popuptext {
  border-top: 1px solid #dbdbdb;
  padding: 0 10px;
  margin-top: 2px;
}
.PopupButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-bottom: 10px;
}
.PopupButton button {
  color: #fff;
  border: none;
  background: #1a1e5d;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
}
.PopupOverlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: rgba(4, 4, 4, 0.5);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.SuccessPopupButton button {
  color: #fff;
  border: 1px solid;
  background: #1a1e5d;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
}
.SecondContent {
  width: 18rem;
  padding: 1rem 8px;
  border-left: 1px solid #dbdbdb;
  min-height: 95.5vh;
}
.Search {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 2px solid #dbdbdb;
  padding: 4px 6px;
  border-radius: 10px;
}
.Search input {
  width: 100%;
  height: 1.75rem;
  outline: none;
  border: none;
  font-size: 1.2rem;
}
.FirstContent {
  width: 50rem;
  padding: 1rem 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}
.FirstContentData {
  /* width: 40rem; */
}
.SearchIcon {
  font-size: 1.5rem;
  padding-right: 2px;
  color: #1a1e5d;
}
.SuggestionCard {
  width: 100%;
  /* border: 1px solid #dbdbdb; */
  /* border-radius: 5px; */
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* justify-content: center; */
  margin: 8px 0;
  cursor: pointer;
}
.ProfileIcon {
  width: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ProfileIcon img {
  width: 100%;
}
.ProfileIconDiv {
  width: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.SuggestionContent {
  padding: 0 2px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.SuggestionName {
  padding: 2px 0;
}
.SuggestionName p {
  margin: 0;
  padding: 2px 0;
  font-size: 0.9rem;
}
.SuggestionHead p {
  color: rgb(137, 136, 136);
  margin: 0;
  padding: 4px 8px;
  font-weight: 500;
}
.SuggestionAction p {
  color: #0966e8;
  margin: 0;
  font-size: 0.9rem;
  padding: 0;
}
.SearchHead {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.SearchHead button {
  color: #fff;
  border: none;
  background: #222872;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
}
.LoginedUser {
  padding: 1rem 0;
}
/* @media screen and (max-width:1246px){
  .FirstContent{
    width: 50rem;
    padding: 1rem 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .FirstContentData{
    width: 37rem;
  }
}
@media screen and (max-width:1024px) {
  .SecondContent{
    width: 15rem;
    padding: 1rem 10px;
    border-left: 1px solid #dbdbdb;
  }
  .FirstContent{
    width: 50rem;
    padding: 1rem 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
      }
  .FirstContentData{
    width: 20rem;
  }
  .Grid{
    display: grid;
    
    height: auto;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 1rem;
    column-gap: 1rem;
    
   
}
} */
@media screen and (max-width: 1300px) {
  .FirstContent {
    width: 46rem;
    padding: 1rem 0px;
    display: flex;
    flex-direction: column;
  }
  .SecondContent {
    width: 18rem;
    padding: 1rem 8px;
    border-left: 1px solid #dbdbdb;
    min-height: 95.5vh;
  }
}
@media screen and (max-width: 1250px) {
  .PostCard {
    width: 16rem;
  }
  .Grid {
    row-gap: 1rem;
    column-gap: 0.5rem;
    justify-items: center;
    /* width: fit-content; */
    align-items: center;
  }
  .SecondContent {
    padding: 1rem 4px;
    border-left: 1px solid #dbdbdb;
    min-height: 95.5vh;
  }
}
@media screen and (max-width: 1100px) {
  .PostCard {
    width: 15rem;
  }
  .Grid {
    row-gap: 1rem;
    column-gap: 0.1rem;
    justify-items: center;
    /* width: fit-content; */
    align-items: center;
  }
}
@media screen and (max-width: 1024px) {
  .PostCard {
    width: 18rem;
  }
  .Grid {
    row-gap: 1rem;
    column-gap: 0.4rem;
    justify-items: center;
    /* width: fit-content; */
    align-items: center;
  }
}

@media screen and (max-width: 900px) {
  .Grid {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 1rem;
    column-gap: 0.5rem;
    justify-items: center;
    /* width: fit-content; */
    align-items: center;
  }
  .PostCard {
    border: 1px solid #ccc;
    margin: 5px;
    text-align: left;
    border-radius: 0 0 8px 8px;
    cursor: pointer;
    width: 22rem;
    height: 19rem;
  }
  .FirstContent {
    width: 32rem;
    padding: 1rem 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .SecondContent {
    width: 18rem;
    padding: 1rem 8px;
    border-left: 1px solid #dbdbdb;
    min-height: 95.5vh;
  }
}
@media screen and (max-width: 720px) {
  .Content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .Container {
    padding: 0 0.5rem;
  }
  .FirstContent {
    width: 100%;
    padding: 1rem 0px;
    display: flex;
    flex-direction: column;
    align-items: normal;
  }
  .SecondContent {
    /* width: 100%; */
    padding: 1rem 8px;
    border-left: none;
    min-height: 95.5vh;
  }
  .SuggestionContent {
    padding: 0 2px;
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    font-size: 1rem;
  }
  .SuggestionName p {
    margin: 0;
    padding: 2px 0;
    font-size: 1rem;
  }

  .SuggestionAction p {
    color: #0966e8;
    margin: 0;
    font-size: 1rem;
    padding: 0;
  }
}
@media screen and (max-width: 599px) {
  .PostCard {
    border: 1px solid #ccc;
    margin: 5px;
    text-align: left;
    border-radius: 0 0 8px 8px;
    cursor: pointer;
    width: 20rem;
    height: 19rem;
  }
  .SearchHead {
    /* width: fit-content; */
    display: flex;
    flex-direction: row;
  }
  .Tab .MuiTab-root {
    font-size: 0.9rem;
  }
  .Popup {
    width: 80%;
    /* height: 30rem; */
    border-radius: 0 0 10px 10px;
    padding: 0px;
    background-color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 10rem;
  }
  .SuggestionContent {
    padding: 0 2px;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    font-size: 1rem;
  }
  .Grid {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 1rem;
    column-gap: 0.5rem;
    justify-items: center;
    /* width: fit-content; */
    align-items: center;
  }
}
@media screen and (max-width: 400px) {
  .PostCard {
    border: 1px solid #ccc;
    margin: 5px;
    text-align: left;
    border-radius: 0 0 8px 8px;
    cursor: pointer;
    width: 18rem;
    height: 19rem;
    align-items: center;
    align-content: center;
  }
}
.WhiteBoradContainer{
  width: 60rem;
height: 36rem;
border-radius: 16px;
background-color: #fff;
position: fixed;
top: 19%;
right: 1%;
border: 1px solid #dbdbdb;
/* transform: translate(-50%, -50%); */
animation: slideIn 0.5s forwards;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.closing {
  animation: slideOut 0.5s; /* Adjust the duration as needed */
}
@keyframes slideIn {
  from {
    right: -60rem; /* Off-screen initially */
  }
  to {
    right: 1rem; /* Final position */
  }
}
@keyframes slideOut {
  from {
    right: 1rem; /* Initial position when closing */
  }
  to {
    right: -60rem; /* Move off-screen to the right */
  }
}
.WhiteBoradHead{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
  padding: 0rem 1rem;
  margin: 0;
  border: none;
  border-radius:15px 15px 0 0 ;
}
.WhiteBoradIcon{
  cursor: pointer;
  font-size: 1.25rem;
  padding: 8px;
}
.WhiteBoradContent{
  height: 30rem;
  padding:.5rem 1rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


.chatboticon {
  position: fixed;
  top: 50%;
 right: 0%;
  /* color: #fff;  */
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* animation: slideIn 0.5s forwards; */
}

.Iconhead {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: #000;
  font-weight: bold;
  font-size: 1.25rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
}
.icontext {
  padding: .5rem;
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  /* background-color: #e4c395; */
  border-radius: 6px;
  /* border: 1px solid; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:#1a1e5d;
}
.icontext p{
  margin: 0;
  padding: 0;
}
.WhiteboardFloatingIcon{
font-size: 1.5rem;
margin: 2px 0;
color:#1a1e5d;
}
.ChatImage {
  width: 11rem;
  /* border-radius: 50%; */
  /* border: 1px solid; */
  display: flex;
  align-items: center;
  /* background-color: #0f8bea; */
}
.ChatImage img {
  width: 100%;
}