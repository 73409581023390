.container {
  height: 100vh;
  overflow-y: scroll;
  background-color: #0b0f7705;
  font-family: Arial, sans-serif;
}

.container::-webkit-scrollbar {
  display: none;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #4caf50;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 8rem;
  padding: 0 2rem;
  background-color: #ffffff;
  color: #131010;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}



.headerText {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.headerText h1 {
  color: darkblue;
  font-weight: 800;
  margin: 0;
}

.headerText p {
  font-weight: 800;
  font-size: 0.8rem;
  text-transform: uppercase;
  display: flex;
  gap: 2rem;
  margin: 0;
}

.headerButton{
  display: flex;
  gap: 2rem;
}

.updateBtn {
  background: rgba(0, 0, 139, 0.123);
  font-size: 0.8rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: rgb(0, 0, 139);
  width: 130px;
  cursor: pointer;
  font-weight: 800;
  text-transform: uppercase;
  border: 2px solid rgb(0, 0, 139);
  transition: background 0.3s, color 0.3s;
}

.updateBtn:hover {
  color: #ffffff;
  background: rgb(0, 0, 139);
}

.deleteBtn {
  background: rgba(255, 0, 0, 0.123);
  font-size: 0.8rem;
  outline: none;
  border-radius: 7px;
  padding: 0.75rem;
  color: rgb(201, 0, 0);
  width: 130px;
  cursor: pointer;
  font-weight: 800;
  text-transform: uppercase;
  border: 2px solid rgb(201 ,0, 0);
  transition: background 0.3s, color 0.3s;
}

.deleteBtn:hover {
  color: #ffffff;
  background:rgb(201 ,0, 0);
}

.MainBox {
  width: 90%;
  padding: 4rem;
}

.EventDetails {
  margin: 2rem auto;
  border-radius: 0.5rem;
  background-color: #f9f9f9;
  max-width: 100%;
}

.posterDetails {
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.poster img {
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.discription h3 {
  margin-bottom: 1rem;
}

.details {
  max-width: 100%;
}

.details p {
  width: 100%;
}

.details h4 {
  margin-bottom: 1rem;
}

.Venue h3 {
  margin-bottom: 1rem;
}

.attendButton button {
  padding: 0.8rem;
  border: none;
  font-weight: 700;
  font-size: 1rem;
  background-color: rgb(0, 0, 119);
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  width: 100%;
}

.attendButton button:hover {
  background-color: rgb(0, 0, 139);
}

.attendButton button.attending {
  background-color: rgb(0, 100, 0); 
  color: white; 
}

.detailCards {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.dateBox,
.hostVenue {
  padding: 1.5rem;
  border-radius: 5px;
  background-color: #ffffff;
}

.hostName,
.venueDetails {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.hostName p,
.venueDetails p {
  font-weight: 600;
  width: auto;
}

.hostHead {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.hostHead p {
  font-weight: 600;
  width: auto;
}

.dateTime {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  align-items: center;
}

.dateTime p {
  font-weight: 600;
  width: 7rem;
}

.timeIcon {
  font-size: 1.5rem;
}

.dateTime span,
.hostName span,
.venueDetails span {
  font-weight: 600;
  color: #414141;
}

.dateBox span {
  font-weight: 600;
  color: #414141;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  display: flex;
  gap: 4rem;
}

.modalContent {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 10px;
}

.modalButtons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 20px;
}

 .modalCancel {
  background: rgba(0, 0, 139, 0.123);
  font-size: 0.8rem;
  outline: none;
  border-radius: 7px;
  padding: 0.5rem;
  color: rgb(0, 0, 139);
  width: 100px;
  cursor: pointer;
  font-weight: 800;
  text-transform: uppercase;
  border: 2px solid rgb(0, 0, 139);
  transition: background 0.3s, color 0.3s;
}

.modalCancel:hover{
  color: #ffffff;
  background: #cf142b;
  border: 2px solid #cf142b;

}

.modalConfirm {
  background: rgba(0, 0, 139, 0.123);
  font-size: 0.8rem;
  outline: none;
  border-radius: 7px;
  padding: 0.5rem;
  color: rgb(0, 0, 139);
  width: 100px;
  cursor: pointer;
  font-weight: 800;
  text-transform: uppercase;
  border: 2px solid rgb(0, 0, 139);
  transition: background 0.3s, color 0.3s;
}

.modalConfirm:hover{
  color: #ffffff;
  background: rgb(2, 138, 42);
  border: 2px solid rgb(2, 138, 42);

}

.snackbar {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgb(0, 0, 139);;
  color: #fff;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-size: 14px;
  text-align: center;
}



