.Container{
    height: 100vh;
  overflow-y: scroll;
  padding: 0 1rem;
}
.Container::-webkit-scrollbar{
  display: none;
}

.Header{
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  color: #131010;
  height: 4rem;
  margin: 1rem;
  box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
    0.3em 0.3em 1em #6d6d6d4d;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  padding: 0 1rem;
}
.Header p{
  font-weight: 500;
  font-size: 1.35rem;
  text-transform: uppercase;
}
.QuizList{
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: 1.5rem 1rem;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid #eff8ff;
  min-height: 75vh;
}
.QuizGrid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  width: 100%;
}
.Card{
  display: flex;
  justify-content: flex-start;
  background-color: #ffffff;
  align-items: center;
  color: #131010;
  box-shadow: inset 0 -3em 3em #c9c9c91a, 0 0 0 2px #f5f5f5,
    0.3em 0.3em 1em #6d6d6d4d;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}
.Card p{
  font-weight: 500;
  font-size: 1.15rem;
  padding: 1rem;
}
