.container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
}

.maintenanceSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #DFE2F7;
    padding: 3rem;
    border-radius: 45px;
    gap: 1rem;
}

.maintenanceLogo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.maintenanceLogoImage {
    width: 8rem;
}

.maintenanceLogoImage img {
    width: 100%;
    height: 100%;
}

.maintenanceHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.maintenanceHeader h4 {
    font-family: Urbane;
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #595BDB;
    padding-bottom: 1.5rem;
}

.maintenanceHeader p {
    font-family: Urbane;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #5F5F5F;
}
.maintenanceImageContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.maintenanceImage{
    width: 17rem;
}
.maintenanceImage img{
    width: 100%;
    height: 100%;
}

.maintenanceBottomText {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.maintenanceBottomText p {
    font-family: Urbane;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #5F5F5F;
}
@media screen and (max-width:768px) {
   
    .maintenanceSection {
        padding: 2rem;
        width: 90%;
    }
    .maintenanceHeader h4 {
        font-size: 28px;
    }
}