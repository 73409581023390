.Container {
    width: 100%;
    height: 100vh;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    position: relative;
    background-image: url("../../assests/bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    flex-direction: column;
    overflow-y: scroll;
      }
  .Login {
    width: 20rem;
    width: 22rem;
    background-color: #f3dcbc;
    border-radius: 1rem;
  }
  .form {
    padding: 3rem 2rem;
    border-radius: 1rem;
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }
  .learnersIMg {
    width: 10%;
  }
  .learnersIMg img {
    width: 100%;
  }
  .HeaderContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
  }
  .Input {
    width: 100%;
    font-size: 1rem;
    padding: 0.7rem 0;
    text-align: center;
    margin: 8px;
    border: 1.5px solid #1a1e5d;
    border-radius: 4rem;
    outline: none;
  }
  .password {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18.5rem;
    position: relative;
  }
  
  .passwordInput {
    width: 100%;
    font-size: 1rem;
    padding: 0.7rem 0;
    text-align: center;
    margin: 5px;
    outline: none;
    border: 1.5px solid #1a1e5d;
    border-radius: 4rem;
  }
  
  .eye {
    position: absolute;
    right: 1rem;
    font-size: 1.75rem;
  }
  .formButton {
    width: 100%;
    font-size: 1rem;
    padding: 0.5rem;
    text-align: center;
    border-radius: 4rem;
    margin: 1rem 0;
    border: none;
    background-color: #1a1e5d;
    color: #fff;
    cursor: pointer;
  }
  .form .formText {
    font-size: 1.5rem;
    margin: 6px;
    font-weight: 600;
  }
  .Error {
    width: 100%;
    align-items: center;
    text-align: center;
  }
  .Error > p{
    font-size: 1.25rem;
    color: red;
    padding: 2px;
    margin: 1px;
  }
  .left {
    width: fit-content;
    /* height: 100%; */
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    flex-direction: column;
    padding: 1rem 10px;
  }
  .right {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
   
  }
  .LeftContent {
    width: 38rem;
  }
  .LogoImage {
    width: 7em;
  }
  .LogoImage img {
    width: 100%;
    border-radius: 10rem;
  }
  .heading {
    width: 100%;
  }
  .heading p {
    font-size: 3rem;
    font-weight: 700;
    color: #d7ae74;
    padding-left: 10px;
    margin:16px 0;
  }
  .HeadingText {
    width: 100%;
  }
  .HeadingText p {
    font-size: 1.75rem;
    font-weight: 500;
    color: #fff;
    padding-left: 10px;
    margin:16px 0;
  }
  .formText {
    font-size: 1.2rem;
    margin: 15px 2rem;
    width: 100%;
    align-items: center;
    color: #1a1e5d;
  }
  .text1 {
    margin: 4px 0;
    font-weight: 500;
  }
  .text2 {
    margin: 0;
    font-weight: 700;
  }
  .Bottemtext {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .bottemtext1 {
    font-size: 1.25rem;
    margin: 5px ;
    font-weight: 500;
    color: #acaaaa;
    margin-top: 1rem;
  }
  .bottemtext2 {
      font-size: 1rem;
      margin: 0;
      color: #acaaaa;
    }
    .Facebook {
      width: 10%;
      margin: 1rem;
    }
    .Google {
      width: 10%;
    }
    .Facebook img {
      width: 100%;
    }
    .Google img {
      width: 100%;
    }
    .Bottomicons{
      width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    .AccountText {
      font-weight: 600;
      color: #000;
    }
    .AccountText a {
      text-decoration: none;
      color: #3b82f6;
    }
    .form p{
      margin: 0;
      /* color: #3b82f6; */
      font-weight: 500;
      cursor: pointer;
    }
    .SearchContent{
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      padding: 0 8px;
      justify-content: flex-end;
      /* margin-left: 2rem; */
      z-index: 99;
      height: 29rem;
    }
    .SearchInput{
      display: flex;
      flex-direction: row;
      /* justify-content: center; */
      align-items: center;
      width: 40rem;
      /* border: 3px solid #928f8f; */
      background-color: #fff;
      padding: 4px 1rem;
      border-radius: 2rem;
    }
    .SearchInput input{
      width: 100%;
      border: none;
      outline: none;
      height: 2.5rem;
      font-size: 1.2rem;
    }
    .SearchIcon{
      font-size: 1.25rem;
      cursor: pointer;
    }
    .SearchText p{
      font-weight: 700;
      font-size: 3rem;
      margin: 0;
      padding:1rem 0;
      color: #fff;
      text-align: center;
      
    }
    .SearchButton{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .SearchButton button{
      margin: 1rem;
      padding: .5rem 1rem ;
      background-color: #1a1e5d;
      border: 3px solid #fff;
      outline: none;
      color: #fff;
      font-size: 1.5rem;
      font-weight: 600;
      border-radius: 10px;
      cursor: pointer;
    }
    .MainContent{
      display: flex;
      flex-direction: row;
      height: fit-content;
    }
    .Grid{
      display: grid;
      width: 74rem;
      height: auto;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 1rem;
      column-gap: 1rem;
      padding: 4px 0;
      justify-items: center; 
      align-items: center;
      position: relative;
    }
    .CardDiv{
      /* color: #fff; */
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .Cards{
      border: 2px solid #5c5b5b;
      border-radius: 10px;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      cursor: pointer;
    }
    .Cardheading p{
      margin: 0;
      padding: 8px 0;
      font-size: 1.2rem;
      font-weight: 500;
    }
    .CardDescription p{
      margin: 0;
      padding: 8px 0;
      font-size: 1rem;
      text-align: center;
    }
    .CardDivOverlay{
  width: 100%;
  height: 100%;
  position: absolute;
  background-color:rgba(4, 4, 4, 0.5);
    }
    .cardButton{
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .cardButton button{
      background: #262673;
  outline: none;
  border: none;
  color: #fff;
  
  font-size: 1rem;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  cursor: pointer;
    }
    .ErrorMessage{
     
      color: #fff;
      font-size: 1.2rem;
      position: absolute; /* Add this */
      top: 50%; /* Add this */
      left: 50%; /* Add this */
      transform: translate(-50%, -50%); /* Add this */
    }
    @media screen and (max-width:1024px){
      .Grid{
        width: 58rem;
        grid-template-columns: repeat(3, 1fr);
        row-gap: .5rem;
        column-gap: .5rem;
        padding: 4px 2px;
      }
      .Cards{
        padding: .5rem;
        display: flex;
        height: 15rem;
      }
    }
    @media screen and (max-width:820px) {
      .Grid{
        width: 45rem;
        grid-template-columns: repeat(2, 1fr);
        row-gap: .5rem;
        column-gap: .5rem;
        padding: 4px 2px;
      }
      .Cards{
        padding: .5rem;
        display: flex;
        height: 14rem;
      }
    }
    @media screen and (max-width:712px) {
      .Grid{
        width: 43rem;
        grid-template-columns: repeat(2, 1fr);
        row-gap: .5rem;
        column-gap: .5rem;
        padding: 4px 2px;
      }
      .Cards{
        padding: .5rem;
        display: flex;
        height: 14rem;
      }
      .Cardheading p{
        margin: 0;
        padding: 8px 0;
        font-size: 1.1rem;
        font-weight: 500;
      }
      .SearchButton button{
        border: 2px solid #fff;
        font-size: 1.2rem;
      }
      .SearchInput{
        width: 80%; 
      }
    }
    @media screen and (max-width:599px) {
      /* .SearchInput{
        width: fit-content; 
      } */
      .SearchText p{
        font-weight: 700;
        font-size: 2rem;
        margin: 0;
        padding:1rem 0;
        color: #fff;
        text-align: center;
        
      }
      .Grid{
        width: 19rem;
        grid-template-columns: repeat(1, 1fr);
        row-gap: .5rem;
        column-gap: .5rem;
        padding: 4px 2px;
      }
      .ErrorMessage{
        text-align: center;
        width: 100%;
        color: #fff;
        font-size: 1rem;
        position: absolute;
        top: 50%; 
        left: 50%; 
        transform: translate(-50%, -50%); 
       margin-top: 1rem;
      }
      .Cards{
        padding: .5rem;
        display: flex;
        height: 14rem;
      }
      .Cardheading p{
        margin: 0;
        padding: 8px 0;
        font-size: 1.1rem;
        font-weight: 500;
       
      }
      .SearchContent{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        padding: 0 8px;
        justify-content: flex-end;
        z-index: 99;
        height: 25rem;
      }
      .SearchInput input{
        width: 100%;
        border: none;
        outline: none;
        height: 1.75rem;
        font-size: 1.2rem;
      }
      
    }