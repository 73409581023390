@media (max-width: 480px) {
  .container {
    width: 100%;
    height: 100vh;
  }
  .lessonPage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 1rem;
    background: #ffffff;
    height: 100%;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0.5rem;
    background: #7e9dee;
    padding: 0.5rem;
    border-radius: 5px;
  }
  .header p {
    font-size: 1.25rem;
    color: #071439;
    font-weight: 600;
  }
  .lessonDetail {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0.5rem;
    padding: 0.5rem;
    background: #ffffff;
    overflow: hidden;
    box-shadow: 0 3px 8px #0000003d;
  }
  .TopicDetailText {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    scroll-behavior: smooth;
    overflow-y: auto;
  }
  .TopicDetailText span {
    text-align: justify;
  }
  .TopicDetailText iframe {
    width: 100%;
    height: 100%;
  }
  .Blank {
    color: transparent;
    opacity: 0;
  }
  .imageView img {
    width: 100%;
    height: 100%;
  }
  .Pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0.5rem 0;
    margin-bottom: 1.5rem;
  }
  .pageLeft {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pageLeft svg {
    font-size: 1.25rem;
    cursor: pointer;
  }
  .pageCentre {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5rem;
  }
  .pageCentre button {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 2px solid #96aeef;
    background: #fff;
    font-size: 1rem;
    color: #5e5ea2;
  }
  .pageRight {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pageRight svg {
    font-size: 1.25rem;
    cursor: pointer;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .container {
    width: 100%;
    height: 100vh;
  }
  .lessonPage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 1rem;
    background: #e2e2e2;
    height: 100%;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0.5rem;
    background: #7e9dee;
    padding: 0.75rem;
    border-radius: 5px;
  }
  .header p {
    font-size: 2rem;
    color: #071439;
    font-weight: 600;
  }
  .lessonDetail {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    margin: 0.75rem;
    padding: 0.75rem;
    background: #ffffff;
    overflow: hidden;
    box-shadow: 0 3px 8px #0000003d;
    height: 100%;
  }
  .TopicDetailText {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
    overflow-y: scroll;
  }
  .TopicDetailText span {
    text-align: justify;
    font-size: 1.75rem;
  }
  .TopicDetailText iframe {
    width: 100%;
    height: 100%;
  }
  .Blank {
    color: transparent;
    opacity: 0;
  }
  .imageView img {
    width: 100%;
    height: 100%;
  }
  .Pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0.5rem 0;
  }
  .pageLeft {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pageLeft svg {
    font-size: 2rem;
    cursor: pointer;
  }
  .pageCentre {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5rem;
  }
  .pageCentre button {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 2px solid #96aeef;
    background: #fff;
    font-size: 2rem;
    color: #5e5ea2;
  }
  .pageRight {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pageRight svg {
    font-size: 2rem;
    cursor: pointer;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .container {
    width: 100%;
    height: 100vh;
  }
  .lessonPage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 1rem;
    background: #e2e2e2;
    height: 100%;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0.5rem;
    background: #7e9dee;
    padding: 0.5rem;
    border-radius: 5px;
  }
  .header p {
    font-size: 2rem;
    color: #071439;
    font-weight: 600;
  }
  .lessonDetail {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    margin: 0.5rem;
    padding: 0.75rem;
    background: #ffffff;
    overflow: hidden;
    box-shadow: 0 3px 8px #0000003d;
    height: 100%;
  }
  .TopicDetailText {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
    overflow-y: scroll;
  }
  .TopicDetailText::-webkit-scrollbar {
    display: none;
  }
  .TopicDetailText span {
    text-align: justify;
    font-size: 1.75rem;
  }
  .TopicDetailText iframe {
    width: 100%;
    height: 100%;
  }
  .Blank {
    color: transparent;
    opacity: 0;
  }
  .imageView img {
    width: 100%;
    height: 100%;
  }
  .Pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0.5rem 0;
  }
  .pageLeft {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pageLeft svg {
    font-size: 2rem;

    cursor: pointer;
  }
  .pageCentre {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5rem;
  }
  .pageCentre button {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 2px solid #96aeef;
    background: #fff;
    font-size: 2rem;

    color: #5e5ea2;
  }
  .pageRight {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pageRight svg {
    font-size: 2rem;

    cursor: pointer;
  }
}
