.Container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: url("../../assests/bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .Login {
    width: 20rem;
    width: 22rem;
    background-color: #f3dcbc;
    border-radius: 1rem;
  }
  .form {
    padding: 1rem 2rem;
    border-radius: 1rem;
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }
  
  .HeaderContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
  }
  .Input {
    width: 100%;
    font-size: 1rem;
    padding: 0.7rem 0;
    text-align: center;
    margin: 8px;
    border: 1.5px solid #1a1e5d;
    border-radius: 4rem;
    outline: none;
  }
  .password {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* width: 18.5rem; */
    position: relative;
    border: 1.5px solid #1a1e5d;
    border-radius: 4rem;
    /* padding: 0 10px; */
    width: 100%;
  }
  
  .passwordInput {
    width: 100%;
    font-size: 1rem;
    padding: 0.7rem 0;
    text-align: center;
    outline: none;
   border: none;
  }
  
  .eye {
    font-size: 1.5rem;
    padding: 0 4px;
    cursor: pointer;
  }
  .info{
    font-size: 1.35rem;
    padding: 0 6px;
    cursor: pointer;
  }
  .formButton{
    display: flex;
    justify-content: center;
  }
  .formButton button {
   
    font-size: 1rem;
    padding: 10px 5rem;
    text-align: center;
    border-radius: 4rem;
    margin: 1rem 0;
    border: none;
    background-color: #1a1e5d;
    color: #fff;
    cursor: pointer;
  }
  .form .formText {
    font-size: 1.5rem;
    margin: 6px;
    font-weight: 600;
  }
  .Error {
    width: 100%;
    align-items: center;
    text-align: center;
  }
  .Error > p{
    font-size: 1.25rem;
    color: red;
    padding: 2px;
    margin: 1px;
  }
  
  .right {
    width: 40rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .LeftContent {
    width: 35rem;
  }
  .LogoImage {
    width: 6rem;
  }
  .LogoImage img {
    width: 100%;
    border-radius: 10rem;
  }
  
  .formText {
    font-size: 1.2rem;
    margin: 15px 2rem;
    width: 100%;
    align-items: center;
    color: #1a1e5d;
  }
  .text1 {
    margin: 4px 0;
    font-weight: 500;
  }
  
  
    .form p{
      margin: 0;
      /* color: #3b82f6; */
      font-weight: 500;
      cursor: pointer;
     text-align: center;
    }
    
    .SearchText p{
      font-weight: 700;
      font-size: 3rem;
      margin: 0;
      padding:1rem 0;
      color: #fff;
      text-align: center;
      
    }
    .PasswordPopup{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #1a1e5d;
    border: 3px solid #949393;
    border-radius: 20px;
    padding: .5rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99;
    color: #fff;
    width: 15rem;
    }
    .action button{
      margin: .5rem 0;
      padding: .5rem 1rem ;
      background-color: transparent;
      border: 3px solid #fff;
      outline: none;
      width: 100%;
      color: #fff;
      font-size: 1rem;
      font-weight: 600;
      border-radius: 10px;
      cursor: pointer;
    }
    .content ul {
      display: flex;
      flex-direction: column;
      list-style: circle;
      font-size: 1rem;
    }
    .content ul p {
      color: #fff;
      text-align: center;
      margin: 0;
      padding: 5px 0;
    }
    .content ul li {
      margin: 7px 0px;
      color: #fff;
    }
    .formButton {
      font-size: 1rem;
      padding: 0.5rem 5rem;
      text-align: center;
      border-radius: 4rem;
      margin: 4px 0;
      border: none;
      color: #fff;
      background-color: #1a1e5d;
      cursor: pointer;
    }
    .buttonDisabled{
      
      font-size: 1rem;
      padding: 0.5rem 5rem;
      text-align: center;
      border-radius: 4rem;
      margin: 4px 0;
      border: none;
      color: #fff;
      background-color: #5e6194;
      cursor: pointer;
    }
    @media screen and (max-width:450px) {
      .SearchText p{
        font-weight: 700;
        font-size: 2rem;
        margin: 0;
        padding:1rem 0;
        color: #fff;
        text-align: center;
        
      }
      .Login {
        width: 19rem;
        background-color: #f3dcbc;
        border-radius: 1rem;
      }
      .form {
        padding: 1rem ;
        border-radius: 1rem;
        /* width: 100%; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
      }
      
      
    }
    @media screen and (max-width:330px) {
      .SearchText p{
        font-weight: 700;
        font-size: 2rem;
        margin: 0;
        padding:1rem 0;
        color: #fff;
        text-align: center;
        
      }
      .Login {
        width: 19rem;
        background-color: #f3dcbc;
        border-radius: 1rem;
      }
    }
    