.sidebar {
  width: 16rem !important;
  display: flex;
  flex-direction: column;
  border-radius: 0 0;
  height: calc(100vh- 17vh);
  background: #ededfb;
  border-radius: 30px 0 0 30px;
  justify-content: space-between;
  margin-left: 4rem;
}
.sidebar::-webkit-scrollbar {
  display: none;
}
.MenuContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 0;
  align-items: flex-end;
}
.menuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  margin: 6px 0;
  width: 12rem;
  border-radius: 2rem 0 0 2rem;
  cursor: pointer;
}
.selectedMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  margin: 6px 0;
  width: 12rem;
  border-radius: 2rem 0 0 2rem;
  background-color: #fff;
  cursor: pointer;
}
.menuItemImage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 1.5rem;
  padding-left: 1rem;
}
.menuItemImage img {
  width: 100%;
  height: 100%;
}
.menuItemText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.menuItemText p {
  margin: 0;
  font-family: Urbane;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #595bdb;
  padding-left: 1rem;
}
.bottomContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
}
.separateLine {
  border-top: 2px solid #dbdbdb;
  width: 100%;
  margin-bottom: 8px;
}
.bottomText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0.75rem 0;
}
.bottomText p {
  font-family: Urbane;
  font-size: 14px;
  font-weight: 500;
  line-height: 23.04px;
  text-align: left;
  color: #595bdb;
  margin: 0;
  cursor: pointer;
}
.selectedBottomText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0.75rem 0;
  padding: 10px;
  margin: 6px 0;
  width: 12rem;
  border-radius: 2rem 0 0 2rem;
  background-color: #fff;
  cursor: pointer;
}
.selectedBottomText p {
  font-family: Urbane;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.04px;
  text-align: left;
  color: #595bdb;
  margin: 0;
  cursor: pointer;
}
.supportText {
  padding-left: 1.7rem;
}
.logoutText {
  padding-left: 1.4rem;
}
.popupOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  width: 100%;
  height: 100vh;
  background: #d9d9d980;
}
.Popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 31px;
  padding: 4rem ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  box-shadow: 1px 1px 2.3px 0px #cfcfcf;
}
.popupText {
  padding: 2rem 0;
}
.popupText p {
  font-family: Urbane;
  font-size: 20px;
  font-weight: 600;
  line-height: 27.65px;
  text-align: left;
  color: #505050;
  margin: 0;
}
.PopupButton {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  padding: 2rem 0;
}
.noButton {
  width: 16rem;
  height: 3.5rem;
  border-radius: 2.5rem;
  font-family: Urbane;
  font-size: 20px;
  font-weight: 600;
  line-height: 27.65px;
  text-align: center;
  color: #fff;
  background: #595bdb;
  border: none;
  cursor: pointer;
}
.yesButton {
  width: 16rem;
  height: 3.5rem;
  border-radius: 2.5rem;
  background: #d9d9d9;
  font-family: Urbane;
  font-size: 20px;
  font-weight: 600;
  line-height: 27.65px;
  text-align: center;
  color: #898890;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .sidebar {
    display: none;
  }
}
