.Container {
    width: 100%;
    height: 100vh;
    padding: 0 1rem;
    overflow-y: scroll;
  }
  .Container::-webkit-scrollbar{
    display: none;
  }
  .AddressSection {
    width: 35rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    z-index: 9999;
  
  }
  .AddressSection form {
    background-color: #f5f5f5;
    padding: 2rem 2rem ;
    border-radius: 20px;
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 99;
  }
  .AddressSection form input
  {
    /* width: 100%; */
    font-size: 1rem;
    background-color: #fff;
    padding: 8px 1px;
    text-align: center;
    border-radius:5px ;
    margin: 10px 0;
    border: 1px solid #696969c1;
    outline: none;
    
  }
  
  /* .AddressSection form {
    align-items: center;
  } */
  .FormButton{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
  .FormButton button {
    
    border-radius:5px;
    background-color: #3b82f6;
    border: none;
    outline: none;
    height: 2rem;
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
    padding: 2px 10px;
    margin: 0 1rem;
    margin-top: 1rem;
  }
  .AddressSection form input::-webkit-input-placeholder {
    text-align: center;
    text-transform: uppercase;
  }
  .Formtext{
    width: 100%;
  }
  .Formtext p{
    margin: 4px 1rem;
    font-size: 1.2rem;
  }
  .ProfileOverlay{
    width: 100vw;
  height: 100vh;
  position: absolute;
 background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  }
  .Time{
    display: flex;
    flex-direction: row;
    /* width: 100%; */
    justify-content: space-between;
  }
  .TimeSecond{
    display: flex;
    flex-direction: column;
    width: 45%;
  }
  label{
    font-weight: 500;
  }
  .Description{
    display: flex;
    flex-direction: column;
   
  }
  .Editor{
    /* height: 8rem; */
    overflow-y: scroll;
    margin-top: 5px;
  }
  .Editor::-webkit-scrollbar{
    display: none;
  }
  .ViewPopup{
    width: 30rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    z-index: 9999;
    min-height: 5rem;
  height: fit-content;
  background-color: #fff;
  padding: 10px 1rem;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
  box-shadow: 2px 2px #888888;
  }
  .Icons{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: fit-content;
    align-items: center;
    
  }
  .EditIcons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 6rem;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .Title{
    /* text-align: center; */
  }
  .Title p{
    font-size: 1.25rem;
    font-weight: 500;
    margin: 5px 0;

  }
  .start p{
    margin: 0;
  }
  .DeletePopup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #077bc8;
    border: 1px solid #fcf9f9;
    border-radius: 20px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: fit-content;
    z-index: 99;
    box-shadow: 2px 2px #888888;
  }
  .DeleteText {
    /* margin-top: 15%; */
  }
  .DeleteText p {
    font-size: 1.25rem;
    text-align: center;
    color: #fff;
    font-weight: 500;
    line-height: 25px;
  }
  .DeleteButton {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
  }
  .DeleteButton button {
    margin: 0 .5rem;
    padding: .5rem 1rem ;
    background-color: transparent;
    border: 2px solid #fff;
    outline: none;
    width: 100%;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 10px;
    cursor: pointer;
  }
  @media screen and (max-width: 600px) {
    .ViewPopup{
      width: 80%;
    padding: 10px .5rem;
    }
    .AddressSection {
      width: 88%;
    }
    .AddressSection form {
      padding: 1rem ;
    }
    .Time{
      display: flex;
      flex-direction: column;
      /* width: 100%; */
      justify-content: space-between;
    }
    .TimeSecond{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .DeletePopup {
      padding: .5rem;
      width: 80%;
    }
    .DeleteText p {
      font-size: 1rem;
    }
    .DeleteButton {
      margin: 0 auto;
      display: flex;
      flex-direction: row;
    }
    .DeleteButton button {
      margin: 0 .5rem;
      padding: .5rem 1rem ;
      width: 100%;
       }
       .AddressSection form input
  {
    /* width: 100%; */
    font-size: 1rem;
    padding: 6px 1px;
    margin: 4px 0;
    
  }
}
  